/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import { Box, Button, Flex, Text, Card, Paragraph, Image, IconButton } from 'theme-ui'
import { BsArrowRight } from 'react-icons/bs'
import StatusModal from 'components/status-modal'
import Table from 'components/Table'
import CustomModal from 'components/modal'

import useSWR from 'swr'
import NFTService, { Deposit } from 'network/services/nft'
import { IDataResponse, serialize } from 'network/request'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import _ from 'lodash'

import MoreInfo from 'components/more-info'
import QRCode from 'qrcode'

const DepositRequest = () => {
  const [addressID, setAddressID] = useState<string | number>('')
  const [requestOpen, setRequestOpen] = useState<boolean>(false)
  const [depositOpen, setDepositOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })
  const [page, setPage] = useState(1)
  const limit = 10
  const { data, error, mutate } = useSWR<IDataResponse<Deposit>>(
    serialize(NFTService.getMyNFTDeposits, {
      sort: 'created_at:desc',
      page: page,
      limit: limit
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve deposit information" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const nftDeposit = NFTService.toRowDeposit(data).map((item) => {
    if (item.status === 'pending') {
      return {
        id: item.id,
        requested_on: item.created_at,
        expires_at: item.expired_at,
        status: (
          <Flex variant="layout.flexCenterSpaceBetween">
            <Button onClick={() => openDeposit(item.id)}>Deposit Now</Button>
          </Flex>
        )
      }
    } else if (item.status === 'new') {
      return {
        id: item.id,
        requested_on: item.created_at,
        expires_at: item.expired_at,
        status: (
          <Flex>
            <Text>{_.startCase(item.status)}</Text>
            <MoreInfo title="" direction="topLeft">
              <Box
                variant="layout.borderedTextCenter"
                bg="dark"
                p={2}
                sx={{ width: 'max-content' }}
              >
                <Text variant="small" color="textMuted">
                  Your deposit request is <br /> pending approval from system
                </Text>
              </Box>
            </MoreInfo>
          </Flex>
        )
      }
    } else {
      return {
        id: item.id,
        requested_on: item.created_at,
        expires_at: item.expired_at,
        status: _.startCase(item.status)
      }
    }
  })
  const totalPending = NFTService.toRowDeposit(data).filter(
    (item) => item.status === 'pending' || item.status === 'new'
  )
  const total = NFTService.toPaginateDeposit(data).total

  const openRequest = () => {
    if (totalPending.length > 0) {
      setMessage({
        success: false,
        isOpen: true,
        message: 'Unable to make multiple deposit request'
      })
    } else {
      setRequestOpen(true)
    }
  }
  const closeRequest = () => {
    setRequestOpen(false)
  }

  const openDeposit = (data: string | number) => {
    setAddressID(data)
    setDepositOpen(true)
  }

  const closeDeposit = () => {
    setDepositOpen(false)
  }

  // TODO: integrate api
  // TODO: add pagination

  const submitDeposit = async () => {
    closeRequest()
    const submitData = { token_type: 'VXT', network_type: 'ETHEREUM' }
    try {
      const { data: result } = await NFTService.createNFTDeposit(submitData)
      if (result.success) {
        mutate()
        setMessage({
          success: true,
          isOpen: true,
          message: 'Request Deposit Successfully'
        })
      }
    } catch (e: any) {
      setMessage({
        success: false,
        isOpen: true,
        message: e.message ?? ''
      })
    }
  }

  return (
    <Box>
      <Box p={2} />
      <Button variant="primaryFlexCenter" onClick={openRequest}>
        <Flex>
          <Text>Make a request</Text>
          <BsArrowRight sx={{ ml: 2 }} size="1.5em" color="grey" />
        </Flex>
      </Button>
      <Box p={4} />
      <Box>
        <Table
          headers={['Request ID', 'Requested On', 'Expire At', 'Status']}
          data={nftDeposit}
          page={page}
          onPageChange={setPage}
          total={total}
        />
      </Box>

      <CustomModal
        isOpen={requestOpen}
        onRequestClose={closeRequest}
        overlayStyle={{
          justifyContent: 'center',
          textAlign: 'center'
        }}
        contentStyle={{
          height: '36%'
        }}
      >
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Card>
            <Text>Request to deposit</Text>
            <Box pt={2} />
            <Paragraph>
              You are about to make a request to deposit. Please allow up to 48 hours to obtain
              deposit address.
            </Paragraph>
            <Box pt={4} />
            <Flex variant="layout.hStack" sx={{ justifyContent: 'center' }}>
              <Button onClick={closeRequest} sx={{ background: 'muted' }}>
                Cancel
              </Button>
              <Box>
                <Button onClick={submitDeposit}>Confirm</Button>
              </Box>
            </Flex>
          </Card>
        </Box>
      </CustomModal>

      <DepositModal depositOpen={depositOpen} closeDeposit={closeDeposit} id={addressID} />

      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        onRequestClose={() => setMessage({ isOpen: false })}
        success={message.success}
      />
    </Box>
  )
}

const DepositModal: FC<{ depositOpen: boolean; closeDeposit: () => void; id: string | number }> = ({
  depositOpen,
  closeDeposit,
  id
}) => {
  const { data, error, mutate } = useSWR<{ data: Deposit }>(NFTService.getMyOneNFTDeposit(id))
  const [qr, setQR] = useState<string>('')

  const nftDeposit = data?.data

  useEffect(() => {
    const generateQR = async () => {
      if (nftDeposit?.recipient_addr) {
        const url = await QRCode.toDataURL(nftDeposit?.recipient_addr)
        setQR(url)
      } else {
        setQR('')
      }
    }

    generateQR()
  }, [nftDeposit?.recipient_addr])

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve address" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(nftDeposit?.recipient_addr ?? '')
  }

  const datetime = new Date(data.data.expired_at)
  const diff = datetime.getTime() - Date.now()
  const sec = Math.ceil(diff / 1000)
  const secLeft = Math.round(diff % 60)
  const mins = Math.ceil(sec / 60)
  const minsLeft = Math.round(mins % 60)
  const hrs = Math.round(mins / 60)

  const timeLeft = `${hrs}h ${minsLeft}m ${secLeft}s`

  return (
    <Box>
      <CustomModal
        isOpen={depositOpen}
        onRequestClose={closeDeposit}
        overlayStyle={{
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <Box>
          <Card>
            <Text>Deposit Now</Text>
            <Box pt={2} />
            <Text variant="mediumSmall">Deposit address for Voxto NFTs is dynamic</Text>
            <Box pt={2} />
            <Text variant="small">Expire in</Text>
            <Box pt={1} />
            <Text variant="large" sx={{ color: 'primary' }}>
              {/* ToDo : Will change to time left */}
              {timeLeft}
            </Text>
            <Box pt={2} />
            <Image src={qr} sx={{ width: 250 }} />
            <Box pt={2} />
            <Card sx={{ background: 'input' }}>
              <Flex variant="layout.flexCenterSpaceBetween">
                <Text sx={{ color: 'textMuted' }}>{nftDeposit?.contract_address}</Text>
                <IconButton onClick={handleCopy}>
                  <Image src="/assets/svg/copy.svg" />
                </IconButton>
              </Flex>
            </Card>
            <Box p={2} />
            <Text variant="small">
              Send only Voxto NFT to this deposit address. Ensure the network is{' '}
              {nftDeposit?.network_type} (ERC721).
            </Text>
            <Box p={2} />
            <Button onClick={closeDeposit}>Close</Button>
          </Card>
        </Box>
      </CustomModal>
    </Box>
  )
}

export default DepositRequest
