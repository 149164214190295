import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, Button, Card, Heading, Text, IconButton } from 'theme-ui'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

import AuthService, { LoginState } from 'network/services/auth'
import Link from 'components/link'
import Tab from 'components/Tab'
import FormInput from 'components/form-input'
import FormPhoneInput from 'components/form-phone-input'
import { login } from 'store/auth'
import axios from 'axios'

const LoginPage = () => {
  let navigate = useNavigate()
  let location = useLocation()

  const [isEmail, setIsEmail] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  let {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm<LoginState>({
    shouldUseNativeValidation: false
  })
  let state: any = location.state
  let from = state?.from?.pathname || '/home'

  const onSubmit = async (data: LoginState) => {
    let location = ''
    let ipAddress = ''
    try {
      const res = await axios.get('https://geolocation-db.com/json/')
      console.log(res.data)
      location = res?.data?.city ?? 'Unknown'
      ipAddress = res?.data?.IPv4 ?? 'Unknown'
    } catch (error) {
      console.log(error)
    }
    try {
      const response = await AuthService.login({
        ...data,
        location: location,
        ip_address: ipAddress
      })

      login({
        token: response.data.data.jwt.token,
        profile: response.data.data.user
      })

      navigate(from, { replace: true })
    } catch (error) {
      console.log(error)
      setError('password', {
        type: 'manual',
        message: 'Invalid email or password'
      })
    }
  }

  return (
    <Box
      variant="layout.flexCenterCenter"
      sx={{
        minHeight: ['calc(100vh - 51px - 144px)', 'inherit'],
        py: [5, 16],
        position: 'relative'
      }}
    >
      <Card px={10} py={12}>
        <Box
          variant="layout.flexStartCenter"
          sx={{ minHeight: 'inherit', flexDirection: 'column' }}
        >
          <Heading as="h1">Sign In</Heading>
          <Box pt={4} />
          <Heading as="h3">Sign in with your email</Heading>
          {/* <Heading as="h3">Sign in with your email or mobile</Heading> */}

          <Box pt={10} />

          {/* <Box sx={{ width: '100%' }}>
            <Tab defaultTab={isEmail ? 0 : 1} tabs={['Email', 'Phone']} onTabChange={setIsEmail} />
          </Box>

          <Box pt={10} /> */}

          <Box as="form" onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
            {/* {isEmail ? (
              <FormInput<LoginState>
                id="email"
                type="email"
                name="email"
                label="E-mail Address"
                register={register}
                rules={{
                  required: 'You must enter your email.',
                  pattern: {
                    value: new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$', 'ig'),
                    message: 'Enter a valid email address.'
                  }
                }}
                errors={errors}
              />
            ) : (
              <FormPhoneInput<LoginState>
                id="phone"
                type="phone"
                name="phone"
                label="Phone Number"
                setValue={setValue}
                register={register}
                rules={{ required: 'You must enter your phone.' }}
                errors={errors}
              />
            )} */}

            <FormInput<LoginState>
              id="email"
              type="email"
              name="email"
              label="E-mail Address"
              register={register}
              rules={{
                required: 'You must enter your email.',
                pattern: {
                  value: new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$', 'ig'),
                  message: 'Enter a valid email address.'
                }
              }}
              errors={errors}
            />

            <Box pt={4} />

            <FormInput<LoginState>
              id="password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password"
              register={register}
              rules={{ required: 'You must enter your password.' }}
              errors={errors}
              trailing={
                <IconButton px={4} type="button" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </IconButton>
              }
            />

            <Box pt={10} />

            <Button
              type="submit"
              onClick={() => {
                clearErrors()
              }}
              sx={{ width: ['100%', null, 500] }}
            >
              Login
            </Button>

            <Box pt={4} />

            {/* <Text as="p" sx={{ textAlign: 'center' }}>
              Or
            </Text>

            <Box pt={4} />

            <Button
              type="submit"
              onClick={() => {
                clearErrors()
              }}
              sx={{ background: 'input', width: ['100%', null, 500] }}
            >
              Continue with Apple
            </Button> */}

            <Box pt={10} />

            <Box variant="layout.flexCenterCenter" sx={{ flexDirection: 'column' }}>
              <Text>
                <Link to="/forget-password">Forgot Password</Link>
              </Text>

              <Box pt={4} />

              <Text>
                Don't have an account? <Link to="/sign-up">Sign Up</Link>
              </Text>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default LoginPage
