import client from '../request'

export type InformationVerification = {
  full_name: string
  address: string
  city: string
  zip: string
  country: string
  country_code: string
  phone: string
}

export type VerificationFiles = {
  id_type: string
  id_front: string
  id_back?: string
  selfie?: string
  document?: string
  status?: string
  completed?: boolean
}

export type Verification = {
  id: number
  full_name: string
  address: string
  city: string
  zip: string
  country: string
  country_code: string
  phone: string
  id_type: string
  id_front: string
  id_back: string
  selfie: string
  proof_document: string
  status?: string
  completed?: boolean
}

// export interface Verification extends InformationVerification, VerificationFiles {
//   status?: string
// }

// useSWR, return strings
// const get = '/me/verifications'
const get = (status: string) => {
  return `/me/verifications?status=${status}`
}

const getPending = `/me/verifications/pending`

// axios
const create = (data: InformationVerification) => {
  return client.post('/me/verifications', data)
}

// axios
const update = (id: number, data: VerificationFiles) => {
  return client.put(`/me/verifications/${id}`, data)
}

const VerificationService = {
  get,
  create,
  update,
  getPending
}

export default VerificationService
