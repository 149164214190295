import toUTCTime from 'modules/to-utc-time'
import { IDataResponse } from 'network/request'
import { User } from './auth'

export type Reward = {
  id: string | number
  user: User
  from_user: User
  amount: number
  type: string
  created_at: string
}

// useSWR, return strings
const getRewards = '/me/rewards'

// useSWR, return strings
const getRewardsStats = '/me/reward-stats'

// useSWR, return strings
const getReferrals = '/me/referrals'

// processing
const toRow = (data: IDataResponse<Reward>): Reward[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        created_at: toUTCTime(element.created_at),
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data?: IDataResponse<any>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const RewardService = {
  getRewards,
  getRewardsStats,
  getReferrals,
  toRow,
  toPaginate
}

export default RewardService
