import TitleWithBack from 'components/TitleWithBack'
import {
  Box,
  Grid,
  Heading,
  Image,
  Paragraph,
  Text,
  Flex,
  IconButton,
  Button,
  AspectRatio
} from 'theme-ui'
import { useNavigate, useParams } from 'react-router-dom'
import { useResolution } from 'hooks/useResolution'
import { FC, useState } from 'react'
import MobileReturnHeader from 'components/MobileReturnHeader'

import NFTService, { NFT } from 'network/services/nft'
import useSWR from 'swr'
import StatusModal from 'components/status-modal'
import LoadingCard from 'components/loading'
import ErrorCard from 'components/error'

const MyNFTsDetails = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="NFT" backRef="/nfts" />}
      <TitleWithBack backRef="/nfts" title="" />
      <PageView />
    </Box>
  )
}

const PageView: FC = () => {
  const { id } = useParams()
  const {
    data: nftData,
    error,
    mutate
  } = useSWR<{ data: NFT }>(id != null ? NFTService.getMyNFT(id) : null)
  const navigate = useNavigate()
  const [showLess, setShowLess] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<{
    isOpen: boolean
    message?: string
    success?: boolean
  }>({
    isOpen: false,
    success: true,
    message: ''
  })

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve NFT details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!nftData) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const nft = nftData.data

  const handleCopy = () => {
    navigator.clipboard.writeText(nft.contact_address)
    setModalMessage({
      success: true,
      isOpen: true,
      message: 'The address has been copied to your clipboard'
    })
  }

  return (
    <>
      <Grid columns={[1, 1, 2]} gap={5}>
        <Box sx={{ overflow: 'hidden', width: '100%', borderRadius: 10, pr: [0, 0, 10] }}>
          <AspectRatio ratio={1 / 1}>
            <Image
              src={nft.image_url}
              sx={{
                width: '100%',
                objectFit: 'cover'
              }}
            />
          </AspectRatio>
        </Box>
        <Flex variant="layout.vStack">
          <Heading>{nft.name}</Heading>
          <Box>
            <Paragraph variant="small" color="textMuted">
              {showLess || nft.description.length > 70 ? (
                <>{nft.description.slice(0, 70) + '...'}</>
              ) : (
                <>{nft.description}</>
              )}
            </Paragraph>
            {nft.description.length > 70 && (
              <Text
                variant="small"
                sx={{ color: 'primary', cursor: 'pointer' }}
                onClick={() => setShowLess(!showLess)}
              >
                {showLess ? 'Show More' : 'Show Less'}
              </Text>
            )}
          </Box>
          <Box pt={4}>
            <Text>Contact Address</Text>
            <Flex variant="layout.flexCenterStart" sx={{ height: 'auto' }}>
              <Text>{nft.contact_address}</Text>
              <Box pl={2} />
              <IconButton onClick={handleCopy}>
                <Image src="/assets/svg/copy.svg" />
              </IconButton>
            </Flex>
          </Box>
          {nft.stake_id && (
            <Box pt={4}>
              <Flex variant="layout.flexCenterStart">
                <Button onClick={() => navigate(`/stake/${nft.stake_id}`)} sx={{ pl: [3, 6] }}>
                  <Text variant="mediumSmall">View My Contract</Text>
                </Button>
                <Box p={2} />
                <Text variant="small" sx={{ color: 'textMuted' }}>
                  This NFT is linked to a subscription
                </Text>
              </Flex>
            </Box>
          )}
        </Flex>
      </Grid>
      <StatusModal
        isOpen={modalMessage.isOpen}
        onRequestClose={() => {
          setModalMessage({ isOpen: false })
        }}
        success={modalMessage.success}
        children={modalMessage.message}
      />
    </>
  )
}

export default MyNFTsDetails
