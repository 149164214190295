import { Box, Grid, Heading, Text, Flex } from 'theme-ui'
import useSWR from 'swr'
import { useState } from 'react'

import { ShopCard } from 'components/NFTs'

import NFTService, { NFT } from 'network/services/nft'
import { IDataResponse, serialize } from 'network/request'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import PageControl from 'components/page-control'

const ShopActive = () => {
  return (
    <Box>
      <Flex variant="layout.flexCenterSpaceBetween">
        <Heading as="h1">Live Auction</Heading>
      </Flex>
      <Box p={3} />
      <ActiveNFT />
    </Box>
  )
}

const ActiveNFT = () => {
  const [page, setPage] = useState(1)
  const limit = 6
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getAll, {
      page: page,
      limit: limit,
      sort: 'created_at:desc',
      owned: false,
      status: 'active'
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve NFT" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const activeNFTs = NFTService.toRow(data)
  const total = NFTService.toPaginate(data).total

  if (total === 0) {
    return <Text>There are no active listing at the moment</Text>
  }

  return (
    <Box>
      <Grid columns={[1, 2, 2, 3, 4]} gap={8}>
        {activeNFTs.map((item) => (
          <ShopCard key={item.id} nft={item} />
        ))}
      </Grid>

      {data && total > 0 && (
        <PageControl page={page} total={total} limit={limit} setPage={setPage} />
      )}
    </Box>
  )
}

export default ShopActive
