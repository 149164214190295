import { FormEvent, useRef, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import {
  DeepMap,
  FieldError,
  Path,
  get,
  RegisterOptions,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form'
import useSWR from 'swr'
import { Box, Button, Card, Flex, Image, Input, InputProps, Text } from 'theme-ui'
import _ from 'lodash'

import FileService from 'network/services/file'
import StatusModal from 'components/status-modal'

type UploadCardProps<TFormValues> = {
  image: JSX.Element
  description: string
  setValue: UseFormSetValue<TFormValues>
  rules?: RegisterOptions
  register: UseFormRegister<TFormValues>
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  name: Path<TFormValues>
} & Omit<InputProps, 'name'>

const UploadCard = <TFormValues extends Record<string, unknown>>({
  image,
  rules,
  setValue,
  register,
  description,
  errors,
  name
}: UploadCardProps<TFormValues>) => {
  const [uploadedFile, setUploadedFile] = useState<string>()
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })
  const uploadRef = useRef<HTMLInputElement | null>(null)

  const upload = async (e: FormEvent<HTMLInputElement>) => {
    // make sure files is not empty
    if (e.currentTarget?.files == null || _.isEmpty(e.currentTarget?.files)) {
      return
    }

    const files = e.currentTarget?.files

    try {
      const { data } = await FileService.create(files[0])

      if (data.success) {
        setValue(name, data.data as any)
        setUploadedFile(data.data)
      }
    } catch (e: any) {
      setMessage({
        isOpen: true,
        success: false,
        message: e.message
      })
    }
  }

  const { data: fileLink, error: fileError } = useSWR(
    uploadedFile ? FileService.get(uploadedFile) : null
  )

  if (fileError) {
    console.log(fileError)
  }
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  return (
    <Card
      variant="flexCenterSecondaryCard"
      sx={{ height: 350, border: hasError ? '1px solid rgb(255, 50, 50)' : '' }}
    >
      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        onRequestClose={() => setMessage({ isOpen: false })}
        success={message.success}
      />

      {fileLink ? (
        <Flex variant="layout.flexCenterCenter">
          <Image
            src={fileLink.data}
            alt={fileLink.data}
            sx={{
              objectFit: 'cover',
              height: '100%',
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        </Flex>
      ) : (
        <>
          {/* to trigger upload dialog */}
          <Input type="file" ref={uploadRef} sx={{ display: 'none' }} onChange={upload} />
          {/* to store url string */}
          <Input
            type="hidden"
            sx={{ display: 'none' }}
            aria-invalid={hasError}
            {...(register && register(name, rules))}
          />
          <Box
            sx={{
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '70%',
              height: '100%',
              textAlign: 'center'
            }}
          >
            <Flex variant="layout.flexCenterCenter" sx={{ p: 1, height: '40%' }}>
              {image}
            </Flex>
            <Flex variant="layout.flexCenterCenter" sx={{ height: '20%' }}>
              <Text variant="extraLarge" sx={{ textAlign: 'center' }}>
                {description}
              </Text>
            </Flex>
            {errors != null && (
              <ErrorMessage
                errors={errors}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                name={name as any}
                render={({ message }) => <Text color="red">{message}</Text>}
              />
            )}
            <Box pt={4}>
              <Button
                type="button"
                onClick={() => {
                  uploadRef?.current?.click()
                }}
              >
                Upload
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Card>
  )
}

export default UploadCard
