import CustomModal from 'components/modal'
import { FC } from 'react'
import { TiTick, TiTimes } from 'react-icons/ti'
import { AspectRatio, Box, Button, Card, Flex, Grid, Text } from 'theme-ui'

const ExampleModal: FC<{
  isOpen: boolean
  onRequestClose?: () => void
}> = ({ isOpen, onRequestClose }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
      }}
      contentStyle={{
        height: '80%'
      }}
    >
      <Box>
        <Card sx={{ width: 'auto', py: 7 }}>
          <Text variant="large" sx={{ textAlign: 'center', width: '100%' }}>
            KYC Guideline
          </Text>
          <Box p={3} />
          <Grid columns={[1, 2]} gap={2}>
            <Box
              sx={{
                overflow: 'hidden',
                width: '100%',
                borderRadius: 10,
                background: 'sidebarButtonBG'
              }}
            >
              <AspectRatio ratio={1 / 1}>
                <Box
                  sx={{
                    backgroundImage: `url(/assets/images/verification-example-1.png)`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '100%',
                    p: 3
                  }}
                >
                  <Flex variant="layout.flexCenterEnd" sx={{ height: 'auto' }}>
                    <Box variant="layout.iconContainer" sx={{ background: 'buttonBG' }}>
                      <TiTick size="2em" />
                    </Box>
                  </Flex>
                </Box>
              </AspectRatio>
            </Box>

            <Box
              sx={{
                overflow: 'hidden',
                width: '100%',
                borderRadius: 10,
                background: 'sidebarButtonBG'
              }}
            >
              <AspectRatio ratio={1 / 1}>
                <Box
                  sx={{
                    backgroundImage: 'url(/assets/images/verification-example-2.png)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '100%',
                    p: 3
                  }}
                >
                  <Flex variant="layout.flexCenterEnd" sx={{ height: 'auto' }}>
                    <Box variant="layout.iconContainer" sx={{ background: 'buttonBG' }}>
                      <TiTimes size="2em" />
                    </Box>
                  </Flex>
                </Box>
              </AspectRatio>
            </Box>
          </Grid>

          <Box p={4} />

          <Flex variant="layout.flexCenterCenter" sx={{ height: 'auto' }}>
            <Box
              variant="layout.coloredContainer"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100 }}
            >
              Tips
            </Box>
          </Flex>

          <Box p={4} />

          <Box>
            <Text as="p">
              • Do not block any information on your identification card / passport
              <br /> <br />• Do not wear any outfit that may obstruct the view of your face
            </Text>
          </Box>

          <Box p={4} />

          <Flex variant="layout.flexCenterCenter" sx={{ height: 'auto' }}>
            <Button onClick={onRequestClose}>Close</Button>
          </Flex>
        </Card>
      </Box>
    </CustomModal>
  )
}

export default ExampleModal
