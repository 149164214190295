import { FC, useMemo, useState } from 'react'
import { Box, Button, Flex, Grid, Select, Text } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { City, Country } from 'country-state-city'
import { useUpdateEffect } from 'usehooks-ts'
import { mutate } from 'swr'

import VerificationService, { Verification } from 'network/services/verification'
import FormInput from 'components/form-input'
import StatusModal from 'components/status-modal'

const PersonalInfoVerification: FC<{
  initialData: Verification | undefined
  setPageSelected: React.Dispatch<React.SetStateAction<string>>
}> = ({ initialData = null, setPageSelected }) => {
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })

  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    setValue,
    formState: { errors }
  } = useForm<Verification>({
    shouldUseNativeValidation: false,
    defaultValues: {
      ...initialData
    }
  })

  const country = watch('country')
  const countries = Country.getAllCountries()
  const cities = useMemo(() => {
    return country
      ? City.getCitiesOfCountry(countries.filter((item) => item.name === country)[0]?.isoCode)
      : []
  }, [country, countries])

  useUpdateEffect(() => {
    setValue('city', '')
  }, [country])

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (initialData?.status === 'approved') {
        setMessage({
          isOpen: true,
          success: false,
          message: 'KYC already approved'
        })
        return
      }

      if (initialData == null) {
        const { data: result } = await VerificationService.create(data)
        if (result.success) {
          setMessage({
            isOpen: true,
            success: true,
            message: ''
          })
        }
      } else {
        const { data: result } = await VerificationService.update(initialData.id, data)
        if (result.success) {
          setMessage({
            isOpen: true,
            success: true,
            message: ''
          })
        }
      }

      mutate(VerificationService.getPending)
    } catch (e: any) {
      setMessage({
        isOpen: true,
        success: false,
        message: e.message ?? ''
      })
    }
  })

  return (
    <Box>
      <StatusModal
        isOpen={message.isOpen}
        success={message.success}
        onRequestClose={() => {
          setMessage({ isOpen: false })

          if (message.success) {
            setPageSelected('identity_verification')
          }
        }}
        children={message.message}
      />

      <Box>
        <Text variant="extraLarge">Please confirm your details below</Text>
      </Box>
      <Box p={1} />
      <Box>
        <Text variant="mediumSmall" color="secondaryText">
          Please enter your full name as per your identification document
        </Text>
        <Box p={2} />
        <Box as="form" onSubmit={onSubmit}>
          <Grid columns={[1, 2]} sx={{ width: '100%' }} gap={10}>
            <Box>
              <FormInput<Verification>
                id="full_name"
                name="full_name"
                label="Full Name*"
                register={register}
                rules={{ required: 'You must enter your name.' }}
                errors={errors}
                readOnly={initialData?.completed}
              />
            </Box>
            <Box></Box>

            <Box>
              <Text variant="extraLarge">Please enter your address</Text>
            </Box>
            <Box sx={{ display: ['none', 'block'] }} />

            <Box>
              <Box>
                <Text>Country*</Text>
              </Box>
              <Box p={1} />
              <Select
                variant="forms.secondarySelect"
                sx={{ textAlign: 'left' }}
                {...register('country')}
                disabled={initialData?.completed}
              >
                {countries.map((item) => (
                  <option key={item.isoCode} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Box>

            <Box>
              <FormInput<Verification>
                id="address"
                name="address"
                label="Address*"
                register={register}
                rules={{ required: 'You must enter your address.' }}
                errors={errors}
                readOnly={initialData?.completed}
              />
            </Box>

            <Box>
              <Box>
                <Text>City*</Text>
              </Box>
              <Box p={1} />
              <Select
                variant="forms.secondarySelect"
                sx={{ textAlign: 'left' }}
                {...register('city')}
                disabled={initialData?.completed}
              >
                {cities?.map((item, index) => (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Box>

            <Box>
              <FormInput<Verification>
                id="zip"
                name="zip"
                label="Postal Code / ZIP*"
                register={register}
                rules={{ required: 'You must enter your zip code.' }}
                errors={errors}
                placeholder="50000"
                readOnly={initialData?.completed}
              />
            </Box>

            <Box>
              <Text variant="extraLarge">Please enter your phone number</Text>
            </Box>
            <Box sx={{ display: ['none', 'block'] }} />

            <Box>
              <Box>
                <Text>Country Code*</Text>
              </Box>
              <Box p={1} />
              <Select
                variant="forms.secondarySelect"
                sx={{ textAlign: 'left' }}
                {...register('country_code')}
                disabled={initialData?.completed}
              >
                {countries.map((item) => (
                  <option key={`${item.phonecode}${item.isoCode}`} value={item.isoCode}>
                    {`${item.name} (+${item.phonecode})`}
                  </option>
                ))}
              </Select>
            </Box>

            <Box>
              <FormInput<Verification>
                id="phone"
                name="phone"
                label="Mobile Phone Number*"
                register={register}
                rules={{ required: 'You must enter your phone number.' }}
                errors={errors}
                readOnly={initialData?.completed}
              />
            </Box>

            <Box sx={{ display: ['none', 'block'] }} />
            <Flex variant="layout.flexCenterEnd">
              {!initialData?.completed && (
                <Button type="submit" onClick={() => clearErrors()}>
                  {initialData == null ? 'Submit' : 'Update'}
                </Button>
              )}
            </Flex>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default PersonalInfoVerification
