import { FC, ReactNode } from 'react'
import { Box, Card, Flex, Text } from 'theme-ui'
import commaNumber from 'modules/comma-number'

const MarketCard: FC<{
  currency: string
  icon: ReactNode
  price: string
  change: number | string
}> = ({ currency, icon, price, change }) => (
  <Card variant="muted">
    <Box>
      <Flex variant="layout.hStack">
        {icon}
        <Box>
          <Text color="textMuted">{currency}</Text>
        </Box>
      </Flex>

      <Flex mt={3} variant="layout.flexCenterSpaceBetween">
        <Box>
          <Text as="p" variant="mediumSmall" color="textMuted">
            Current Price
          </Text>
          <Text as="p" variant="mediumTitle">
            ${commaNumber(parseFloat(price).toFixed(2))}
          </Text>
        </Box>
        <Box>
          <Text as="p" variant="mediumSmall" color="textMuted">
            24h Change
          </Text>
          <Text
            as="p"
            variant="mediumTitle"
            color={change > 0 ? 'success' : 'danger'}
            sx={{ textAlign: 'right' }}
          >
            <>
              {change > 0 && '+'}
              {change}%
            </>
          </Text>
        </Box>
      </Flex>
    </Box>
  </Card>
)

export default MarketCard
