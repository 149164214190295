import client, { IDataResponse } from '../request'
import toUTCTime from 'modules/to-utc-time'
import { NFTTransfer } from './nft_transfer'
import { NFTWithdraw } from './nft_withdraw'

export type NFT = {
  id: string | number
  name: string
  description: string
  type: string
  token_type: string
  contact_address: string
  status: string
  blocked: boolean
  user_id: number | string
  stake_id: number | string
  staked_at: string
  created_at: string
  updated_at: string
  expired_at: string
  image_url: string
  current_bid?: number
  nft_deposits: Deposit[]
  nft_withdraws: NFTWithdraw[]
  nft_transfers: NFTTransfer[]
  pending_nft_withdraws_count: number
  pending_nft_transfers_count: number
  pending_nft_deposits_count: number
}

export type CreateBid = {
  amount: number
  nft_id: number | string
}

export type Bid = {
  id: number | string
  token_type: string
  amount: number
  status?: string
  nft_id: number | string
  user_id: number | string
  created_at: string
  updated_at: string
  user: {
    email: string
  }
}

export type Deposit = {
  id: number | string
  token_type: string
  network_type: string
  status: string
  sender_addr: string
  recipient_addr: string
  contract_address: string
  network_fee: number
  ref_no: string
  txn_hash: string
  nft_id: number | string
  user_id: number | string
  auditor_id: number | string
  nft_transaction_id: number | string
  reason: string
  audited_at: string
  confirmed_at: string
  expired_at: string
  created_at: string
  update_at: string
}

export type CreateDeposit = {
  token_type: string
  network_type: string
}

// useSWR, return strings
const getMyNFTs = '/me/nfts'

// useSWR, return strings
const getMyNFT = (id: string | number) => {
  return `/me/nfts/${id}`
}

// useSWR, return strings
const getAll = '/nfts'

const getOneNFT = (id: string | number) => {
  return `/nfts/${id}`
}

const getNFTBidByNFTId = (id: string | number) => {
  return `/nfts/${id}/bids`
}

const getOneNFTBid = (id: string | number) => {
  return `/nft-bids/${id}`
}

const createNFTBid = (data: CreateBid) => {
  return client.post('/nft-bids', data)
}

const getMyNFTDeposits = '/me/nft-deposits'

const getMyOneNFTDeposit = (id: string | number) => {
  return `/me/nft-deposits/${id}`
}

const createNFTDeposit = (data: CreateDeposit) => {
  return client.post('/me/nft-deposits', data)
}

// processing
const toRow = (data: IDataResponse<NFT>): NFT[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const bidToRow = (data: IDataResponse<Bid>): Bid[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toRowDeposit = (data: IDataResponse<Deposit>): Deposit[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        created_at: toUTCTime(element.created_at),
        expired_at: toUTCTime(element.expired_at),
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<NFT>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const bidToPaginate = (data: IDataResponse<Bid>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const toPaginateDeposit = (data: IDataResponse<Deposit>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const NFTService = {
  getMyNFTs,
  getAll,
  getMyNFTDeposits,
  getOneNFT,
  getMyNFT,
  getNFTBidByNFTId,
  getOneNFTBid,
  getMyOneNFTDeposit,
  createNFTBid,
  createNFTDeposit,
  toRow,
  bidToRow,
  toRowDeposit,
  toPaginate,
  bidToPaginate,
  toPaginateDeposit
}

export default NFTService
