import { Box } from 'theme-ui'
import { Outlet, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { motion } from 'framer-motion'

import Sidebar from 'components/Sidebar'
import Header from 'components/header'
import { NavBarDisplayContextProvider } from 'context/navBarDisplayContext'
import { SideBarDisplayContextProvider } from 'context/sideBarDisplay'
import { currencyStore } from 'store/currency'
import { fadeAnim } from 'lib/animation'

let AnimatedBox = motion(Box)

const AuthenticatedLayout = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    // fetch ticker once
    axios
      .get(
        `https://api.binance.com/api/v3/ticker/price?symbols=%5B"BTCUSDT","ETHUSDT","BNBUSDT"%5D`
      )
      .then((res) => {
        let data = res.data
        data.forEach((datum: any) => {
          let obj = {
            e: '',
            E: 0,
            s: datum.symbol,
            c: datum.price,
            p: '',
            P: '',
            v: ''
          }

          if (datum.symbol.toLowerCase() === 'btcusdt') {
            currencyStore.btc = obj
          }
          if (datum.symbol.toLowerCase() === 'ethusdt') {
            currencyStore.eth = obj
          }
          if (datum.symbol.toLowerCase() === 'bnbusdt') {
            currencyStore.bnb = obj
          }
        })
      })
      .catch((err) => {})
  }, [])

  return (
    <NavBarDisplayContextProvider>
      <SideBarDisplayContextProvider>
        <>
          <Header />
          <AnimatedBox
            key={pathname}
            variant="layout.mainContainer"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnim}
          >
            <Outlet />
          </AnimatedBox>
          <Sidebar />
        </>
      </SideBarDisplayContextProvider>
    </NavBarDisplayContextProvider>
  )
}

export default AuthenticatedLayout
