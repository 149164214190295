import { Box, Card, Grid, Image, Text, AspectRatio } from 'theme-ui'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { NFT } from 'network/services/nft'

const MyNFTCard: FC<{ nft: NFT }> = ({ nft }) => {
  const navigate = useNavigate()

  return (
    <Card
      sx={{
        height: 'calc(100% - 50px)',
        cursor: 'pointer'
      }}
      onClick={() => navigate(`${nft.id}`)}
    >
      <Grid>
        <Box sx={{ borderRadius: 10, overflow: 'hidden' }}>
          <AspectRatio ratio={1 / 1}>
            <Image
              src={nft.image_url}
              sx={{
                width: '100%',
                objectFit: 'cover'
              }}
            />
          </AspectRatio>
        </Box>

        <Text>{nft.name}</Text>
        <Box>
          <Text variant="small" sx={{ color: 'textMuted' }}>
            {nft.description}
          </Text>
        </Box>
        {nft.stake_id ? (
          <Box
            sx={{
              background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%',
              borderRadius: 30,
              p: 3,
              color: 'buttonText'
            }}
            variant="layout.flexCenterCenter"
          >
            <Image src="/assets/svg/check.svg" />
            <Box sx={{ ml: 4 }}>Linked to Contract</Box>
          </Box>
        ) : (
          <Box
            sx={{
              background: 'dark',
              borderRadius: 30,
              p: 3,
              color: 'buttonText'
            }}
            variant="layout.flexCenterCenter"
          >
            <Image src="/assets/svg/check-black.svg" />
            <Box sx={{ ml: 4 }}>Not Linked</Box>
          </Box>
        )}
      </Grid>
    </Card>
  )
}

export default MyNFTCard
