import { FC } from 'react'
import { Box, Button, Card, Flex, Grid, Text } from 'theme-ui'
import { BsArrowRight } from 'react-icons/bs'

import { Pool } from 'network/services/pool'
import commaNumber from 'modules/comma-number'
import MoreInfo from 'components/more-info'
import TokenIcon from 'components/icons/token'
import Link from 'components/link'
import ProgressBar from 'components/progress-bar'

const LaunchpoolCard: FC<{ pool: Pool }> = ({ pool }) => (
  // TODO: we need minWidth for this
  <Card variant="secondary">
    <Box>
      <Flex variant="layout.flexCenterSpaceBetween">
        <Flex variant="layout.vStack" sx={{ '> * + *': { mt: 1 } }}>
          <Flex variant="layout.hStack">
            <TokenIcon type={pool.token_type} />
            <Box>
              <Text as="p" variant="large">
                {pool.token_name} Pool
              </Text>
            </Box>
            <Box>
              <MoreInfo direction="top">
                <Box
                  variant="layout.borderedTextCenter"
                  bg="dark"
                  p={2}
                  sx={{ width: 'max-content' }}
                >
                  <Text variant="small" color="textMuted">
                    A limitation release for stakers
                  </Text>
                </Box>
              </MoreInfo>
            </Box>
          </Flex>
          <Text as="p" variant="small" color="textMuted">
            {`Stake ${pool.token_type}, Earn VXT`}
          </Text>
        </Flex>
        <Flex>
          <Link to={`/stake/overview/${pool.id}`}>
            <Button
              variant="round"
              sx={{
                width: 30,
                height: 30
              }}
            >
              <BsArrowRight />
            </Button>
          </Link>
        </Flex>
      </Flex>

      <Box p={2} />

      <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: 'auto' }}>
        {pool.pool_size / (pool.max_pool_size ?? pool.pool_size) <= 0.8 ? (
          <Box sx={{ ml: `${(pool.pool_size / (pool.max_pool_size ?? pool.pool_size)) * 100}%` }}>
            <Text>{pool.pool_size}</Text>
          </Box>
        ) : (
          <Box />
        )}
        <Box>
          <Text>{`${pool.max_pool_size ?? pool.pool_size} ${pool.token_type}`}</Text>
        </Box>
      </Flex>
      <ProgressBar value={pool.pool_size / (pool.max_pool_size ?? pool.pool_size)} />

      <Box p={2} />

      <Grid columns={2} gap={0.5}>
        <Text variant="mediumSmall" color="textMuted">
          Participants
        </Text>
        <Text variant="mediumSmall" sx={{ textAlign: 'right' }}>
          {pool.total_participants_count}
        </Text>
        <Text variant="mediumSmall" color="textMuted">
          Current Pool
        </Text>
        <Text variant="mediumSmall" sx={{ textAlign: 'right' }}>
          {`${commaNumber(pool.pool_size)} ${pool.token_type}`}
        </Text>
      </Grid>
    </Box>
  </Card>
)

export default LaunchpoolCard
