import { FC, useState } from 'react'
import { Box, Flex, Button, Text } from 'theme-ui'
import useSWR, { mutate } from 'swr'

import { IDataResponse, serialize } from 'network/request'
import NotificationsService, { Notifications } from 'network/services/notification'
import { useResolution } from 'hooks/useResolution'
import MobileReturnHeader from 'components/MobileReturnHeader'
import TitleWithBack from 'components/TitleWithBack'
import { NotiCard } from 'components/Notification'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import PageControl from 'components/page-control'

const NotificationPage = () => {
  return (
    <Box variant="layout.pageContainer">
      <PageView />
    </Box>
  )
}

const PageView = () => {
  const [page, setPage] = useState(1)
  const limit = 10
  const {
    data,
    error,
    mutate: refresh
  } = useSWR<IDataResponse<Notifications>>(
    serialize(NotificationsService.getNotifications, {
      sort: 'created_at:desc',
      page: page,
      limit: limit
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve Notification" refresh={() => refresh()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const notifications = NotificationsService.toRow(data)
  const total = NotificationsService.toPaginate(data).total

  return (
    <Box>
      <NotificationView refresh={refresh} notifications={notifications} total={total} />
      <Box p={3} />
      {total === 0 && (
        <Flex variant="layout.flexCenterCenter">
          <Text>There are no notifications at the moment</Text>
        </Flex>
      )}

      {data && total > 0 && (
        <PageControl page={page} total={total} limit={limit} setPage={setPage} />
      )}
    </Box>
  )
}

const NotificationView: FC<{
  refresh: () => void
  notifications: Notifications[]
  total: number
}> = ({ refresh, notifications, total }) => {
  const { isMobile } = useResolution()

  const updateNotification = async () => {
    try {
      const { data: result } = await NotificationsService.markNotificationsAsRead({
        ids: notifications.map((notification) => notification.id)
      })

      if (result.success) {
        // refresh this page
        refresh()
        // refresh home page notification badge
        mutate(
          serialize(NotificationsService.getNotifications, {
            read: 0,
            sort: 'created_at:desc',
            limit: 3
          })
        )
      } else {
        // TODO: show error message
      }
    } catch (e: any) {
      console.log(e)
    }
  }
  return (
    <>
      {isMobile && <MobileReturnHeader title="Notifications" delta={-1} />}
      <Flex>
        <TitleWithBack delta={-1} title="Notifications" />
        {total > 0 && (
          <Box variant="layout.flexCenterEnd">
            <Button variant="secondary" onClick={() => updateNotification()}>
              <Text sx={{ color: 'primary', cursor: 'pointer' }}>Mark As Read</Text>
            </Button>
          </Box>
        )}
      </Flex>
      <Box p={3} />
      {notifications.map((item) => (
        <>
          <NotiCard notification={item} />
          <Box p={1} />
        </>
      ))}
    </>
  )
}

export default NotificationPage
