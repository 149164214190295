import Table from 'components/Table'
import { Box, Heading, Flex } from 'theme-ui'
import { TiArrowUnsorted } from 'react-icons/ti'

const TransactionHistory = () => {
  // TODO: integrate api
  // TODO: add pagination

  return (
    <Box>
      {/* ToDo: Change to Sorting Button */}
      <Box p={2} />
      <Flex variant="layout.hStack">
        <Heading>All Type</Heading>
        <TiArrowUnsorted />
      </Flex>
      <Box p={4} />
      <Table
        headers={['Type', 'Date & Time', 'Contact Address', 'NFT Name', 'Status']}
        data={[]}
        total={0}
      />
    </Box>
  )
}

export default TransactionHistory
