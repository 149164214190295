import { Card, Button, Box, Heading, Text, Image } from 'theme-ui'

import Link from 'components/link'

const SignUpPage = () => {
  const signUpWithApple = () => {}

  // TODO: tweak for mobile landscape
  return (
    <Box
      variant="layout.flexCenterCenter"
      sx={{
        minHeight: ['calc(30vh - 51px - 144px)', 'inherit'],
        py: [5, 16],
        position: 'relative'
      }}
    >
      <Card px={10} py={12}>
        <Box variant="layout.flexCenterCenter" sx={{ flexDirection: 'column' }}>
          <Heading>Welcome to Voxto</Heading>
          <Box pt={10} />
          <Link to="/sign-up">
            <Button sx={{ width: [300, null, 500] }}>Sign up with email</Button>
            {/* <Button sx={{ width: [300, null, 500] }}>Sign up with phone or email</Button> */}
          </Link>
          {/* <Box pt={4} />
          <Text>Or</Text>
          <Box pt={4} />
          <Button
            onClick={() => {
              signUpWithApple()
            }}
            sx={{ background: 'black', width: [300, null, 500] }}
          >
            <Image src="/assets/svg/apple-logo.svg" />
            <Text sx={{ pl: 3 }}>Continue with Apple</Text>
          </Button> */}
          <Box pt={10} />
          <Text>
            Already have an account? <Link to="/sign-in">Log In</Link>
          </Text>
        </Box>
      </Card>
    </Box>
  )
}

export default SignUpPage
