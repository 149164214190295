import { DeepMap, FieldError, get, Path, RegisterOptions, UseFormRegister } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Checkbox, InputProps, Label, Text } from 'theme-ui'

export type FormCheckboxProps<TFormValues> = {
  name: Path<TFormValues>
  rules?: RegisterOptions
  register?: UseFormRegister<TFormValues>
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  label?: string
  children?: JSX.Element
} & Omit<InputProps, 'name'>

export const FormCheckbox = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  label,
  children,
  ...props
}: FormCheckboxProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  return (
    <>
      {label && (
        <Label htmlFor={name} mb={2}>
          {label}
        </Label>
      )}

      <Label>
        <Checkbox
          bg="input"
          name={name}
          aria-invalid={hasError}
          {...props}
          {...(register && register(name, rules))}
        />
        {children}
      </Label>

      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => <Text color="red">{message}</Text>}
      />
    </>
  )
}

export default FormCheckbox
