import { FC, useEffect, useState } from 'react'
import { AspectRatio, Box, Card, Flex, Grid, Image, Text } from 'theme-ui'

const NewsCardLarge: FC<{
  imageSrc: string
  title: string
  source?: string
  date: string
}> = ({ imageSrc, title, source, date }) => {
  const [slicedTitle, setSlicedTitle] = useState<string>()

  useEffect(() => {
    if (title.length > 70) setSlicedTitle(`${title.slice(0, 71)} ...`)
    else setSlicedTitle(title)
  }, [title])

  return (
    <Card sx={{ background: 'none', width: '100%', px: 0 }}>
      <Grid gap={5}>
        <Box sx={{ overflow: 'hidden', borderRadius: 10 }}>
          <AspectRatio ratio={16 / 9}>
            <Image
              src={imageSrc}
              sx={{
                objectFit: 'cover'
              }}
            />
          </AspectRatio>
        </Box>
        <Box>
          <Text variant="large">{slicedTitle}</Text>
        </Box>
        <Flex variant="layout.hStack">
          <Box>
            <Text variant="small" color="textMuted" sx={{ textAlign: 'left' }}>
              {source}
            </Text>
          </Box>
          <Box>
            <Text variant="small" color="primary">
              |
            </Text>
          </Box>
          <Box>
            <Text variant="small">{date}</Text>
          </Box>
        </Flex>
      </Grid>
    </Card>
  )
}

export default NewsCardLarge
