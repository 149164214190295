/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import { Box, Button, CSSProperties, Flex, ThemeUICSSProperties } from 'theme-ui'
import { TargetAndTransition, motion } from 'framer-motion'

const Tab: FC<{
  defaultTab?: number
  onTabChange?: (tabNo: number) => void
  tabs?: string[]
}> = ({ defaultTab, onTabChange = (tabNo: number) => {}, tabs = [] }) => {
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [currentTabAnimation, setCurrentTabAnimation] = useState<TargetAndTransition>({})
  const [startPosition, setStartPosition] = useState<CSSProperties>({ left: 0 })

  const switchTab = (targetTab: number) => {
    if (targetTab !== currentTab) {
      setCurrentTabAnimation({
        left: [(100 / tabs.length) * currentTab + '%', (100 / tabs.length) * targetTab + '%']
      })
      setCurrentTab(targetTab)
      setStartPosition({ left: (100 / tabs.length) * currentTab + '%' })
    }
    onTabChange(targetTab)
  }

  useEffect(() => {
    if (defaultTab) {
      switchTab(defaultTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab])

  return (
    <Box sx={{ position: 'relative' }}>
      <Flex
        variant="layout.flexCenterSpaceBetween"
        sx={{
          background: 'darkBG',
          borderRadius: 30
        }}
      >
        <AnimatedBackground
          length={tabs.length}
          startPosition={startPosition}
          currentTabAnimation={currentTabAnimation}
        />
        {tabs.map((item, index) => (
          <Flex key={index} variant="layout.flexCenterCenter" sx={{ flex: 1 }}>
            <Button
              variant="text"
              sx={{
                width: '100%',
                height: '100%',
                zIndex: 2,
                py: 4,
                color: currentTab === index ? 'white' : 'default'
              }}
              onClick={() => switchTab(index)}
            >
              {item}
            </Button>
          </Flex>
        ))}
      </Flex>
    </Box>
  )
}

const AnimatedBackground: FC<{
  length: number
  startPosition: ThemeUICSSProperties
  currentTabAnimation: TargetAndTransition
}> = ({ length, startPosition, currentTabAnimation }) => (
  <motion.div
    sx={{
      background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%',
      borderRadius: 30,
      position: 'absolute',
      bottom: 0,
      width: 100 / length + '%',
      height: '100%',
      flex: 1,
      zIndex: 1,
      ...startPosition
    }}
    animate={currentTabAnimation}
    transition={{ duration: 0.3 }}
  />
)

export default Tab
