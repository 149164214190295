import {
  Box,
  Grid,
  Heading,
  Image,
  Text,
  Flex,
  Button,
  Paragraph,
  Card,
  Divider,
  IconButton,
  AspectRatio
} from 'theme-ui'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useResolution } from 'hooks/useResolution'
import Tab from 'components/Tab'
import MobileReturnHeader from 'components/MobileReturnHeader'
import TitleWithBack from 'components/TitleWithBack'
import CustomModal from 'components/modal'
import { useNavigate } from 'react-router-dom'
import StatusModal from 'components/status-modal'

import NFTService, { NFT, Bid, CreateBid } from 'network/services/nft'
import useSWR from 'swr'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import { IDataResponse, serialize } from 'network/request'
import WalletService, { Wallet } from 'network/services/wallet'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import FormInput from 'components/form-input'
import ErrorPopUpCard from 'components/errorPopUp'
import _ from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'

let AnimatedBox = motion(Box)

const NFTDetail = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="NFT's" backRef="/nfts?tab=shop" />}
      <TitleWithBack backRef="/nfts?tab=shop" title="" />
      <PageView />
    </Box>
  )
}

const PageView: FC = () => {
  const { id } = useParams()
  const { data, error, mutate } = useSWR<{ data: NFT }>(
    id != null ? NFTService.getOneNFT(id) : null
  )
  const [tab, setTab] = useState<number>(0)
  const [bidModalOpen, setBidModalOpen] = useState<boolean>(false)
  const [depositModalOpen, setDepositModalOpen] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<{
    isOpen: boolean
    message?: string
    success?: boolean
  }>({
    isOpen: false,
    success: true,
    message: ''
  })

  const nft = data?.data

  const methods = useForm<CreateBid>({
    shouldUseNativeValidation: false
  })

  useEffect(() => {
    if (nft) {
      methods.setValue('nft_id', nft.id)
    }
  }, [nft, methods])

  const {
    data: bidHistoryData,
    error: bidHistoryError,
    mutate: mutateBidHistory
  } = useSWR<IDataResponse<Bid>>(
    nft?.id != null
      ? serialize(NFTService.getNFTBidByNFTId(nft.id), {
          page: 1,
          limit: 5,
          sort: 'created_at:desc'
        })
      : null
  )
  const bidHistory = bidHistoryData ? NFTService.bidToRow(bidHistoryData) : undefined

  const { data: walletData, error: walletError } = useSWR<{ data: Wallet }>(
    serialize(WalletService.getByToken('VXT'))
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve NFT details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(nft?.contact_address ?? '')
    setModalMessage({
      success: true,
      isOpen: true,
      message: 'The address has been copied to your clipboard'
    })
  }

  const openDepositModal = () => {
    setBidModalOpen(false)
    setDepositModalOpen(true)
  }

  const openModal = () => {
    setBidModalOpen(true)
  }

  const closeDepositModal = () => {
    setDepositModalOpen(false)
  }

  const onSubmit = methods.handleSubmit(async (data) => {
    if (data.amount > walletData!.data.balance) {
      openDepositModal()
    } else {
      setBidModalOpen(false)
      try {
        const { data: result } = await NFTService.createNFTBid(data)
        if (result.success) {
          setModalMessage({
            success: true,
            isOpen: true,
            message: 'Bid successfully'
          })
          mutateBidHistory()
          mutate && mutate()
        }
      } catch (e: any) {
        setModalMessage({
          success: false,
          isOpen: true,
          message: e.message
        })
      }
    }
  })

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const datetime = new Date(nft?.expired_at ? nft?.expired_at : '')
  const diff = datetime.getTime() - Date.now()
  const mins = Math.ceil(diff / 1000 / 60)
  const hrs = Math.round(mins / 60)
  const day = Math.round(hrs / 24)
  const endDate = `${datetime.getDate()} ${months[datetime.getMonth()]} ${datetime.getFullYear()}`
  const timeLeft = `${day > 0 ? day + ' Days' : hrs > 0 ? hrs + ' Hours' : mins + 'Minutes'}`

  return (
    <FormProvider {...methods}>
      <Grid columns={[1, 1, 2]}>
        <Box sx={{ overflow: 'hidden', width: '100%', borderRadius: 10, pr: [0, 0, 10] }}>
          <AspectRatio ratio={1 / 1}>
            <Image
              src={nft?.image_url}
              sx={{
                width: '100%',
                objectFit: 'cover'
              }}
            />
          </AspectRatio>
        </Box>
        <Box>
          <Heading>{nft?.name}</Heading>
          <Box p={2} />
          <Box
            variant="layout.borderedText"
            sx={{
              background: 'linear-gradient(180deg, #1F244F 0%, #1F244F66 100%)',
              width: 160
            }}
          >
            {nft?.status !== 'active' ? (
              <Text>Ended on {endDate}</Text>
            ) : (
              <Text>End in {timeLeft}</Text>
            )}
          </Box>
          <Box p={3} />
          <Grid columns={[1, 2]} gap={[10, 3]}>
            <Grid>
              <Text>{nft?.status !== 'active' ? 'Last Bid' : 'Current Bid'}</Text>
              <Text>
                {nft?.current_bid} {nft?.token_type}
              </Text>
            </Grid>
            <Grid>
              <Text>Contact Address</Text>
              <Flex>
                <Text>{nft?.contact_address}</Text>
                <Box pl={2} />
                <IconButton onClick={handleCopy}>
                  <Image src="/assets/svg/copy.svg" />
                </IconButton>
              </Flex>
            </Grid>
          </Grid>
          <Box p={[5, 2]} />
          {nft?.status === 'active' && (
            <Button sx={{ width: '100%' }} onClick={openModal}>
              Place a Bid
            </Button>
          )}
          <Box p={[5, 2]} />
          <Grid columns={2}>
            <Box
              variant="layout.borderedText"
              sx={{ background: 'linear-gradient(180deg, #1F244F 0%, #1F244F66 100%)', p: 5 }}
            >
              <Grid gap={1}>
                <Text>Type</Text>
                <Text sx={{ color: 'primary' }}>{_.startCase(nft?.type)}</Text>
              </Grid>
            </Box>
            <Box
              variant="layout.borderedText"
              sx={{ background: 'linear-gradient(180deg, #1F244F 0%, #1F244F66 100%)', p: 5 }}
            >
              <Grid gap={1}>
                <Text>Asset Type</Text>
                <Text sx={{ color: 'primary' }}>Universal</Text>
              </Grid>
            </Box>
          </Grid>
          <Box p={[4, 2]} />
          <Box sx={{ width: '100%' }}>
            <Tab defaultTab={tab} tabs={['Details', 'History']} onTabChange={setTab} />
          </Box>
          <Box p={2} />
          <Box>
            <AnimatePresence exitBeforeEnter>
              {tab === 0 ? (
                <AnimatedBox
                  key="description"
                  initial="hide"
                  animate="show"
                  exit="hide"
                  variants={fadeAnim}
                >
                  <Paragraph variant="block">{nft?.description}</Paragraph>
                </AnimatedBox>
              ) : (
                <AnimatedBox
                  key="history"
                  initial="hide"
                  animate="show"
                  exit="hide"
                  variants={fadeAnim}
                >
                  <BidHistory bidHistory={bidHistory} bidHistoryError={bidHistoryError} />
                </AnimatedBox>
              )}
            </AnimatePresence>
          </Box>
        </Box>
      </Grid>
      {nft != null && (
        <BidModal
          wallet={walletData?.data}
          walletError={walletError}
          nft={nft!}
          isOpen={bidModalOpen}
          onRequestClose={() => setBidModalOpen(false)}
          onSubmit={onSubmit}
        />
      )}

      <DepositModal isOpen={depositModalOpen} onRequestClose={closeDepositModal} />

      <StatusModal
        isOpen={modalMessage.isOpen}
        onRequestClose={() => {
          setModalMessage({ isOpen: false })
        }}
        success={modalMessage.success}
        children={modalMessage.message}
      />
    </FormProvider>
  )
}

const BidHistory: FC<{ bidHistory?: Bid[]; bidHistoryError?: any }> = ({
  bidHistory,
  bidHistoryError
}) => {
  if (bidHistoryError) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve Bid History" />
      </Flex>
    )
  }

  if (!bidHistory) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  return (
    <Grid gap={2}>
      {bidHistory?.map((item) => {
        const email1 = item.user.email.split('@')[0]
        const email2 = item.user.email.split('@')[1][0]
        const formattedEmail = `${email1.at(0)}***${email1.at(email1.length - 1)}@${email2}***.***`
        const createdAt = new Date(item.created_at)
        const timeNow = new Date()
        const mins = Math.ceil((timeNow.getTime() - createdAt.getTime()) / 1000 / 60)
        const hrs = Math.round(mins / 60)
        const dayPassed = Math.round(hrs / 24)
        const hrsPassed = dayPassed > 0 ? hrs % dayPassed : hrs
        const timeStr = `${dayPassed > 0 ? dayPassed : ''} ${dayPassed > 0 ? 'day(s)' : ''} ${
          hrsPassed > 0 ? hrsPassed : ''
        } ${hrsPassed > 0 ? 'hour(s)' : ''} ago`
        const minStr = `${mins} minute(s) ago`
        return (
          <Box>
            <Box>
              <Text>
                <Text color="textMuted">Bid</Text>{' '}
                <Text variant="large">
                  ~{item.amount} {item.token_type}
                </Text>
              </Text>
            </Box>
            <Box>
              <Text>
                <Text color="textMuted">by</Text>
                <Text variant="large">{` ${formattedEmail}`}</Text>
                <Text color="textMuted">{` ${mins >= 60 ? timeStr : minStr}`}</Text>
              </Text>
            </Box>
          </Box>
        )
      })}
    </Grid>
  )
}

const BidModal: FC<{
  wallet?: Wallet
  walletError?: any
  nft: NFT
  isOpen: boolean
  onRequestClose: () => any
  onSubmit: () => any
}> = ({ wallet, walletError, nft, isOpen, onRequestClose, onSubmit }) => {
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext<CreateBid>()
  const amount = watch('amount')

  if (!wallet) {
    return (
      <>
        {isOpen && <ErrorPopUpCard onRequestClose={onRequestClose} message={'Wallet Not Found'} />}
      </>
    )
  }

  if (walletError) {
    return (
      <>
        {isOpen && (
          <ErrorPopUpCard onRequestClose={onRequestClose} message={'Unable to retrieve Wallet'} />
        )}
      </>
    )
  }

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Box>
        <Card sx={{ p: 10 }}>
          <Grid>
            <Text>Place a bid</Text>
            <Text variant="mediumSmall" sx={{ color: 'textMuted' }}>
              You are about to place a bid for {nft.name} from Voxto.
            </Text>
            <Text>Your Bid</Text>
            <FormInput<CreateBid>
              name="amount"
              placeholder="Amount"
              register={register}
              rules={{
                required: 'You must enter your an amount.',
                // TODO: more than instead of equal
                min: {
                  value: nft.current_bid ?? 0,
                  message: `Must be more than ${nft.current_bid} VXT`
                }
              }}
              errors={errors}
              trailing={
                <Box>
                  <Text color="primary">VXT</Text>
                </Box>
              }
            />
            <Text variant="mediumSmall" sx={{ color: 'textMuted' }}>
              Must be more than {nft.current_bid} VXT
            </Text>

            <Box p={1} />

            <Flex variant="layout.flexCenterSpaceBetween">
              <Text variant="mediumSmall" sx={{ color: 'textMuted' }}>
                Your Balance
              </Text>
              <Text>
                {wallet.balance} {wallet.token_type}
              </Text>
            </Flex>
            <Card sx={{ background: 'input' }}>
              <Text sx={{ color: 'textMuted' }}>
                Only tokens from the winning bid are burnt to reduce the circulating supply of{' '}
                {wallet.token_type}
              </Text>
            </Card>
            <Divider />
            <Flex variant="layout.flexCenterSpaceBetween">
              <Text sx={{ color: 'textMuted' }}>You Will Pay</Text>
              <Text>
                {amount} {wallet.token_type}
              </Text>
            </Flex>
            <Grid columns={2}>
              <Button onClick={onRequestClose} sx={{ background: 'input' }}>
                Cancel
              </Button>
              <Button onClick={onSubmit}>Place Bid</Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </CustomModal>
  )
}

const DepositModal: FC<{ isOpen: boolean; onRequestClose: () => any }> = ({
  isOpen,
  onRequestClose
}) => {
  const navigate = useNavigate()
  const goToDeposit = () => {
    onRequestClose()
    navigate('/wallet/deposit')
  }

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <Card sx={{ p: 10 }}>
          <Flex variant="layout.vStack">
            <Box>
              <Text>You have insufficient balance to place a bid</Text>
            </Box>
            <Box>
              <Text variant="mediumSmall" sx={{ color: 'textMuted' }}>
                Please submit a request to proceed for deposit now
              </Text>
            </Box>
            <Box sx={{ p: 4 }} />
            <Box>
              <Flex variant="layout.hStack" sx={{ justifyContent: 'center' }}>
                <Button onClick={onRequestClose} sx={{ background: 'input' }}>
                  Cancel
                </Button>
                <Button onClick={goToDeposit}>Deposit Now</Button>
              </Flex>
            </Box>
          </Flex>
        </Card>
      </Box>
    </CustomModal>
  )
}

export default NFTDetail
