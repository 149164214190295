/** @jsxImportSource theme-ui */
import { Box, Button, Flex, Grid, Text } from 'theme-ui'
import TitleWithBack from 'components/TitleWithBack'
import { BsArrowRight } from 'react-icons/bs'
import { useResolution } from 'hooks/useResolution'
import MobileReturnHeader from 'components/MobileReturnHeader'
import PieChart from 'components/pie-chart'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import PoolService, { Pool } from 'network/services/pool'
import LoadingCard from 'components/loading'
import ErrorCard from 'components/error'

const HowProfitDistributionWorksPage = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="How Profit Distribution Works?" delta={-1} />}
      <TitleWithBack delta={-1} title="How Profit Distribution Works?" />
      <Box variant="layout.flexCenterCenter" sx={{ flexDirection: 'column' }}>
        <Box variant="layout.flexCenterCenter" sx={{ flexDirection: 'column' }}>
          <Chart />
          <Box p={3} />
          <Button
            onClick={() =>
              window.open(
                'https://medium.com/@voxto_amplify/voxto-tokenomics-c22ce8d8a28',
                '_blank'
              )
            }
          >
            <Box variant="layout.hStack">
              <Box>
                <Text>Read Tokenomics</Text>
              </Box>
              <BsArrowRight />
            </Box>
          </Button>
        </Box>
        <Box p={5} />
        <Grid columns={[1, 3]} gap={7}>
          <Box>
            <Text sx={{ color: 'primary' }}>Burn</Text>
            <Box>
              <Text variant="mediumSmall">
                Percentage of monthly profits from each pool will be allocated to buying back $VXT
                tokens from DEXs or CEXs (circulating supply) and sent to a null address. This aims
                to ensure value retention of the $VXT token.
              </Text>
            </Box>
          </Box>
          <Box>
            <Text sx={{ color: 'primary' }}>Stakers</Text>
            <Box>
              <Text variant="mediumSmall">
                Allocation for returns to stakers based on monthly profits.
              </Text>
            </Box>
          </Box>
          <Box>
            <Text sx={{ color: 'primary' }}>NFT Holders </Text>
            <Box>
              <Text variant="mediumSmall">
                Additional returns for Voxto NFT stakers who stake their NFTs in pools of their
                choice. Returns percentage is distribute proportionately to the staked amount.
              </Text>
            </Box>
          </Box>
          <Box>
            <Text sx={{ color: 'primary' }}>Reserves </Text>
            <Box>
              <Text variant="mediumSmall">
                These funds go into reserves for emergency situations or new product programs.
              </Text>
            </Box>
          </Box>
          <Box>
            <Text sx={{ color: 'primary' }}>Team </Text>
            <Box>
              <Text variant="mediumSmall">
                Funds for sustenance of the Voxto team and operations costs.
              </Text>
            </Box>
          </Box>
          <Box>
            <Text sx={{ color: 'primary' }}>$VXT Pool </Text>
            <Box>
              <Text variant="mediumSmall">Percentage returns allocated to $VXT Pool.</Text>
            </Box>
          </Box>
          <Box>
            <Text sx={{ color: 'primary' }}>Pool Incentives </Text>
            <Box>
              <Text variant="mediumSmall">
                Pool incentives only apply for stakers opting for returns in the form of $VXT.
              </Text>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const Chart = () => {
  const { id } = useParams()
  const { data, error } = useSWR<{ data: Pool }>(id != null ? PoolService.get(id) : null)

  // TODO: use rate from data instead of hard code
  const amplifyData12 = [
    {
      id: 'reserve',
      label: 'Reserve',
      value: 7,
      color: '#1645B2'
    },
    {
      id: 'poolInsentives',
      label: 'Pool Incentives',
      value: 4,
      color: '#0B1733'
    },
    {
      id: 'stakers',
      label: 'Stakers',
      value: 35,
      color: '#0F2F79'
    },
    {
      id: 'nftHolder',
      label: 'NFT Holder',
      value: 2,
      color: '#123076'
    },
    {
      id: 'vxtPool',
      label: 'VXT Pool',
      value: 10,
      color: '#215BF0'
    },
    {
      id: 'team',
      label: 'Team',
      value: 35,
      color: '#2667FF'
    },
    {
      id: 'burn',
      label: 'Burn',
      value: 7,
      color: '#1A336C'
    }
  ]
  const amplifyData24 = [
    {
      id: 'reserve',
      label: 'Reserve',
      value: 1,
      color: '#1645B2'
    },
    {
      id: 'stakers',
      label: 'Stakers',
      value: 50,
      color: '#0F2F79'
    },
    {
      id: 'nftHolder',
      label: 'NFT Holder',
      value: 6,
      color: '#123076'
    },
    {
      id: 'poolInsentives',
      label: 'Pool Incentives',
      value: 4,
      color: '#0B1733'
    },
    {
      id: 'vxtPool',
      label: 'VXT Pool',
      value: 2,
      color: '#215BF0'
    },
    {
      id: 'team',
      label: 'Team',
      value: 35,
      color: '#2667FF'
    },
    {
      id: 'burn',
      label: 'Burn',
      value: 2,
      color: '#1A336C'
    }
  ]
  const voxtoData12 = [
    {
      id: 'reserve',
      label: 'Reserve',
      value: 14,
      color: '#1645B2'
    },
    {
      id: 'stakers',
      label: 'Stakers',
      value: 35,
      color: '#0F2F79'
    },
    {
      id: 'nftHolder',
      label: 'NFT Holder',
      value: 3,
      color: '#123076'
    },
    {
      id: 'team',
      label: 'Team',
      value: 35,
      color: '#2667FF'
    },
    {
      id: 'burn',
      label: 'Burn',
      value: 13,
      color: '#1A336C'
    }
  ]
  const voxtoData24 = [
    {
      id: 'reserve',
      label: 'Reserve',
      value: 3,
      color: '#1645B2'
    },
    {
      id: 'stakers',
      label: 'Stakers',
      value: 50,
      color: '#0F2F79'
    },
    {
      id: 'nftHolder',
      label: 'NFT Holder',
      value: 9,
      color: '#123076'
    },
    {
      id: 'team',
      label: 'Team',
      value: 35,
      color: '#2667FF'
    },
    {
      id: 'burn',
      label: 'Burn',
      value: 3,
      color: '#1A336C'
    }
  ]

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  return (
    <Flex sx={{ width: '100%', flexDirection: ['column', 'row'] }}>
      <Box sx={{ height: [300, 400], width: '100%' }}>
        <PieChart data={data.data.type === 'vxt' ? voxtoData12 : amplifyData12} title="12" />
      </Box>
      <Box sx={{ height: [300, 400], width: '100%' }}>
        <PieChart data={data.data.type === 'vxt' ? voxtoData24 : amplifyData24} title="24" />
      </Box>
    </Flex>
  )
}

export default HowProfitDistributionWorksPage
