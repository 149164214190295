import { FC } from 'react'
import { Box, Image, Text, Flex, AspectRatio } from 'theme-ui'

const TopBuyerCard: FC<{
  email: string
  value: number
  image: string
  index: number
}> = ({ email, image, value, index }) => {
  return (
    <Flex variant="layout.flexCenterStart" sx={{ height: 60 }}>
      <Box sx={{ mr: 5 }}>
        <Text>{index}</Text>
      </Box>
      <Flex variant="layout.flexCenterStart">
        <Box sx={{ width: 60 }}>
          <AspectRatio ratio={1 / 1}>
            <Image
              src={image}
              sx={{
                borderRadius: 100,
                objectFit: 'cover'
              }}
            />
          </AspectRatio>
        </Box>

        <Box sx={{ py: 3, ml: 5, width: '100%' }}>
          <Box>
            <Text sx={{ fontWeight: 'bold' }}>{email}</Text>
          </Box>
          <Text variant="small" color="textMuted">
            {value} VXT
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default TopBuyerCard
