import { FC } from 'react'
import { Box, Card, Divider, Flex, Text, Grid, Button, Image, Badge } from 'theme-ui'
import humanize from 'human-date'
import { DateTime } from 'luxon'

import { Stake } from 'network/services/stake'
import { useResolution } from 'hooks/useResolution'
import HorizontalScroller from 'layout/horizontal-scroller'
import TokenIcon from 'components/icons/token'
import Link from 'components/link'

const StakeCard: FC<{
  stake: Stake
}> = ({ stake }) => {
  const { isMobile } = useResolution()

  return (
    <HorizontalScroller>
      <Card p={0} sx={{ width: [700, '100%'] }}>
        <Flex sx={{ px: 7, pt: 5 }} variant="layout.flexCenterSpaceBetween">
          <Flex variant="layout.flexCenterStart" sx={{ width: 'auto' }}>
            <Box sx={{ width: 30, display: 'inline-flex', svg: { width: '100%', height: '100%' } }}>
              <TokenIcon type={stake.token_type} />
            </Box>
            <Text ml={5}>{stake.token_type}</Text>
            <Text ml={3} color="textMuted" variant="mediumSmall">
              {stake.token_name}
            </Text>

            {stake.type === 'amplify' && (
              <Badge
                sx={{
                  background: 'transparent linear-gradient(100deg, #1142A4 0%, #0BA2E4 100%) 0% 0% '
                }}
                mx={9}
              >
                Amplify Pool
              </Badge>
            )}

            {stake.type === 'launch' && (
              <Badge
                sx={{
                  background: 'transparent linear-gradient(101deg, #6E0B4A 0%, #F70F81 100%) 0% 0% '
                }}
                mx={9}
              >
                Launch Pool
              </Badge>
            )}

            {stake.type === 'vxt' && (
              <Badge
                sx={{
                  background: 'transparent linear-gradient(99deg, #02623C 0%, #0ECB81 100%) 0% 0% '
                }}
                mx={9}
              >
                VXT Pool
              </Badge>
            )}

            {stake.earn_vxt ? (
              <Box>
                <Flex sx={{ verticalAlign: 'middle' }} variant="layout.flexCenterCenter">
                  <Box variant="layout.iconContainer">
                    <Image src="/assets/svg/VXT-icon.svg" />
                  </Box>
                  <Text sx={{ ml: 4 }}>Earning in VXT</Text>
                </Flex>
              </Box>
            ) : (
              <></>
            )}

            {stake.link_nft ? (
              <Box>
                <Flex sx={{ verticalAlign: 'middle' }} variant="layout.flexCenterCenter">
                  <Box variant="layout.iconContainer">
                    <Image src="/assets/svg/blockchain.svg" />
                  </Box>
                  <Text sx={{ ml: 4 }}>NFT Linked</Text>
                </Flex>
              </Box>
            ) : (
              <></>
            )}
          </Flex>
          {!isMobile && (
            <Text>
              {stake.matured_at &&
                humanize.relativeTime(DateTime.fromISO(stake.matured_at).toJSDate())}
            </Text>
          )}
        </Flex>
        <Divider />
        <Grid sx={{ px: 7, pb: 5 }} columns={[4, 5]}>
          <Flex sx={{ flexDirection: 'column', borderRight: '2px solid #2667FF' }}>
            <Text>Amount ({stake.token_type})</Text>
            <Text>{stake.principal_amount}</Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column', borderRight: '2px solid #2667FF' }}>
            <Text>Base Profit Share</Text>
            <Text color="success">{`${stake.stake_rate}%`}</Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column', borderRight: '2px solid #2667FF' }}>
            <Text>Earned in {stake.earn_vxt ? 'VXT' : 'Principal'}</Text>
            <Text>
              {stake.total_interests ?? 0} {stake.earn_vxt ? 'VXT' : stake.token_type}
            </Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text>Term</Text>
            <Text>{stake.tenure} months</Text>
          </Flex>
          {!isMobile && (
            <Box sx={{ justifySelf: 'flex-end' }}>
              <Link to={`${stake.id}`}>
                <Button>View Details</Button>
              </Link>
            </Box>
          )}
        </Grid>
        {isMobile && (
          <Flex sx={{ px: 7, pb: 5 }}>
            <Text sx={{ pr: 5 }}>
              {stake.matured_at &&
                humanize.relativeTime(DateTime.fromISO(stake.matured_at).toJSDate())}
            </Text>
            <Link to={`${stake.id}`}>
              <Button>View Details</Button>
            </Link>
          </Flex>
        )}
      </Card>
    </HorizontalScroller>
  )
}

export default StakeCard
