import { Box, Card, Heading, Link, Text } from 'theme-ui'
import TitleWithBack from 'components/TitleWithBack'
import MobileReturnHeader from 'components/MobileReturnHeader'
import { useResolution } from 'hooks/useResolution'

const AboutVoxtoPage = () => {
  const { isMobile } = useResolution()
  const link =
    'https://medium.com/@voxto_amplify/introducing-voxto-the-easy-way-to-generate-crypto-returns-a452bf3d8075'

  return (
    <Box variant="layout.pageContainer">
      {/* {isMobile && <MobileReturnHeader title="About Voxto" delta={-1} />}
      <TitleWithBack delta={-1} title="" /> */}
      <Card>
        <Heading>About Voxto : </Heading>
        <Box p={3} />
        <Text>
          Voxto is a Centralised Finance Platform that is built on the Ethereum chain. The native
          token of Voxto is $VXT which is both the governance and utility token of the project. $VXT
          will be used for gas for transactions made on the Voxto Platform.
        </Text>
        <Box p={3} />
        <Text>
          Voxto has developed a better and smarter way for everyone to stake and earn on crypto by
          profit share. Voxto was founded in 2021 by Jeffrey Ong & Team, who have a long standing
          track record of generating impressive trading returns for their clients via proprietary
          crypto trading strategies and algorithms.
        </Text>
        <Box p={3} />
        <Text>Read more about Voxto here : </Text>
        <Link onClick={() => window.open(link, '_blank')}>
          <Text sx={{ wordWrap: 'break-word' }}>{link}</Text>
        </Link>
      </Card>
    </Box>
  )
}

export default AboutVoxtoPage
