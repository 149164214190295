import { FC, ReactNode, useEffect } from 'react'
import { useResponsiveValue } from '@theme-ui/match-media'
import ReactModal from 'react-modal'

const CustomModal: FC<{
  isOpen: boolean
  onRequestClose?: () => void
  overlayStyle?: React.CSSProperties | undefined
  contentStyle?: React.CSSProperties | undefined
  children: ReactNode
}> = ({ isOpen, onRequestClose, overlayStyle, contentStyle, children }) => {
  const modalWidth = useResponsiveValue(['auto', '30%'])

  useEffect(() => {
    ReactModal.setAppElement('body')
  })

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 5,
          ...overlayStyle
        },
        content: {
          backgroundColor: 'transparent',
          border: 'none',
          width: modalWidth,
          margin: 'auto',
          padding: 0,
          ...contentStyle
        }
      }}
    >
      <>{children}</>
    </ReactModal>
  )
}

export default CustomModal
