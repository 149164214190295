/** @jsxImportSource theme-ui */
import { FC, useState } from 'react'
import { Box, Card, Flex, Grid, Heading, Image, Text } from 'theme-ui'
import useSWR from 'swr'
import _ from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'

import { useResolution } from 'hooks/useResolution'
import { getProfile } from 'store/auth'
import MobileReturnHeader from 'components/MobileReturnHeader'
import AuthService, { User } from 'network/services/auth'
import VerificationService, { Verification } from 'network/services/verification'
import { fadeAnim } from 'lib/animation'
import IdentityVerification from './identity-verification'
import PersonalInfoVerification from './personal-info-verification'

let AnimatedBox = motion(Box)

const IdentityVerificationPage = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Identity Verification" toSidebar />}
      <Box>
        <Box>
          <Heading>Identity Verification</Heading>
        </Box>

        <Box p={1} />

        <Box>
          <Text>Complete Identity Verification to unlock all features</Text>
        </Box>

        <Box p={3} />

        <FormView />
      </Box>
    </Box>
  )
}

const FormView = () => {
  const { data } = useSWR<{ data: Verification }>(VerificationService.getPending)

  if (!data) {
    return <></>
  }

  const verificationData = data.data

  return <PageView verificationData={verificationData} />
}

const PageView: FC<{ verificationData: Verification | undefined }> = ({ verificationData }) => {
  const [pageSelected, setPageSelected] = useState('personal_info')
  // get up to date profile
  const { data } = useSWR<{ data: User }>(AuthService.findMyself)
  const profile = data?.data

  return (
    <>
      <Grid
        sx={{
          width: '100%',
          height: ['100%', 300],
          '>*': {
            height: [250, 'auto']
          }
        }}
        columns={[1, 3]}
        gap={5}
      >
        <VerifyEmailCard verified={true} />

        <PersonalInfoCard
          type={pageSelected}
          onClick={() => {
            setPageSelected('personal_info')
          }}
          status={profile?.verified ? 'verified' : verificationData?.status ?? 'pending'}
        />

        <IdentityVerificationCard
          type={pageSelected}
          onClick={() => {
            setPageSelected('identity_verification')
          }}
          status={profile?.verified ? 'verified' : verificationData?.status ?? 'pending'}
        />
      </Grid>

      <Box p={[5, 8]} />

      {!profile?.verified && (
        <AnimatePresence exitBeforeEnter>
          {{
            identity_verification: (
              <AnimatedBox
                key="identity_verification"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <IdentityVerification
                  initialData={verificationData}
                  setPageSelected={setPageSelected}
                />
              </AnimatedBox>
            )
          }[pageSelected] || (
            <AnimatedBox
              key="personal_info"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnim}
            >
              <PersonalInfoVerification
                initialData={verificationData}
                setPageSelected={setPageSelected}
              />
            </AnimatedBox>
          )}
        </AnimatePresence>
      )}
    </>
  )
}

const VerifyEmailCard: FC<{ verified: boolean }> = ({ verified }) => {
  return (
    <Card
      variant="flexCenterSecondaryCard"
      sx={{
        borderRadius: 20
      }}
    >
      <Flex
        sx={{
          py: [0, 5],
          width: ['90%', '100%', '80%'],
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Grid columns={1} sx={{ height: [150, 200] }}>
          <Box>
            <Image src="/assets/svg/mail.svg" width={50} height={50} />
          </Box>
          <Text variant="large" color="primary">
            Verify Email
          </Text>
          <Box>
            <Text color="secondaryText">Email account verified at registration</Text>
          </Box>
        </Grid>
        <Flex variant="layout.flexCenterCenter" sx={{ pt: 5 }}>
          <Image src={`/assets/svg/${verified ? 'check' : 'uncheck'}.svg`} width={20} height={20} />
          <Text variant="large" sx={{ ml: 5 }} color={verified ? 'default' : 'primary'}>
            {verified ? 'Verified' : 'Unverified'}
          </Text>
        </Flex>
      </Flex>
    </Card>
  )
}

const PersonalInfoCard: FC<{
  type: string
  onClick: () => any
  status: string
}> = ({ type, onClick, status }) => {
  return (
    <Card
      variant={
        type === 'personal_info' ? 'flexCenterSecondaryCardOutlined' : 'flexCenterSecondaryCard'
      }
      sx={{
        borderRadius: 20
      }}
      onClick={onClick}
    >
      <Flex
        sx={{
          py: [0, 5],
          width: ['90%', '100%', '80%'],
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Grid columns={1} sx={{ height: [150, 200] }}>
          <Box>
            <Image src="/assets/svg/google-forms.svg" width={35} height={35} />
          </Box>
          <Text variant="large" color="primary">
            Personal Info
          </Text>
          <Box>
            <Text color="secondaryText">
              Submit your personal information and add your mobile phone number
            </Text>
          </Box>
        </Grid>
        <Flex variant="layout.flexCenterCenter" sx={{ pt: 5 }}>
          <Image
            src={`/assets/svg/${status === 'verified' ? 'check' : 'uncheck'}.svg`}
            width={20}
            height={20}
          />
          <Text
            sx={{ ml: 5 }}
            variant="large"
            color={status === 'verified' ? 'default' : 'primary'}
          >
            {_.startCase(status)}
          </Text>
        </Flex>
      </Flex>
    </Card>
  )
}

const IdentityVerificationCard: FC<{
  type: string
  onClick: () => any
  status: string
}> = ({ type, onClick, status }) => {
  return (
    <Card
      variant={
        type === 'identity_verification'
          ? 'flexCenterSecondaryCardOutlined'
          : 'flexCenterSecondaryCard'
      }
      sx={{
        borderRadius: 20
      }}
      onClick={onClick}
    >
      <Flex
        sx={{
          py: [0, 5],
          width: ['90%', '100%', '80%'],
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Grid columns={1} sx={{ height: [150, 200] }}>
          <Box>
            <Image src="/assets/svg/name.svg" width={50} height={50} />
          </Box>
          <Text variant="large" color="primary">
            Identity Verification
          </Text>
          <Box>
            <Text color="secondaryText">Complete Identity Verification to unlock all features</Text>
          </Box>
        </Grid>
        <Flex variant="layout.flexCenterCenter" sx={{ pt: 5 }}>
          <Image
            src={`/assets/svg/${status === 'verified' ? 'check' : 'uncheck'}.svg`}
            width={20}
            height={20}
          />
          <Text
            sx={{ ml: 5 }}
            variant="large"
            color={status === 'verified' ? 'default' : 'primary'}
          >
            {_.startCase(status)}
          </Text>
        </Flex>
      </Flex>
    </Card>
  )
}

export default IdentityVerificationPage
