import toUTCTime from 'modules/to-utc-time'
import { IDataResponse } from '../request'

export type Transaction = {
  id: number | string
  title: string
  description: string
  txn_type: string
  token_type: string
  status: string
  confirmed: boolean
  amount: number
  amount_in: number
  amount_out: number
  amount_str: string
  wallet_id: number
  user_id: number
  txn_hash: string
  created_at: string
  updated_at: string
}

const getTransaction = '/me/transactions'

// processing
const toRow = (data: IDataResponse<Transaction>): Transaction[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        created_at: toUTCTime(element.created_at),
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data?: IDataResponse<Transaction>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const TransactionService = {
  getTransaction,
  toRow,
  toPaginate
}

export default TransactionService
