import { FC } from 'react'
import { Box, Grid, Text, Flex, Image, Button } from 'theme-ui'

import { useResolution } from 'hooks/useResolution'
import { UploadCard } from 'components/IdentityVerification'
import { useFormContext } from 'react-hook-form'
import { Verification } from 'network/services/verification'

const ProofOfAddress: FC<{
  onBack?: () => any | void
}> = ({ onBack = () => null }) => {
  const { isMobile } = useResolution()
  const {
    setValue,
    register,
    clearErrors,
    formState: { errors }
  } = useFormContext<Verification>()

  return (
    <Box>
      <Grid columns={[1, 2]} gap={10}>
        <UploadCard<Verification>
          image={<Image src="/assets/svg/Layer_50.svg" width={60} height={60} />}
          description="  Upload your document here"
          rules={{
            required: 'Please upload your selfie'
          }}
          setValue={setValue}
          register={register}
          name="proof_document"
          errors={errors}
        />

        <Flex variant="layout.flexCenterSpaceBetween" sx={{ flexDirection: 'column' }}>
          <Box>
            <Box
              variant="layout.coloredContainer"
              sx={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Text>Tips</Text>
            </Box>
            <Box p={2} />
            <Box>
              <Text>• Make sure all details are clear</Text>
            </Box>
            <Box p={2} />
            <Box>
              <Text>
                • Upload any document proving your identity and registered address. Documents can be
                the latest utility bills, insurance, bank statement etc
              </Text>
            </Box>
            <Box p={2} />
            <Box>
              <Text>
                • All information provided must match Voxto’s account registration details
              </Text>
            </Box>
          </Box>
          <Box p={5} />
          <Flex variant={isMobile ? 'layout.flexCenterCenter' : 'layout.flexCenterEnd'}>
            <Button
              type="button"
              sx={{ mr: 5, width: ['100%', 'auto'] }}
              variant="secondary"
              onClick={onBack}
            >
              Back
            </Button>

            <Button
              sx={{ width: ['100%', 'auto'] }}
              type="submit"
              form="verification-form"
              onClick={async () => {
                clearErrors()
                // onSubmit()
              }}
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  )
}

export default ProofOfAddress
