/** @jsxImportSource theme-ui */
import { Box, Flex, Heading, Text } from 'theme-ui'
import useSWR from 'swr'

import { useResolution } from 'hooks/useResolution'
import TitleWithBack from 'components/TitleWithBack'
import Table from 'components/Table'
import MobileReturnHeader from 'components/MobileReturnHeader'

import LoginActivityService, { LoginActivity } from 'network/services/login_activities'
import { IDataResponse, serialize } from 'network/request'
import { useState } from 'react'
import LoadingCard from 'components/loading'
import ErrorCard from 'components/error'

const LastLoginPage = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      <Box>
        {isMobile && (
          <>
            <MobileReturnHeader title="Security" delta={-1} />
            <Heading variant="styles.h5" color="secondaryText">
              Last Login
            </Heading>
            <Box p={2} />
            <Box>
              <Text variant="large">Monitor the logins to your Voxto account.</Text>
            </Box>
          </>
        )}
        <TitleWithBack
          title="Last Login"
          backRef="/security"
          subtitle="Monitor the logins to your Voxto account."
        />
      </Box>

      <Box p={2} />

      <TableView />
    </Box>
  )
}

const TableView = () => {
  const { isMobile } = useResolution()

  const [page, setPage] = useState(1)
  const { data, error, mutate } = useSWR<IDataResponse<LoginActivity>>(
    serialize(LoginActivityService.getAll, {
      page: page,
      limit: 10,
      sort: 'created_at:desc'
    })
  )

  const total = LoginActivityService.toPaginate(data).total

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve login history" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const loginData = LoginActivityService.toRow(data).map((item) => {
    return {
      created_at: item.created_at,
      location: item.location,
      ip_address: item.ip_address,
      device: item.device
    }
  })

  return (
    <Table
      headers={['Date & Time', 'Location', 'IP Address', 'Device']}
      data={loginData}
      width={isMobile ? 700 : '100%'}
      onPageChange={setPage}
      total={total}
      limit={10}
      page={page}
    />
  )
}

export default LastLoginPage
