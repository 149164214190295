// import AuthService from 'network/services/auth'
import { logout } from 'store/auth'
import { Button, Box, Text, Flex, Grid, Image, Card } from 'theme-ui'
import { getProfile } from 'store/auth'
import CustomModal from 'components/modal'
import { useState } from 'react'

const UserInfo = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const profile = getProfile()

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const confirmLogout = () => {
    try {
      // await AuthService.logout()
      logout()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Flex variant="layout.flexCenterCenter" sx={{ flexDirection: 'column' }}>
      <Image src={'/assets/svg/user-logo.svg'} />
      <Box pt={6} />
      <Box>
        <Text>{profile?.email}</Text>
      </Box>
      <Box pt={6} />
      <Grid
        columns={1}
        gap={4}
        sx={{ width: '100%', justifyItems: 'center', alignItems: 'center' }}
      >
        {/* <Button>English</Button> */}
        <Button variant="sidebarButton" onClick={openModal}>
          Logout
        </Button>
      </Grid>

      <CustomModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        overlayStyle={{
          justifyContent: 'center',
          textAlign: 'center'
        }}
        contentStyle={{
          height: '36%'
        }}
      >
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Card sx={{ p: 10 }}>
            <Box>
              <Text>Are you sure?</Text>
            </Box>
            <Box p={5} />
            <Flex variant="layout.hStack" sx={{ justifyContent: 'center' }}>
              <Button onClick={closeModal} sx={{ background: 'input' }}>
                Cancel
              </Button>
              <Box>
                <Button onClick={confirmLogout}>Confirm</Button>
              </Box>
            </Flex>
          </Card>
        </Box>
      </CustomModal>
    </Flex>
  )
}

export default UserInfo
