import client, { IDataResponse } from '../request'
import { NFT } from './nft'

export type NFTTransfer = {
  id: number | string
  status: string
  txn_hash: number
  nft_id: number | string
  user_id: number | string
  to_user_id: number | string
  created_at: string
  updated_at: string
  nft: NFT
}

export type CreateNFTTransfer = {
  email: string
  nft_id: number | string
  two_fa_code: string
}

const getAll = '/me/nft-transfers'

const getOne = (id: string | number) => {
  return `/me/nft-transfers/${id}`
}

const toRow = (data: IDataResponse<NFTTransfer>): NFTTransfer[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }
  return []
}

const toPaginate = (data: IDataResponse<NFTTransfer>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const create = (data: CreateNFTTransfer) => {
  return client.post('/me/nft-transfers', data)
}

const NFTTransferService = {
  getAll,
  getOne,
  create,
  toRow,
  toPaginate
}

export default NFTTransferService
