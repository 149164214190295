import { FC, useEffect, useState } from 'react'
import { Box, Button, Card, Flex, Grid, Heading, Image, Select, Text } from 'theme-ui'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import { TiArrowUnsorted } from 'react-icons/ti'
import { useResolution } from 'hooks/useResolution'
import CustomModal from 'components/modal'
import { IDataResponse, serialize } from 'network/request'
import NFTService, { NFT } from 'network/services/nft'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import UserSecurityService from 'network/services/user_security'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import FormInput from 'components/form-input'
import { UserSecurity } from 'network/services/user_security'
import NFTWithdrawService, { CreateNFTWithdraw } from 'network/services/nft_withdraw'
import StatusModal from 'components/status-modal'
import _ from 'lodash'
import { useNFTCheck } from 'hooks/useNFTCheck'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'

let AnimatedBox = motion(Box)

const NFTWithdrawPage = () => {
  return <PageView />
}

const PageView = () => {
  const { isMobile } = useResolution()
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })

  const method = useForm<CreateNFTWithdraw>({
    shouldUseNativeValidation: false,
    defaultValues: {
      token_type: 'VXT'
    }
  })

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const submitWithdraw = () => {
    onSubmit()
  }

  const onSubmit = method.handleSubmit(async (data) => {
    method.clearErrors()
    console.log(data)
    try {
      const { data: result } = await NFTWithdrawService.create(data)
      if (result.success) {
        setMessage({
          isOpen: true,
          success: true,
          message: 'Withdraw NFT Successfully'
        })
      }
    } catch (e: any) {
      setMessage({
        isOpen: true,
        success: false,
        message: e.message
      })
    }
  })

  return (
    <Box>
      <Box p={3} />
      <FormProvider {...method}>
        <Box as="form" onSubmit={onSubmit}>
          <Grid columns={[1, 2]} gap={4}>
            <Box>
              <Text>NFT to withdraw</Text>
              <Box p={2} />
              <NFTWithdrawView setDisableSubmit={setDisableSubmit} />
            </Box>
            <Box>
              <Box>
                <Text>Network</Text>
                <Box p={2} />
                <NetworkSelect />
                <Box p={2} />
                <Grid columns={isMobile ? 1 : 2}>
                  <Text>Destination Wallet Address</Text>
                </Grid>
                <Box p={2} />
                <NFTWalletAddress disable={disableSubmit} />
                <Box p={4} />
                <PleaseNote disable={disableSubmit} />
              </Box>
              <Box>
                <Box p={3} />
                <Grid>
                  <Text>Withdrawal Fees</Text>
                  <Text variant="extraLarge" sx={{ color: 'textMuted' }}>
                    0.002 VTX
                  </Text>
                </Grid>
                <Box p={2} />
                <Grid columns={2}>
                  <Button
                    sx={{ background: 'input' }}
                    onClick={() => navigate('/nfts')}
                    type="button"
                  >
                    Back
                  </Button>
                  <AnimatePresence exitBeforeEnter>
                    {!disableSubmit && (
                      <AnimatedBox
                        key="withdraw"
                        initial="hide"
                        animate="show"
                        exit="hide"
                        variants={fadeAnim}
                      >
                        <Button type="button" onClick={openModal}>
                          Withdraw
                        </Button>
                      </AnimatedBox>
                    )}
                  </AnimatePresence>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <VerificationView
            isOpen={modalOpen}
            onRequestClose={() => closeModal()}
            submitForm={submitWithdraw}
          />
        </Box>
      </FormProvider>
      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        success={message.success}
        onRequestClose={() => {
          setMessage({ isOpen: false })
          if (message?.success) {
            navigate('/nfts')
          }
        }}
      />
    </Box>
  )
}

const NFTWithdrawView: FC<{ setDisableSubmit: (val: boolean) => void }> = ({
  setDisableSubmit
}) => {
  const [selectNFTId, setSelectNFTId] = useState<number | string>()
  const { checkState } = useNFTCheck(selectNFTId)
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, {
      status: 'active'
    })
  )
  const [selectIndex, setSelectIndex] = useState<number>(-1)
  const [selectDisplay, setSelectDisplay] = useState<boolean>(false)
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext<CreateNFTWithdraw>()

  useEffect(() => {
    console.log(checkState)
    if (!checkState) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [checkState])

  const closeDisplay = (index: number) => {
    setSelectNFTId(myNFTs[index].id)
    setValue('nft_id', myNFTs[index].id)
    setSelectIndex(index)
    setSelectDisplay(false)
  }

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve NFT details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const myNFTs = NFTService.toRow(data)

  return (
    <Box>
      <Card>
        <Flex sx={{ flexDirection: ['row', 'column', 'row'] }}>
          {selectIndex >= 0 ? (
            <Image src={myNFTs[selectIndex].image_url} sx={{ width: [100, 200] }} />
          ) : (
            <Box sx={{ width: [100, 200], height: [100, 200] }}></Box>
          )}
          <Box sx={{ ml: 5, flex: 1 }}>
            <Text>Select NFT to withdraw</Text>
            <Box p={1} />
            <Button
              sx={{ minWidth: '100%' }}
              onClick={() => setSelectDisplay(!selectDisplay)}
              type="button"
            >
              <Flex variant="layout.flexCenterSpaceBetween">
                {selectIndex >= 0 ? (
                  <Text>{myNFTs[selectIndex].name}</Text>
                ) : (
                  <Text>Please Select NFT</Text>
                )}

                <TiArrowUnsorted />
              </Flex>
            </Button>
            <Card
              sx={{
                background: 'darkBG',
                position: 'absolute',
                width: [200, 250],
                display: selectDisplay ? 'block' : 'none'
              }}
            >
              <Text>Select NFT</Text>
              <Grid>
                {myNFTs.map((item, index) => (
                  <Flex variant="layout.hStack" onClick={() => closeDisplay(index)}>
                    <Image src={item.image_url} width={100} />
                    <Grid sx={{ ml: 2 }}>
                      <Text>{item.name}</Text>
                      <Text variant="small" sx={{ color: 'textMuted' }}>
                        Type : {_.startCase(item.type)}
                      </Text>
                    </Grid>
                  </Flex>
                ))}
              </Grid>
            </Card>
          </Box>
        </Flex>
        <FormInput<CreateNFTWithdraw>
          name="nft_id"
          register={register}
          rules={{
            required: 'Please select NFT'
          }}
          errors={errors}
          containerSX={{ display: 'none' }}
        />
      </Card>
    </Box>
  )
}

const NetworkSelect = () => {
  const { register } = useFormContext<CreateNFTWithdraw>()

  return (
    <Select variant="secondarySelect" sx={{ textAlign: 'left' }} {...register('network_type')}>
      <option>ETHEREUM</option>
    </Select>
  )
}

const NFTWalletAddress: FC<{ disable: boolean }> = ({ disable }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateNFTWithdraw>()

  return (
    <Box>
      <FormInput<CreateNFTWithdraw>
        name="recipient_addr"
        placeholder="NFT wallet address"
        register={register}
        rules={{
          required: 'Please enter recipient address'
        }}
        errors={errors}
        readOnly={disable}
      />
    </Box>
  )
}

const VerificationView: FC<{
  onRequestClose: () => any
  isOpen: boolean
  submitForm: () => any
}> = ({ isOpen, onRequestClose, submitForm }) => {
  const { data, error, mutate } = useSWR<{ data: UserSecurity }>(UserSecurityService.get)
  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <CustomModal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          overlayStyle={{
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Box>
            <ErrorCard message="Unable to retrieve security information" refresh={() => mutate()} />
          </Box>
        </CustomModal>
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <CustomModal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          overlayStyle={{
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <LoadingCard />
        </CustomModal>
      </Flex>
    )
  }

  const userSecurity = data.data
  return (
    <Box>
      {userSecurity?.two_fa_enabled ? (
        <WithdrawConfirmation
          modalOpen={isOpen}
          closeModal={onRequestClose}
          submitWithdraw={submitForm}
        />
      ) : (
        <Enable2FAModal isOpen={isOpen} onRequestClose={onRequestClose} />
      )}
    </Box>
  )
}

const WithdrawConfirmation: FC<{
  modalOpen: boolean
  closeModal: () => void
  submitWithdraw: () => void
}> = ({ modalOpen, closeModal, submitWithdraw }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateNFTWithdraw>()

  const submitForm = () => {
    submitWithdraw()
    closeModal()
  }

  return (
    <CustomModal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <Box
        variant="layout.flexCenterCenter"
        sx={{
          minHeight: ['calc(100vh - 51px - 144px)', 'inherit'],
          py: [5, 16],
          position: 'relative'
        }}
      >
        <Card px={10} py={12}>
          <Box
            variant="layout.flexStartCenter"
            sx={{ minHeight: 'inherit', flexDirection: 'column' }}
          >
            <Heading sx={{ textAlign: 'left' }}>Security Verification</Heading>
            <Box pt={5} />
            <Text>To secure your account, please complete the following verification.</Text>
            <Box pt={5} />
            <Box>
              <Text>Google </Text>
              <Text>Authentication Code</Text>
            </Box>
            <Box pt={2} />
            <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
              <FormInput<CreateNFTWithdraw>
                sx={{ my: 3, background: 'sidebarButtonBG' }}
                name="two_fa_code"
                register={register}
                rules={{ required: 'You must enter your authentication code.' }}
                errors={errors}
              />
            </Flex>
            <Box pt={5} />
            <Grid columns={2} gap={[1, 3]} sx={{ width: '100%' }}>
              <Button
                type="button"
                onClick={closeModal}
                sx={{ width: '100%', background: 'muted' }}
              >
                Cancel
              </Button>
              <Button sx={{ width: '100%' }} onClick={submitForm}>
                Submit
              </Button>
            </Grid>
          </Box>
        </Card>
      </Box>
    </CustomModal>
  )
}

const Enable2FAModal: FC<{ onRequestClose: () => any; isOpen: boolean }> = ({
  isOpen,
  onRequestClose
}) => {
  const navigate = useNavigate()

  const goTo2Fa = () => {
    onRequestClose()
    navigate('/security/2-factor-authentication')
  }

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
      contentStyle={{
        height: '36%'
      }}
    >
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <Card sx={{ p: 10 }}>
          <Box>
            <Text>Please Enable Google Authenticator before withdrawal</Text>
          </Box>
          <Box p={5} />
          <Flex variant="layout.hStack" sx={{ justifyContent: 'center' }}>
            <Button onClick={onRequestClose} sx={{ background: 'input' }} type="button">
              Cancel
            </Button>
            <Button onClick={goTo2Fa} type="button">
              Confirm
            </Button>
          </Flex>
        </Card>
      </Box>
    </CustomModal>
  )
}

const PleaseNote: FC<{ disable: boolean }> = ({ disable }) => {
  return (
    <Card sx={{ p: 10 }}>
      <Text>Please note:</Text>
      <Box p={1} />
      <AnimatePresence exitBeforeEnter>
        {disable ? (
          <AnimatedBox
            key="pleaseNote"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnim}
          >
            <Text>The NFT Selected is currently in the progress of withdraw / transfer</Text>
          </AnimatedBox>
        ) : (
          <AnimatedBox key="disable" initial="hide" animate="show" exit="hide" variants={fadeAnim}>
            <Text>1. Please enter a valid ERC721 recipient wallet address.</Text>
            <Box p={2} />
            <Text>
              2. Please use “NFT Transfer” if you wish to transfer an NFT asset to other Voxto
              users.
            </Text>
            <Box p={2} />
            <Text>3. All withdrawals are vetted and is processed within 48 hours.</Text>
          </AnimatedBox>
        )}
      </AnimatePresence>
    </Card>
  )
}

export default NFTWithdrawPage
