/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import { Box, Grid, Text, Card, Image, Flex, Button } from 'theme-ui'
import useSWR from 'swr'
import { TiArrowUnsorted } from 'react-icons/ti'
import QRCode from 'qrcode'

import { useResolution } from 'hooks/useResolution'
import SymbolService, { Symbol } from 'network/services/symbol'
import NetworkService, { NetworkState } from 'network/services/network'
import WalletService, { Wallet } from 'network/services/wallet'
import { IDataResponse } from 'network/request'
import TitleWithBack from 'components/TitleWithBack'
import { DepositCard, SelectTokenModal } from 'components/Wallet'
import MobileReturnHeader from 'components/MobileReturnHeader'
import SelectNetworkModal from 'components/Wallet/select-network-modal'
import TokenIcon from 'components/icons/token'
import LoadingCard from 'components/loading'
import _ from 'lodash'
import StatusModal from 'components/status-modal'
import ErrorCard from 'components/error'

const WalletDeposit = () => {
  const { isMobile } = useResolution()

  const [token, setToken] = useState<Symbol | null>(null)
  const [network, setNetwork] = useState<NetworkState | null>(null)
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })

  const { data } = useSWR<IDataResponse<Symbol>>(SymbolService.getAll)

  useEffect(() => {
    setNetwork(null)
  }, [token])

  // show universal loading card at parent
  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const copyMessage = () => {
    setMessage({
      success: true,
      isOpen: true,
      message: 'The address has been copied to your clipboard'
    })
  }

  // from here onwards we can safely assume tokens is not loading
  // but tokens can be error still
  // so lets scope all error within component
  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Deposit Token" delta={-1} />}
      <TitleWithBack backRef="/wallet" title="Deposit Token" />

      <Box p={2} />

      <SelectTokenNetwork
        token={token}
        network={network}
        setToken={setToken}
        setNetwork={setNetwork}
      />

      <Box p={5} />

      <DepositGuide />

      <Box p={5} />

      <QRView token={token} network={network} copyMessage={copyMessage} />

      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        success={message.success}
        onRequestClose={() => setMessage({ isOpen: false })}
      />
    </Box>
  )
}

const SelectTokenNetwork: FC<{
  token?: Symbol | null
  setToken: (token: Symbol) => void
  network?: NetworkState | null
  setNetwork: (network: NetworkState | null) => void
}> = ({ token, setToken, network, setNetwork }) => {
  const [selectToken, setSelectToken] = useState<boolean>(false)
  const [selectNetwork, setSelectNetwork] = useState<boolean>(false)

  const openToken = () => {
    setSelectToken(true)
  }

  const closeToken = () => {
    setSelectToken(false)
  }

  const openNetwork = () => {
    setSelectNetwork(true)
  }

  const closeNetwork = () => {
    setSelectNetwork(false)
  }

  const onChangeToken = (token: Symbol) => {
    setNetwork(null)
    setToken(token)
  }

  const {
    data: tokenData,
    error: tokenError,
    mutate: tokenRefresh
  } = useSWR<IDataResponse<Symbol>>(SymbolService.getAll)
  const {
    data: networkData,
    error: networkError,
    mutate: networkRefresh
  } = useSWR<IDataResponse<NetworkState>>(
    token?.code ? NetworkService.getByToken(token.code) : null
  )

  if (tokenError) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to load tokens" refresh={() => tokenRefresh()} />
      </Flex>
    )
  }

  if (networkError) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to load networks" refresh={() => networkRefresh()} />
      </Flex>
    )
  }

  const tokens: Symbol[] = tokenData != null ? SymbolService.toRow(tokenData) : []
  const networks: NetworkState[] = networkData != null ? NetworkService.toRow(networkData) : []

  return (
    <Box>
      <Grid columns={[1, 2]} gap={[5, 15]}>
        <Box>
          <Text>Select Token</Text>
          <Box p={3} />
          <Flex variant="layout.vStack">
            <Text>Token</Text>
            <Box>
              <Card sx={{ height: 60, py: 0, cursor: 'pointer' }} onClick={openToken}>
                <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: '100%' }}>
                  {token ? (
                    <Flex>
                      <TokenIcon type={token.code} />
                      <Text sx={{ ml: 3 }}>{token.code}</Text>
                      <Text sx={{ ml: 3, color: 'textMuted' }}>{token.name}</Text>
                    </Flex>
                  ) : (
                    <Text>Please Select Token</Text>
                  )}
                  <TiArrowUnsorted />
                </Flex>
              </Card>
            </Box>
          </Flex>
        </Box>

        <Box>
          <Text>Select Network</Text>
          <Box p={3} />
          <Flex variant="layout.vStack">
            <Text>Network</Text>
            <Box>
              {/* will change to pop up message ltr */}
              <Card sx={{ height: 60, py: 0, cursor: 'pointer' }} onClick={openNetwork}>
                <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: '100%' }}>
                  {network ? (
                    <Flex>
                      <Text>{network.name}</Text>
                    </Flex>
                  ) : (
                    <Text>Please Select Network</Text>
                  )}
                  <TiArrowUnsorted />
                </Flex>
              </Card>
            </Box>
          </Flex>
        </Box>
      </Grid>

      <SelectTokenModal
        tokens={tokens}
        tokenSelected={token}
        isOpen={selectToken}
        setToken={onChangeToken}
        onRequestClose={closeToken}
        setIsOpen={setSelectToken}
      />

      <SelectNetworkModal
        networks={networks}
        networkSelected={network}
        isOpen={selectNetwork}
        setNetwork={setNetwork}
        onRequestClose={closeNetwork}
        setIsOpen={setSelectNetwork}
      />
    </Box>
  )
}

const DepositGuide: FC = () => {
  return (
    <Box>
      <Card
        sx={{
          p: 10,
          px: [8, 'auto'],
          borderRadius: 20,
          display: 'flex',
          width: '100%',
          height: '70%'
        }}
      >
        <Grid columns={[1, 2]} gap={5}>
          <DepositCard
            num="1"
            title="Copy Address"
            content="Choose the token and its network on this page, and copy the deposit address."
          />
          <DepositCard
            num="2"
            title="Initiate a withdrawal"
            content="Initiate a withdrawal on the withdrawal platform."
          />
          <DepositCard
            num="3"
            title="Network confirmation"
            content="Wait for the blockchain network to confirm your transfer."
          />
          <DepositCard
            num="4"
            title="Deposit successful"
            content="After the network confirmation, Voxto will credit the token for you."
          />
        </Grid>
      </Card>
    </Box>
  )
}

const QRView: FC<{
  token?: Symbol | null
  network?: NetworkState | null
  copyMessage: () => any
}> = (props) => {
  const { token, network } = props

  const { data, error, mutate } = useSWR<{ data: Wallet }>(
    token?.code != null && network?.name != null
      ? WalletService.getByTokenNetwork(token?.code, network?.name)
      : null
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to load wallet information" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (token?.code != null && network?.name != null && !data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  // at this point wallet should be ready
  const wallet = data?.data

  return <QrAddress wallet={wallet} {...props} />
}

const QrAddress: FC<{
  wallet?: Wallet | null
  network?: NetworkState | null
  copyMessage: () => any
}> = ({ wallet, network, copyMessage }) => {
  const { isMobile } = useResolution()
  const [qr, setQR] = useState<string>('')

  useEffect(() => {
    const generateQR = async () => {
      if (wallet?.wallet_address?.address) {
        const url = await QRCode.toDataURL(wallet.wallet_address.address)
        setQR(url)
      } else {
        setQR('')
      }
    }

    generateQR()
  }, [wallet?.wallet_address?.address])

  const handleCopy = () => {
    navigator.clipboard.writeText(wallet?.wallet_address?.address ?? '')
    copyMessage()
  }

  return (
    <Box>
      <Grid columns={[1, 2]} gap={10}>
        <Card
          sx={{
            p: 10,
            borderRadius: 20,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            textAlign: 'center',
            opacity: _.isEmpty(qr) || !wallet?.wallet_address?.asset?.allow_deposit ? 0 : 1,
            transition: 'opacity 0.2s'
          }}
        >
          <Grid>
            <Box>
              <Text>Scan the code on the withdrawal page of the trading platform</Text>
            </Box>
            <Box>
              <Image src={qr} sx={{ width: 250 }} />
            </Box>
            <Box>
              <Text>
                Send only {wallet?.token_type} to this deposit address. Ensure the network is
              </Text>
              <Text color="danger"> {network?.name}</Text>
              <Text>.</Text>
            </Box>
          </Grid>
        </Card>
        <Box>
          <Card>
            <Flex sx={{ justifyContent: 'space-between' }}>
              {wallet != null && !wallet?.wallet_address?.asset?.allow_deposit ? (
                <Grid>
                  <Text>Deposits are being disabled at this moment</Text>
                </Grid>
              ) : (
                <>
                  <Grid>
                    <Text>Address</Text>
                    <Text>{wallet?.wallet_address?.address ?? '-'}</Text>
                  </Grid>
                  {!isMobile && wallet != null && (
                    <Button
                      variant="primaryFlexCenter"
                      onClick={handleCopy}
                      disabled={wallet == null}
                    >
                      <Box>
                        <Image src="/assets/svg/copy.svg" />
                      </Box>
                      <Box sx={{ ml: 3 }}>
                        <Text>Copy</Text>
                      </Box>
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </Card>

          {wallet != null && wallet?.wallet_address?.asset?.allow_deposit && (
            <>
              <Box p={5} />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Text>Minimum deposit</Text>
                <Text color="textMuted">0.00000001 USDT</Text>
              </Box>
              <Box p={5} />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Text>Remark</Text>
                {wallet?.token_type && (
                  <Text color="textMuted">
                    • Send only {wallet.token_type} to this deposit address.
                  </Text>
                )}

                <Box>
                  <Text color="textMuted">• Ensure the network is </Text>
                  {network?.name ? (
                    <Text color="danger"> {network?.name}</Text>
                  ) : (
                    <Text color="textMuted">selected</Text>
                  )}
                  <Text color="textMuted">.</Text>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export default WalletDeposit
