/** @jsxImportSource theme-ui */
import {
  Button,
  IconButton,
  Box,
  NavLink,
  Image,
  Card,
  Text,
  Flex,
  Divider,
  Heading,
  Grid
} from 'theme-ui'
import { BsPlus } from 'react-icons/bs'
import { MdNotifications, MdPerson } from 'react-icons/md'
import { AiFillGift } from 'react-icons/ai'
import { useNavigate, useLocation } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'
import { TargetAndTransition, motion } from 'framer-motion'
import useSWR, { mutate } from 'swr'

import { useResolution } from 'hooks/useResolution'
import { useNavBarDisplay } from 'context/navBarDisplayContext'
import { useSideBarDisplay } from 'context/sideBarDisplay'
import { NotiPop } from './Notification'
import Link from './link'
import CustomModal from './modal'
import NotificationsService, { Notifications } from 'network/services/notification'
import { IDataResponse, serialize } from 'network/request'

const Header = () => {
  const { isMobile } = useResolution()
  const navigate = useNavigate()
  const [openNoti, setOpenNoti] = useState<boolean>(false)

  const { data, mutate: refresh } = useSWR<IDataResponse<Notifications>>(
    serialize(NotificationsService.getNotifications, {
      read: 0,
      sort: 'created_at:desc',
      limit: 3
    })
  )

  //TODO : change loading message
  if (!data) {
    return <></>
  }

  const notifications = NotificationsService.toRow(data)
  const total = NotificationsService.toPaginate(data).total

  const openModal = () => {
    setOpenNoti(true)
  }

  const closeModal = () => {
    setOpenNoti(false)
  }

  const goToNotification = () => {
    setOpenNoti(false)
    navigate('notification')
  }

  return (
    <Box>
      {isMobile ? (
        <MobileNav openModal={openModal} total={total} />
      ) : (
        <DesktopNav openModal={openModal} total={total} />
      )}
      <Notification
        goToNotification={goToNotification}
        closeModal={closeModal}
        openNoti={openNoti}
        notifications={notifications}
        total={total}
        refresh={refresh}
      />
    </Box>
  )
}

const Notification: FC<{
  goToNotification: () => void
  closeModal: () => void
  refresh: () => void
  openNoti: boolean
  notifications: Notifications[]
  total: number
}> = ({ goToNotification, closeModal, openNoti, notifications, total, refresh }) => {
  const updateNotification = async () => {
    try {
      const { data: result } = await NotificationsService.markNotificationsAsRead({
        ids: notifications.map((notification) => notification.id)
      })

      if (result.success) {
        // refresh home page notification badge
        refresh()
        // refresh notification page
        mutate(
          serialize(NotificationsService.getNotifications, {
            sort: 'created_at:desc',
            page: 1,
            limit: 10
          })
        )
      } else {
        // TODO: show error message
      }
    } catch (e: any) {
      console.log(e)
    }
  }

  return (
    <CustomModal isOpen={openNoti} onRequestClose={closeModal}>
      <Box>
        <Card>
          <Card sx={{ backgroundColor: 'input' }}>
            <Flex variant="layout.flexCenterSpaceBetween">
              <Text>{total} Pending Notification</Text>
              {total > 0 && (
                <Text
                  sx={{ color: 'primary', cursor: 'pointer', ':hover': { opacity: 0.8 } }}
                  onClick={() => updateNotification()}
                >
                  Mark As Read
                </Text>
              )}
            </Flex>
          </Card>

          <Box p={1} />
          <Box>{notifications && notifications.map((item) => <NotiPop notification={item} />)}</Box>
          <Divider />
          <Box sx={{ textAlign: 'center' }}>
            <Button sx={{ background: 'transparent', color: 'primary' }} onClick={goToNotification}>
              See More
            </Button>
          </Box>
        </Card>
      </Box>
    </CustomModal>
  )
}

const MobileNav: FC<{ openModal: () => void; total: number }> = ({ openModal, total }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { navBarDisplay } = useNavBarDisplay()
  const { setSideBarDisplay } = useSideBarDisplay()

  const [navOpen, setNavOpen] = useState<boolean>(false)
  const [buttonAnimation, setButtonAnimation] = useState<TargetAndTransition>({ rotateZ: 0 })
  const [navbarAnimation, setNavbarAnimation] = useState<TargetAndTransition>({ bottom: -1000 })

  useEffect(() => {
    setButtonAnimation({ rotateZ: navOpen ? 45 : 0 })
    setNavbarAnimation({ bottom: navOpen ? 0 : -1000 })
  }, [navOpen])

  return (
    <Box sx={{ display: navBarDisplay ? 'block' : 'none' }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          py: 3,
          px: 5,
          background: 'background',
          zIndex: 2
        }}
      >
        <Box variant="layout.mainContainer" my={0}>
          <Box p={1} />
          <Box variant="layout.flexCenterSpaceBetween">
            <Button variant="round" onClick={() => navigate('gift-rewards')}>
              <AiFillGift />
            </Button>
            <Image src="/voxto-white.svg" alt="voxto" />
            <Flex>
              <IconButton onClick={openModal} sx={{ fontSize: 20 }}>
                <MdNotifications />
                {total > 0 && (
                  <svg width="1" height="1">
                    <circle r={3} cx={3} cy={5} fill="red" />
                  </svg>
                )}
              </IconButton>

              <IconButton onClick={() => setSideBarDisplay(true)} sx={{ fontSize: 20 }}>
                <MdPerson size={200} />
              </IconButton>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 2
        }}
      >
        <motion.div
          sx={{ position: 'absolute', zIndex: -1 }}
          transition={{ duration: 0.3 }}
          animate={navbarAnimation}
        >
          <Card
            variant="layout.mobileNavBar"
            sx={{
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30
            }}
          >
            <Box p={10}>
              <Grid sx={{ rowGap: 6, mb: 6 }}>
                <Link
                  to="/wallet/deposit"
                  onClick={() => {
                    setNavOpen(false)
                  }}
                >
                  <Flex variant="layout.flexCenterCenter">
                    <Box
                      variant="layout.iconContainer"
                      sx={{
                        background:
                          'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%'
                      }}
                    >
                      <Image src="/assets/svg/deposit.svg" />
                    </Box>
                    <Flex sx={{ flex: 1, ml: 2 }} variant="layout.vStack">
                      <Heading as="h4" color="white">
                        Deposit
                      </Heading>
                      <Box>
                        <Text as="p" color="textMuted" variant="small">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Link>
                <Link
                  to="/stake"
                  onClick={() => {
                    setNavOpen(false)
                  }}
                >
                  <Flex variant="layout.flexCenterCenter">
                    <Box
                      variant="layout.iconContainer"
                      sx={{
                        background:
                          'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%'
                      }}
                    >
                      <Image src="/assets/svg/stake-nav.svg" />
                    </Box>
                    <Flex sx={{ flex: 1, ml: 2 }} variant="layout.vStack">
                      <Heading as="h4" color="white">
                        Stake
                      </Heading>
                      <Box>
                        <Text as="p" color="textMuted" variant="small">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Link>
                <Link
                  to="/wallet/withdraw"
                  onClick={() => {
                    setNavOpen(false)
                  }}
                >
                  <Flex variant="layout.flexCenterCenter">
                    <Box
                      variant="layout.iconContainer"
                      sx={{
                        background:
                          'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%'
                      }}
                    >
                      <Image src="/assets/svg/withdraw.svg" />
                    </Box>
                    <Flex sx={{ flex: 1, ml: 2 }} variant="layout.vStack">
                      <Heading as="h4" color="white">
                        Withdraw
                      </Heading>
                      <Box>
                        <Text as="p" color="textMuted" variant="small">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Link>
              </Grid>
              <Box p={5} />
            </Box>
          </Card>
        </motion.div>
        <Flex
          variant="layout.flexCenterSpaceBetween"
          sx={{
            textAlign: 'center',
            background: 'darkBG',
            px: 3,
            py: 2,
            zIndex: 3
          }}
        >
          <Link to="/home">
            <Grid gap={0} sx={{ minWidth: '50px' }}>
              <Box sx={{ textAlign: 'center' }}>
                {location.pathname.includes('/home') ? (
                  <Image src="/assets/svg/home-select.svg" />
                ) : (
                  <Image src="/assets/svg/home.svg" />
                )}
              </Box>
              <Text variant="small">Home</Text>
            </Grid>
          </Link>

          <Link to="/wallet">
            <Grid gap={0} sx={{ minWidth: '50px' }}>
              <Box sx={{ textAlign: 'center' }}>
                {location.pathname.includes('/wallet') ? (
                  <Image src="/assets/svg/wallet-select.svg" />
                ) : (
                  <Image src="/assets/svg/wallet.svg" />
                )}
              </Box>
              <Text variant="small">Wallet</Text>
            </Grid>
          </Link>

          <motion.div transition={{ duration: 0.3 }} animate={buttonAnimation}>
            <Button
              variant="round"
              sx={{ p: 1, width: '6vh', height: '6vh', color: 'secondaryText' }}
              onClick={() => setNavOpen(!navOpen)}
            >
              <BsPlus size="5em" />
            </Button>
          </motion.div>

          <Link to="/stake">
            <Grid gap={0} sx={{ minWidth: '50px' }}>
              <Box sx={{ textAlign: 'center' }}>
                {location.pathname.includes('/stake') ? (
                  <Image src="/assets/svg/stake-select.svg" />
                ) : (
                  <Image src="/assets/svg/stake.svg" />
                )}
              </Box>
              <Text variant="small">Stake</Text>
            </Grid>
          </Link>

          <Link to="/nfts">
            <Grid gap={0} sx={{ minWidth: '50px' }}>
              <Box sx={{ textAlign: 'center' }}>
                {location.pathname.includes('/nfts') ? (
                  <Image src="/assets/svg/blockchain-select.svg" />
                ) : (
                  <Image src="/assets/svg/blockchain-white.svg" />
                )}
              </Box>
              <Text variant="small">NFT</Text>
            </Grid>
          </Link>
        </Flex>
      </Box>
    </Box>
  )
}

const DesktopNav: FC<{ openModal: () => void; total: number }> = ({ openModal, total }) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, background: 'background', zIndex: 4 }}>
      <Box variant="layout.mainContainer" my={0}>
        <Box my={4} variant="layout.flexCenterSpaceBetween">
          <Image src="/voxto-white.svg" alt="voxto" />
          <Box variant="layout.flexCenterEnd">
            {location.pathname.includes('/home') ? (
              <Heading as="h4" sx={{ color: 'primary' }}>
                Home
              </Heading>
            ) : (
              <NavLink onClick={() => navigate('home')}>Home</NavLink>
            )}
            <Box pr={10} />
            {location.pathname.includes('/wallet') ? (
              <Heading as="h4" sx={{ color: 'primary' }}>
                Wallet
              </Heading>
            ) : (
              <NavLink onClick={() => navigate('wallet')}>Wallet</NavLink>
            )}
            <Box pr={10} />
            {location.pathname.includes('/stake') ? (
              <Heading as="h4" sx={{ color: 'primary' }}>
                Stake
              </Heading>
            ) : (
              <NavLink onClick={() => navigate('stake')}>Stake</NavLink>
            )}
            <Box pr={10} />
            {location.pathname.includes('/nfts') ? (
              <Heading as="h4" sx={{ color: 'primary' }}>
                NFTs
              </Heading>
            ) : (
              <NavLink onClick={() => navigate('nfts')}>NFTs</NavLink>
            )}
            <Box pr={10} />
            <Button onClick={() => navigate('gift-rewards')}>
              <Flex variant="layout.hStack">
                <AiFillGift />
                <Box>
                  <Text>Gift / Reward</Text>
                </Box>
              </Flex>
            </Button>
            <Box pr={4} />
            <IconButton onClick={openModal}>
              <MdNotifications size={200} />
              {total > 0 && (
                <svg width="1" height="1">
                  <circle r={3} cx={3} cy={5} fill="red" />
                </svg>
              )}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Header
