import { Box, Card, Checkbox, Divider, Flex, Heading, Label, Text } from 'theme-ui'

const UserAgreementPage = () => {
  return (
    <Box
      variant="layout.newTabContainer"
      sx={{
        minHeight: ['calc(30vh - 51px - 144px)', 'inherit'],
        py: 5,
        position: 'relative'
      }}
    >
      <Card>
        <Box sx={{ textAlign: 'center' }}>
          <Heading sx={{ textDecoration: 'underline' }}>User Agreement</Heading>
        </Box>
        <Box p={[1, 10]}>
          <Text>
            This User Agreement ("Agreement") constitutes a legal and binding agreement between you
            (“User”) and VOXFI Ltd (Company Registration No. 88911) a Samoan Company with its
            registered business address at Vistra Corporate Services Centre, Ground Floor NPF
            Building, Beach Road, Apia, Samoa (“Us” or “VOXTO”).
          </Text>
          <Box p={2} />
          <Text>
            VOXFI Ltd is the company that owns and operates the VOXTO website (“VOXTO Website”).
          </Text>
          <Box p={2} />
          <Text>
            To open an Account (as defined below) and engage with the VOXTO Services (as defined
            below) you must agree to the terms of this Agreement that are set out below.
          </Text>
          <Box p={2} />
          <Text>
            This Agreement is effective upon the date you first click “I agree” or browse the VOXTO
            Website (the “VOXTO Website”) or participate in and / or engage with any VOXTO Services
            (use) via the VOXTO Website. By electronically accepting or acknowledging this
            Agreement, and / or signing up for an Account with VOXTO and / or using the VOXTO
            Services, you represent and undertake, that you have, and are deemed to have read and
            accepted this Agreement.
          </Text>
          <Box p={2} />
          <Text>
            By accepting this Agreement, you also agree to be bound by our Terms of Conditions of
            Use and Privacy Policy, which are hereby deemed to be incorporated into this Agreement
            by reference.
          </Text>
          <Box p={3} />
          <Heading as="h3">ABOUT THE VOXTO WEBSITE AND ITS SERVICES</Heading>
          <Text>
            The VOXTO Website is a Centralized Finance (CeFi) Wallet designed to help investors
            store their Bitcoin (BTC), Ethereum (ETH), and VOXTO Token (VXT) securely while earning
            strong monthly returns. 
          </Text>
          <Box p={2} />
          <Text>Start earning passive income with VOXTO in four easy steps:- </Text>
          <ol>
            <li>
              <Text>Sign up for free by following simple steps to set up your profile.</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Deposit Bitcoin (BTC) and / or Ethereum (ETH) and / or VOXTO Token (VXT) into your
                Wallet by scanning the QR code or copying the deposit address.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Select an Amplify Program and start investing.</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Start earning strong returns that will be reflected in your Account every month.
              </Text>
              <Box p={1} />
            </li>
          </ol>
          <Text>There are currently 2 Amplify Programs to choose from:- </Text>
          <ol>
            <li>
              <Text>Growth Fund (12-Month Program)</Text>
              <ol type="a">
                <li>
                  <Text>35% returns based on VOXTO’s total monthly returns</Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>Locked for 12 months</Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>Paid out monthly to User's Wallet</Text>
                  <Box p={1} />
                </li>
              </ol>
              <Box p={1} />
            </li>
            <li>
              <Text>Ultimate Growth Fund (24-Month Program)</Text>
              <ol type="a">
                <li>
                  <Text>50% returns based on VOXTO’s total monthly returns</Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>Locked for 24 months</Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>Paid out monthly to User's Wallet</Text>
                  <Box p={1} />
                </li>
              </ol>
              <Box p={1} />
            </li>
          </ol>
          <Box p={3} />
          <ol style={{ paddingInlineStart: 20 }}>
            <Heading as="h3">
              <li>PREFACE</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  In this Agreement, the following words and phrases have the corresponding
                  meanings, unless the context otherwise requires: -
                </Text>
                <Box p={2} />
                <Text>"Account" means a User’s VOXTO Account.</Text>
                <Box p={2} />
                <Text>
                  "Applicable Laws" means all applicable local or foreign laws, rules, acts,
                  regulations, subsidiary, legislation, notices, notifications, circulars, license
                  conditions, directions, requests, requirements, guidelines, directives, codes,
                  information papers, practice notes, demands, guidance and/or decisions of any
                  national, state or local government, any agency, exchange, regulatory or
                  self-regulatory body, law enforcement body, court, central bank or tax revenue
                  authority or any other authority.
                </Text>
                <Box p={2} />
                <Text>"Asset" means a digital asset.</Text>
                <Box p={2} />
                <Text>
                  "Cryptocurrency" or "Digital Asset" means a digital currency in which transactions
                  are verified and records maintained by a decentralized system using cryptography,
                  rather than by a centralized authority.
                </Text>
                <Box p={2} />
                <Text>
                  "User" means a person who has a valid Account as may be determined by VOXTO.
                </Text>
                <Box p={2} />
                <Text>
                  "Digital Asset Transaction" means the transaction of sending Digital Assets to
                  third parties, and requesting, receiving and storing of Digital Assets from third
                  parties.
                </Text>
                <Box p={2} />
                <Text>
                  "Digital Currency" means a digital representation of value which is recorded on a
                  distributed digital ledger, whether cryptographically secured or otherwise, that
                  function as medium of exchange and is interchangeable with any money, including
                  through the crediting or debiting of an account.
                </Text>
                <Box p={2} />
                <Text>
                  "Digital Token" means a digital representation which is recorded on a distributed
                  digital ledger whether cryptographically secured or otherwise.
                </Text>
                <Box p={2} />
                <Text>
                  "Encumbrance" means any interest or equity of any person (including any right to
                  acquire, option or right of pre-emption) or any mortgage, charge, pledge, lien,
                  assignment, hypothecation, security interest, title retention or any other
                  security agreement or arrangement.
                </Text>
                <Box p={2} />
                <Text>
                  "Enhanced Due Diligence" means the submission of additional information as
                  requested by VOXTO.
                </Text>
                <Box p={2} />
                <Text>
                  "Forks" means the sudden changes in operating rules of the underlying protocols
                  which govern the operation of Digital Assets.
                </Text>
                <Box p={2} />
                <Text>
                  “Investment” means your investment in the VOXTO Website of Cryptocurrency(ies),
                  Digital Asset(s), Digital Currency(ies) and Digital Token(s) as defined in this
                  Agreement.
                </Text>
                <Box p={2} />
                <Text>"KYC" means Know-Your-Client.</Text>
                <Box p={2} />
                <Text>
                  “Novatee” means a person that takes over the responsibility to pay a debt or to
                  honour a contract, from the original holder of the contract or debt
                </Text>
                <Box p={2} />
                <Text>“Platform” means the VOXTO Website </Text>
                <Box p={2} />
                <Text>
                  "Privacy Policy" means the policy which sets out VOXTO’s data processing practices
                  in respect of personal data collected by VOXTO, as made available at voxto.com ,
                  and as may be supplemented, amended or varied from time to time upon notification
                  to you.
                </Text>
                <Box p={2} />
                <Text>
                  "Prohibited Activity" means any of the prohibited acts or engaging in any of the
                  prohibited businesses.
                </Text>
                <Box p={2} />
                <Text>
                  "United States Dollars" or "USD" means the currency of the United States of
                  America.
                </Text>
                <Box p={2} />
                <Text>
                  "Term and Conditions of Use" means the set of terms which govern use of the
                  Platform by any person who browses, accesses or uses the VOXTO Website, as made
                  available at voxto.com , and as may be supplemented, amended or varied from time
                  to time upon notification to you.
                </Text>
                <Box p={2} />
                <Text>
                  "VOXTO Digital Wallet" means digital asset wallets enabling you to store, track,
                  and manage your balances of Cryptocurrency or Digital Asset.
                </Text>
                <Box p={2} />
                <Text>
                  "VOXTO Member” means the subscription for the service provided in the VOXTO
                  Website.
                </Text>
                <Box p={2} />
                <Text>
                  "VOXTO Services" means the access to VOXTO Website at voxto.com and its offered
                  services on the VOXTO Website.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  The words "we", "us", "our" or any of their derivatives refer to VOXTO and its
                  successors and any novatee, assignee, transferee or purchaser of VOXTO’s rights
                  and / or obligations here under and any reference to VOXTO includes a reference to
                  such successor, novatee, assignee, transferee or purchaser.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  The words "you", "your", "yours" or any of their derivatives refer to the person
                  who opened the Account and / or using our VOXTO Services and shall include, as the
                  context may require, personal representatives (as the case may be).
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  The relationship between you and VOXTO is governed by this Agreement. This
                  Agreement shall apply to and govern your Account with us and all VOXTO Services.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  Section headings in this Agreement are for convenience only and shall not govern
                  the meaning or interpretation of any provision of this Agreement.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>ELECTRONIC CONTRACTING, COMMUNICATIONS AND SIGNATURES</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  Communications, notices, statements and any other document relating to your
                  relationship with us may be provided to you, whether solely or partially via
                  electronic means.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You hereby agree to receive all and any communications from us through electronic
                  means, and you agree to VOXTO’s use of electronic communications to enter into
                  agreements and to communicate with you which may be affected in accordance with
                  Applicable Laws on electronic commerce and electronic signatures.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You agree and acknowledge that all electronic records created and stored by us in
                  respect of all matters relating to you, any transactions on your Account and / or
                  any VOXTO Services provided to you are deemed to be in writing and shall satisfy
                  any legal requirement for documents to be in writing.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You agree and acknowledge that all electronic records created and stored by us are
                  admissible under applicable evidentiary laws in respect of any facts stated
                  therein. In the event we use or rely on such electronic records as evidence of any
                  matters, you agree that you will not, at any time dispute the authenticity or
                  accuracy of any electronic records relied upon by us on the grounds that such
                  record or information is in electronic form. For the avoidance of doubt, the
                  preceding clauses are subject to our right to rectify any error or omission
                  therein and are without prejudice to our right to adduce any other evidence.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>OUR RELATIONSHIP WITH YOU</li>
            </Heading>
            <ol>
              <li>
                <Text>Member</Text>
                <Box p={2} />
                <Text>
                  Upon signing up for an Account with us and / or participate in and / or engage
                  with our VOXTO Services, you shall become a VOXTO member, and you will remain a
                  VOXTO member until and unless your membership is terminated or suspended in
                  accordance with this Agreement. Our obligations to you as a VOXTO member are as
                  set forth in this Agreement.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>Client</Text>
                <Box p={2} />
                <Text>
                  If and to the extent that you make an Investment through the Platform you are our
                  client, and we will treat you as our client for all purposes related to such
                  Investment.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We do not provide advice or recommendations with respect to any aspect of
                  transactions conducted through the Platform, other than advice on the technical
                  use of the Platform. This means, among other things, that we cannot give you any
                  investment, legal, taxation or other advice in connection with your membership or
                  any investments you make through the platform, and nothing on the platform or in
                  any communications we send to you is intended to constitute advice or a
                  recommendation. If you need or want advice, you should consult an appropriate
                  professional financial, legal, taxation or other adviser.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>ASSUMPTION OF RISKS</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  You should understand the risks involved in your Investment in Digital Assets. As
                  with any asset, the value of Digital Assets can go up or down and there can be a
                  substantial risk that you may lose money investing in Digital Assets.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  Digital Asset markets can be volatile, and prices can fluctuate significantly,
                  which could result in sudden and significant increases or decreases in the value
                  of your Digital Assets. This market volatility and other risks involved in
                  investing in Digital Assets, there may be additional risks not identified in this
                  Agreement or the risk warning.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You should carefully consider whether your financial situation and risk tolerance
                  is suitable for investing in Digital Assets. You accept and agree that you are
                  solely responsible for any decision to invest or otherwise hold or deal with
                  Digital Assets and such decision should take into account your risk tolerance and
                  financial circumstances. You should consult if applicable, your own legal
                  consultant or tax professional in relation to your specific situation.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  Under no circumstances does any information contained on the Platform or provided
                  to you through your Account or by any employee, agent or affiliate of VOXTO,
                  constitute financial, investment or other professional advice.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>ELIGIBILITY CRITERIA</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  By opening an Account with us, you expressly warrant and represent that: -
                </Text>

                <Box p={1} />
              </li>
              <ol type="a">
                <li>
                  <Text>
                    If you are an individual, you are at least 18 years of age (“majority age”);
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>You have full capacity to accept this Agreement;</Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    You will not have more than one Account or use or access any Account other than
                    your own or assist any other person in obtaining unauthorized access to your
                    Account or any other Account;
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    All information and documents provided to us are true, correct and complete and
                    not misleading in any material way and such information and documents will
                    remain true and correct;
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    All actions, conditions and things required to be taken, fulfilled and done in
                    order: -
                  </Text>
                  <Box p={1} />
                  <ol type="i">
                    <li>
                      <Text>
                        To enable you to lawfully enter into, exercise your rights and perform, and
                        comply with, your obligations under this Agreement, and
                      </Text>
                      <Box p={1} />
                    </li>
                    <li>
                      <Text>
                        To ensure that those obligations are valid, legally binding and enforceable,
                        have been taken, fulfilled and done;
                      </Text>
                      <Box p={1} />
                    </li>
                  </ol>
                </li>
                <li>
                  <Text>
                    Your obligations under this Agreement are valid, binding and enforceable;
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    You are solvent, able to pay your debts as they fall due and not an undischarged
                    bankrupt and / or are a going concern;
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    Your entry into, exercise of your rights and / or performance of or compliance
                    with your obligations under this Agreement does not and will not: -
                  </Text>
                  <Box p={1} />
                  <ol type="i">
                    <li>
                      <Text>
                        Violate and / or breach any agreement to which you or where applicable, any
                        of your affiliates, is a party or which is binding on any of you or your
                        respective assets, or
                      </Text>
                      <Box p={1} />
                    </li>
                    <li>
                      <Text>
                        Result in the existence of, or oblige any of you to create, any security
                        over those assets;
                      </Text>
                      <Box p={1} />
                    </li>
                  </ol>
                </li>
                <li>
                  <Text>
                    You have obtained all consents, licenses, approvals or authorizations of,
                    exemptions by or registrations with or declarations by, any governmental or
                    other authority that you require, and these are valid and subsisting and will
                    not be contravened by the execution or performance of this Agreement;
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>Your use of the VOXTO Services complies with all Applicable Laws; </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    You will inform yourself and, if necessary, consult your own professional
                    advisers as to the relevant legal, tax, exchange control and investment
                    regulations in force in the countries of your citizenship, residence or
                    domicile; and
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    You will not use your Account for or in relation to any illegal activity. Any
                    Prohibited Activity, in violation of any laws, statutes, ordinances or
                    regulations, or in breach of export controls or sanctions requirements.
                  </Text>
                  <Box p={1} />
                </li>
              </ol>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>ACCOUNT</li>
            </Heading>
            <ol>
              <li>
                <Text>Registration of Account</Text>
                <Box p={1} />
              </li>
              <ol>
                <li>
                  <Text>
                    To participate in and / or engage with the VOXTO Services, you will need to
                    register for an Account by providing your name, email address, a password and
                    acceptance of this Agreement. By creating an Account, you agree and represent
                    that you will participate in and / or engage with the VOXTO Services only for
                    yourself, and not on behalf of any third party. You undertake not to register
                    for more than one Account or register an Account on behalf of any person other
                    than yourself.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    You are fully responsible for all activity that occurs under your Account. We
                    may, in our sole discretion, refuse to open an Account for you, (or limit the
                    number of Accounts that you may hold, or suspend or terminate any Account or the
                    Investment of specific Digital Asset in your Account. For the avoidance of
                    doubt, we are not obliged to open any Account for you or provide you with any of
                    the VOXTO Services.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>You are responsible over your own Account.</Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    You are responsible for safeguarding the password for your Account and for any
                    activities or actions taken under your Account. You must notify us immediately
                    upon becoming aware of any breach of security or unauthorized use of your
                    account. The use of your Account with us is premised on you as the sole user and
                    not transferable to any other party. Any unauthorized use will constitute a
                    breach of this Agreement and liable to immediate termination.
                  </Text>
                  <Box p={1} />
                </li>
              </ol>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>VERIFICATION OF IDENTITY</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  You acknowledge that we are obliged to carry out KYC processes in accordance with
                  our policies and this Agreement.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You agree to provide us with any information we may request for the purposes of
                  identity verification and for the detection of money laundering, terrorist
                  financing, fraud, or any other financial crime, and you permit us to keep a record
                  of such information.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You agree to complete certain verification procedures before you are permitted to
                  start participating in and / or engaging with the VOXTO Services and to access
                  specific VOXTO Services, including certain transfers of any Digital Assets, and
                  the limits that apply to your use of the VOXTO Services may be altered by us from
                  time to time as a result of information collected on an ongoing basis.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  In providing us with information required for our KYC processes, you confirm that
                  the information provided to us is true, correct and complete.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You undertake to inform us promptly of any change in the information provided. We
                  will treat this information in accordance with our Privacy Policy at voxto.com.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You agree to provide any information or documents requested by us in relation to
                  your Account including where desirable or where required for the purposes for
                  complying with any Applicable Laws or pursuant to any order, direction, or request
                  by any applicable court, government or regulatory authority. This includes but it
                  not limited to any applicable anti-money laundering requirements, or any
                  applicable tax disclosure or reporting obligations.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>TRANSACTION LIMITS AND ENHANCED DUE DILIGENCE</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  The participation and / or engagement of the VOXTO Services is subject to a limit
                  on the value of USD or Digital Asset that you may transact or transfer in a given
                  period (e.g. daily) and as may be amended by us from time to time.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  The transaction limit shall be USD 20,000.00. In the event if you wish to deposit
                  more than USD 20,000.00, we may carry out an Enhanced Due Diligence on you as
                  specified in Clause 8.5 and 8.6 of this Agreement.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  Users can deposit a minimum amount of 0.1 Ethereum (ETH), 0.01 Bitcoin (BTC) and
                  100 VOXTO Token (VXT) to their Account.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We reserve the right to change applicable limits and minimum deposit as we deem
                  necessary.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We may require you to submit additional information about yourself or your
                  business if you wish to raise your limits ("Enhanced Due Diligence"). We reserve
                  the right to charge you costs and fees associated with such Enhanced Due Diligence
                  although if we intend to do so, we will notify you in advance so that you can
                  decide whether you wish to proceed with the request. In our discretion, we may
                  refuse to raise your limits, or we may lower your limits at a subsequent time even
                  if you have completed Enhanced Due Diligence.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You accept and agree that you will always remain subject to such Enhanced Due
                  Diligence procedures. Additionally, we may require you to wait some amount of time
                  after completion of a transaction before permitting you to further participate in
                  and / or engage with the VOXTO Services and / or before permitting you to engage
                  in transactions beyond certain volume or value limits.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>VOXTO DIGITAL WALLET</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  Your VOXTO Digital Wallet enables you to send Digital Assets to, and request,
                  receive, and store Digital Assets from, third parties by giving instructions
                  through the Platform (each such transaction is a "Digital Asset Transaction").
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  The VOXTO Digital Wallet is only available in relation to the Digital Assets that
                  VOXTO supports. Information about the Digital Assets that VOXTO supports can be
                  found at voxto.com which may change from time to time. Under no circumstances
                  should you attempt to use your VOXTO Digital Wallet to store, send, request, or
                  receive digital assets in any form that we do not support (we will give a 30
                  days-notice before forgone our support on the digital asset that is no longer
                  supported). We assume no responsibility or liability in connection with any
                  attempt to use your VOXTO Digital Wallet for digital assets that we do not
                  support.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We will process Digital Asset Transactions in accordance with the instructions we
                  receive from you. You should verify all transaction information prior to
                  submitting instructions to us. We do not guarantee the identity of any user,
                  receiver, requestee or other third party and we will have no liability or
                  responsibility for ensuring that the information you provide is accurate and
                  complete.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>We may charge you withdrawal fee for each withdrawal of Digital Assets.</Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We may also refuse to process or cancel any pending Digital Asset Transaction if
                  required by law, regulation or any court or other authority to which VOXTO or
                  VOXTO’s affiliate is subject to in any jurisdiction.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  In the event Digital Asset has been transferred to your Account in error which you
                  know, suspect, or should reasonably know or suspect, you must immediately notify
                  VOXTO of the error by sending an email to support@voxto.com You accept and agree
                  that you have no claim or entitlement to any Digital Asset received in error and
                  must immediately return such funds in accordance with the instructions received
                  from VOXTO.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li></li>
            </Heading>
            <ol>
              <li>
                <Text>
                  When you send or receive Digital Assets, the transaction must be confirmed and
                  recorded in the public ledger associated with the relevant Digital Asset network
                  (e.g. Ethereum (ETH) network). That Digital Asset network is solely responsible
                  for verifying and confirming any such transactions. VOXTO cannot confirm, cancel
                  or reverse Digital Asset Transactions once they have been broadcasted to the
                  relevant Digital Asset network.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>You accept and agree that: -</Text>
                <Box p={1} />
                <ol type="a">
                  <li>
                    <Text>
                      Once submitted to a Digital Asset network, a Digital Asset Transaction will be
                      unconfirmed for a period of time pending sufficient confirmation of the
                      Digital Asset Transaction by the Digital Asset network. A Digital Asset
                      Transaction is not complete while it is in a pending state. Funds associated
                      with transactions that are in a pending state will be designated accordingly
                      and will not be included in your Account or be available to you to conduct
                      transactions.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      When you send Digital Assets from your Account, you are authorizing us to
                      submit your transaction request to the relevant Digital Asset network. Once a
                      transaction request has been submitted to the relevant Digital Asset network,
                      the Digital Asset network will automatically complete or reject the request
                      and neither you nor VOXTO will be able to cancel or otherwise modify your
                      transaction.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      Digital Asset networks are operated by decentralised networks of independent
                      third parties. They are not owned, controlled or operated by VOXTO, we cannot
                      ensure that any transaction details you submit will be confirmed by the
                      relevant Digital Asset network. You agree that any transaction details you
                      submit may not be completed, or may be substantially delayed, by the Digital
                      Asset network used to process the transaction.
                    </Text>
                    <Box p={1} />
                  </li>
                </ol>
              </li>
              <li>
                <Text>
                  When you or a third party sends Digital Assets to a VOXTO Digital Wallet from an
                  external digital wallet not hosted on VOXTO, the person initiating the transaction
                  is solely responsible for executing the transaction properly, which may include,
                  among other things, payment of miner’s fees in order for the transaction to be
                  successful. Non-payment of miner fees may cause your transaction to remain in a
                  pending state outside of VOXTO’s control and we are not responsible for delays or
                  losses incurred as a result of an error in the initiation of the transaction and
                  VOXTO has no obligation to assist in the remediation of such transactions.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We do not own or control the underlying software protocols which govern the
                  operation of Digital Assets supported on our platform. Generally, the underlying
                  protocols are ‘open source’ and anyone can use, copy, modify, and distribute them.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We assume no responsibility for the operation of the underlying protocols, and we
                  are not able to guarantee their functionality, security, or availability. You
                  acknowledge and accept the risk that underlying software protocols relating to any
                  Digital Asset you store in your VOXTO Digital Wallet may change.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  The underlying protocols are subject to sudden changes in operating rules
                  ("Forks"), and such forks may materially affect the value, function, and / or the
                  name of the Digital Asset you store in your VOXTO Digital Wallet. Should a fork
                  occur, VOXTO may, with or without notice to you, temporarily suspend operations in
                  relation to that fork. We may, in our reasonable discretion, decline to support
                  either or both branches of a fork. You acknowledge the risks presented by forks
                  and you accept that we have no responsibility to assist you to move or sell an
                  unsupported branch of a forked protocol.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>DIGITAL ASSETS</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  All Digital Assets held in your VOXTO Digital Wallet are custodial assets held by
                  the VOXTO for your benefit.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  Ultimately, the title to your Digital Assets shall at all times remain with you
                  and shall not transfer to VOXTO. As the owner of a Digital Asset in your VOXTO
                  Digital Wallet, you shall bear all risk of loss of such Digital Asset. VOXTO shall
                  not have any liability to you whether for fluctuations in the fiat currency value
                  of Digital Asset held in your VOXTO Digital Wallet or otherwise.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  The Digital Assets are held by VOXTO on behalf of you when you deposit into your
                  VOXTO Digital Wallet. The VOXTO Digital Wallet shows the value of the Digital
                  Asset that you have. The value is to be determined by VOXTO upon the conversion of
                  the daily market rate. At any time, subject to outages, downtime, and other
                  applicable policies, you may withdraw your Digital Assets by sending it to an
                  external digital wallet controlled by you or a third party upon the request to us.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  To more securely hold custody assets, VOXTO may use shared blockchain addresses,
                  controlled by us, to hold Digital Assets held on behalf of User and / or held on
                  behalf of VOXTO.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>PAYMENTS FOR BITCOIN (BTC), ETHEREUM (ETH) AND VOXTO TOKEN (VXT)</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  The payment should be made by way of BTC and / or ETH and / or VXT from your own
                  account (which is not VOXTO Digital Wallet) into your VOXTO Account. The BTC and /
                  or ETH and / or VXT transferred in your VOXTO Digital Wallet can be used only for
                  Investment in accordance with this Agreement.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  According to this Agreement, you will deposit your BTC and / or ETH and / or VXT
                  directly to VOXTO Digital Wallet as specified by us. The said payment will be a
                  used exclusively for the purpose of investment. Subject to the terms of this
                  Agreement, you retain a right to your own VOXTO Digital Wallet until such time as
                  your BTC and / or ETH and / or VXT are used or withdrawn.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  The payment method by which you pay the BTC and / or ETH and / or VXT shall be
                  executed via a unique QR Code and / or Deposit Address (which is unique to each
                  User).
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You may also deposit Digital Assets from third party hosted digital wallets
                  belonging to you into your VOXTO Digital Wallet. Digital Assets deposited in your
                  VOXTO Digital Wallet can be used for investment. Deposits of Digital Assets may
                  take time to reflect in your VOXTO Digital Wallet due to operational processes,
                  and / or failure by you to adhere to VOXTO’s requirements for deposits of Digital
                  Assets. Under no circumstances will VOXTO have any liability to you in relation to
                  any delay in failure of a deposit being reflected in your VOXTO Digital Wallet.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>WITHDRAWALS</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  You may withdraw Digital Assets from your VOXTO Digital Wallet upon request to us.
                  You are solely responsible for ensuring that any withdrawal instructions provided
                  to VOXTO, including the relevant digital wallet address, are complete and correct
                  and you accept and agree that under no circumstances will VOXTO be under any
                  obligation to reverse or revise any withdrawal.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  Upon receiving your request for withdrawal, we may carry out necessary due
                  diligence if we have reasons to believe that the withdrawal is suspicious or
                  unusual.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You may withdraw your Digital Assets from your Account. VOXTO will process
                  withdrawals in accordance with your instructions. You are solely responsible for
                  ensuring that any withdrawal instructions provided to VOXTO. You accept and agree
                  that under no circumstances will VOXTO be under any obligation to reverse or
                  revise any withdrawal.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  VOXTO may also charge a withdrawal fee on certain deposits or withdrawal of
                  Digital Assets. All such fees will be clearly displayed in your Account or in the
                  VOXTO Website at support@voxto.com.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>VOXTO’S PROFIT SHARE SCHEME</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  The fee payable by you to us for the VOXTO Services is specified in the Fee
                  Schedule available at our Platform ("Fees") as may be amended from time to time.
                  All liabilities, costs and expenses which we incur to provide the VOXTO Services
                  under this Agreement will be covered by the Fees.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>Our Profit-Sharing Structure are as follows: -</Text>
                <Box p={1} />
                {/* TODO : Add picture */}
              </li>
              <li>
                <Text>
                  You shall make all payments due under this Agreement free and clear of, and
                  without deduction, withholding or set-off on account of, any tax or levy or any
                  other charges present and future.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You shall be liable for any service tax (“SST”), value added tax or any other tax
                  chargeable by law on any payment you are required to make to us. If we are
                  required by law to collect and make payment in respect of such tax, you will
                  indemnify us against such payments.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We may deduct your Account with the full amount of any Fees payable by you, or any
                  other monies owed by you to us pursuant to any liability of any nature arising in
                  respect of the Account or otherwise. For this purpose, we may withdraw and collect
                  and / or sell your Digital Assets and collect the proceeds from such sale.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>COMPLIANCE WITH APPLICABLE LAWS</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  The provision of the Account and all VOXTO Services by us to you and the
                  relationship between us and you shall be subject to all Applicable Laws provided
                  that to the extent permitted by law, a breach of any Applicable Laws shall not
                  discharge or release you from any of your obligations under this Agreement to us.
                  The availability of any VOXTO Service or any terms and conditions applicable
                  thereto (including pursuant to this Agreement) may be varied by us without notice
                  to you for compliance (voluntary or otherwise) with the Applicable Laws.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We shall be entitled to rely on and to act in accordance with all legislation and
                  any guidelines, codes, or other information applicable to us. We shall not incur
                  any liability to you as a result of our so reliance or acting. For the avoidance
                  of doubt, this Agreement shall be construed in accordance with any Applicable
                  Laws.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You agree that nothing in this Agreement shall exempt, limit or exclude us from
                  acting in compliance with any applicable guidelines or any other Applicable Laws
                  in carrying out our obligations under this Agreement. To the extent that any
                  provision of this Agreement is inconsistent with the requirements of any
                  Applicable Laws or other information applicable to us. The requirements of the
                  relevant Applicable Laws shall prevail over this Agreement.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>PROHIBITIONS</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  By opening an Account, you hereby agree and acknowledge that you are prohibited
                  from performing any of the prohibited acts or engaging in any of the prohibited
                  businesses set out in this section (each a "Prohibited Activity"): -
                </Text>
                <Box p={1} />
                <ol>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Unlawful activity :</Text>
                    <Text>
                      {' '}
                      Violation of any laws, statutes, ordinance, or regulations within the
                      Jurisdiction of Samoa and / or other Applicable Laws.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Abusive activity : </Text>
                    <Text>
                      {' '}
                      Engaging in abusive activity which includes but is not limited to: -
                    </Text>
                    <Box p={1} />
                    <ol>
                      <li>
                        <Text>
                          Actions which impose an unreasonable or disproportionately large load on
                          our infrastructure, or detrimentally interfere with, intercept, or
                          expropriate any system, data, or information;
                        </Text>
                        <Box p={1} />
                      </li>
                      <li>
                        <Text>
                          Transmitting or uploading any material to the Platform that contains
                          viruses, trojan horses, worms, or any other harmful or deleterious
                          programs;
                        </Text>
                        <Box p={1} />
                      </li>
                      <li>
                        <Text>
                          Attempting to gain unauthorized access to the VOXTO Website, other
                          Accounts, computer systems or networks connected to the VOXTO Website,
                          through password mining or any other means;
                        </Text>
                        <Box p={1} />
                      </li>
                      <li>
                        <Text>
                          Using Account information of another party to access or use the VOXTO
                          Website, except in the case of specific merchants and / or applications
                          which are specifically authorized by a user to access such User's Account
                          and information; or
                        </Text>
                        <Box p={1} />
                      </li>
                      <li>
                        <Text>
                          Transferring your Account access or rights to your account to a third
                          party, unless by operation of law or with the express permission of VOXTO.
                        </Text>
                        <Box p={1} />
                      </li>
                    </ol>
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Abuse other users: -</Text>
                    <Box p={1} />
                    <ol>
                      <li>
                        <Text>
                          Interfere with another individual's or entity's access to or use of any
                          VOXTO Services;
                        </Text>
                        <Box p={1} />
                      </li>
                      <li>
                        <Text>
                          Defame, abuse, extort, harass, stalk, threaten or otherwise violate or
                          infringe the legal rights (such as, but not limited to, rights of privacy,
                          publicity and intellectual property) of others;
                        </Text>
                        <Box p={1} />
                      </li>
                      <li>
                        <Text>
                          Incite, threaten, facilitate or encourage hate, racial intolerance, or
                          violent acts against others; harvest or otherwise collect information from
                          the Platform about others, including without limitation, email addresses,
                          without consent.
                        </Text>
                        <Box p={1} />
                      </li>
                    </ol>
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Illegal activities :</Text>
                    <Text>
                      {' '}
                      undertaking, facilitating or supporting criminal activity of any kind,
                      including but not limited to, money laundering, terrorist financing, illegal
                      gambling operations or malicious hacking.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Fraud :</Text>
                    <Text>
                      {' '}
                      any fraudulent activities, including but not limited to taking any actions
                      that defraud VOXTO or a VOXTO user or any other person, or the provision of
                      any false, inaccurate, or misleading information to VOXTO.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Gambling :</Text>
                    <Text>
                      {' '}
                      Lotteries; bidding fee auctions; sports forecasting or odds making; fantasy
                      sports leagues with cash prizes; internet gaming; contests; sweepstakes; games
                      of chance.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>
                      Intellectual Property Infringement :
                    </Text>
                    <Text>
                      {' '}
                      Undertaking, facilitating or supporting transactions involving items that
                      infringe or violate any copyright, trademark, right of publicity or privacy or
                      any other proprietary right under the law.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Drugs and substances :</Text>
                    <Text>
                      {' '}
                      sale of narcotics, controlled substances, pharmaceuticals and other ducts that
                      make health claims that have not been approved or verified by the applicable
                      local and / or national regulatory body, substances designed to mimic illegal
                      drugs.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Adult Content and Services :</Text>
                    <Text>
                      {' '}
                      pornography and other obscene materials (including literature, imagery and
                      other media); sites offering any sexually-related services such as
                      prostitution, escorts, pay-per view, adult live chat features.
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text sx={{ textDecoration: 'underline' }}>Multievel Marketing (“MLM”) :</Text>
                    <Text>
                      {' '}
                      pyramid schemes, Ponzi schemes, matrix programmes, "get rich quick" schemes,
                      multi-level marketing programmes or high-yield investment programmes.
                    </Text>
                    <Box p={1} />
                  </li>
                </ol>
              </li>
              <li>
                <Text>
                  16.2.Unless you have obtained the prior written consent of VOXTO, you accept and
                  agree that you will not use your Account to conduct or operate any of the
                  following business activities or categories of activity: -
                </Text>
                <Box p={1} />
              </li>
              <ol>
                <li>
                  <Text sx={{ textDecoration: 'underline' }}>Money Services :</Text>
                  <Text>
                    {' '}
                    money remittance, digital asset transmission, currency or Digital Asset
                    exchanges or dealers; payment service provider, e-money or any other financial
                    services business.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text sx={{ textDecoration: 'underline' }}>Investment and Credit Services :</Text>
                  <Text>
                    {' '}
                    investment funds, asset management, brokerage services, consumer lending
                    services, including but not limited to secured and unsecured loans, cash
                    advances, payday lending.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text sx={{ textDecoration: 'underline' }}>Charities :</Text>
                  <Text> Acceptance of donations for non-profit enterprises.</Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text sx={{ textDecoration: 'underline' }}>Games of Skill :</Text>
                  <Text>
                    {' '}
                    Games which are not defined as gambling under this Agreement or by law, but
                    which require an entry fee and award a prize.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text sx={{ textDecoration: 'underline' }}>
                    Religious / Spiritual Organizations :
                  </Text>
                  <Text> Operation of a for-profit religious or spiritual organization.</Text>
                  <Box p={1} />
                </li>
              </ol>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>SUSPENSION, TERMINATION AND CANCELLATION OF ACCOUNT</li>
            </Heading>
            <ol>
              <li>
                <Text>We reserve the right to: -</Text>
                <Box p={1} />
              </li>
              <ol>
                <li>
                  <Text>
                    Refuse to complete, or block, cancel or reverse a transaction you have
                    authorized (even after funds have been debited from your Account),
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    Suspend, restrict, or terminate your access to any or all of VOXTO Services, and
                    / or
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    Deactivate or cancel your Account with immediate effect for any reason,
                    including but not limited to where: -
                  </Text>
                  <Box p={1} />
                </li>
                <ol type="a">
                  <li>
                    <Text>
                      We reasonably believe that we need to do so in order to protect our
                      reputation;
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      We are, in our reasonable opinion, required to do so by applicable law,
                      regulation or any court or other authority to which we are subject in any
                      jurisdiction;
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>We reasonably suspect you of acting in breach of this Agreement;</Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      We reasonably suspect that your Account to be subject of an operational or
                      other error, in which case we may be required to suspend access to your
                      Account until such time as the error is rectified;
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      We reasonably suspect that your Account has been or is being used in relation
                      to any unlawful, or fraudulent manner;
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      We reasonably suspect you or your Account is or has been associated with, or
                      poses a high risk of, money laundering, terrorist financing, fraud, or any
                      other financial crime;
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      Use of your Account is subject to any pending litigation, investigation, or
                      government proceeding and / or we perceive a heightened risk of legal or
                      regulatory non-compliance associated with your Account; or
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      We reasonably suspect you of taking any action that may circumvent our
                      controls such as opening multiple Accounts or abusing promotions that we may
                      offer from time to time.
                    </Text>
                    <Box p={1} />
                  </li>
                </ol>
              </ol>
              <li>
                <Text>
                  If we refuse to complete a transaction and / or suspend, restrict or close your
                  Account, and / or terminate your participation to and / or engagement with VOXTO
                  Services, we will (unless it would be unlawful for us to do so) provide you with
                  notice of our actions and at our absolute discretion, with the reasons for our
                  refusal, suspension or closure and with the procedure for correcting any factual
                  errors that led to such refusal, suspension or closure. If we refuse to complete a
                  transaction and / or suspend your Account, we will lift the suspension or complete
                  the transaction as soon as reasonably practicable once the reasons for refusal and
                  / or suspension no longer exist. However, we are under no obligation to allow you
                  to reinstate a transaction at the same price or on the same terms as the
                  suspended, reversed or cancelled transaction.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We may suspend, restrict, or terminate your access to any or all of the VOXTO
                  Services and / or deactivate or cancel your Account, without reason by giving you
                  30 days’ notice. You acknowledge that our decision to take certain actions,
                  including limiting access to, suspending, or closing your Account, may be based on
                  confidential criteria that are essential for the purposes of our risk management
                  and security protocols. You agree that we are under no obligation to disclose the
                  details of our risk management and security procedures to you.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  If you wish to terminate your Account, you may submit a request at
                  support@voxto.com to terminate your Account. All provisions of this Agreement
                  which by their nature should survive termination shall survive termination,
                  including, without limitation, ownership provisions, warranty disclaimers,
                  indemnity and limitations of liability.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  On termination of this Agreement for any reason, unless prohibited by Applicable
                  Laws or by any court or other order to which VOXTO is subject in any jurisdiction,
                  you are permitted to access your Account for thirty (30) days thereafter for the
                  purposes of transferring Digital Assets and / or your funds deposited in your
                  Account. You are not permitted to participate in and / or engage with VOXTO
                  Services or your Account for any other purposes during these periods and we may,
                  at our discretion, limit the functionality of the Platform for you accordingly.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  If we suspend or close your Account for any reason, we reserve the right to
                  require you to re-complete the procedures outlined at Section 6 before permitting
                  you to transfer or withdraw your Assets.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>SECURITY</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  To access VOXTO Services, you will be required to create an Account with VOXTO.
                  You are solely responsible for keeping confidential and safeguarding your Account
                  details. You must not disclose your login credentials and / or security
                  credentials for your Account to any person. You shall be solely responsible for
                  any and all consequences of use or misuse of your Account, login credentials, and
                  / or security credentials arising from your failure to protect your login and / or
                  security credentials.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You are responsible for keeping the device(s) through which you access VOXTO
                  Services safe and for maintaining adequate security controls over such device(s).
                  This includes taking all reasonable steps to avoid the loss, theft or misuse of
                  such device(s) and ensuring that such device(s) has adequate security controls
                  (e.g. encrypted and password protected).
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  If you suspect that your Account or any of your security details have been
                  compromised, or if you become aware of any fraud or attempted fraud or any other
                  security incident on your device or your Account which affects you and / or VOXTO,
                  you should notify us as soon as possible.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  VOXTO is not liable for any damage or interruption caused by any malware, virus,
                  bug or other malicious code that may affect your devices or other equipment, any
                  phishing, any cyber-hacking and spoofing or other attack by third parties, or for
                  any losses which you or any other party may suffer arising from your Account being
                  compromised and / or theft of your login or security credentials.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>LINKS TO OTHER WEBSITES</li>
            </Heading>
            <Text>
              Our Platform may contain links to third-party websites or services that are not owned
              or controlled by us. VOXTO has no control over, and assumes no responsibility for, the
              content, privacy policies, or practices of any third-party websites or services.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>SERVICE AVAILABILITY</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  We will do all we can to ensure continuous operation of our Platform and the VOXTO
                  Services. However, access to our Platform and / or the VOXTO Services may become
                  degraded or unavailable during times of significant volatility or volume. This
                  could result in the inability to buy or sell for periods of time and may also lead
                  to support response time delays.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  We cannot guarantee that the VOXTO Website, the VOXTO Services or your Account
                  will be available without any interruption or that the Platform and / or the VOXTO
                  Services will not experience any delays, failures, or errors whatsoever. We do not
                  guarantee that any order will be executed, accepted, recorded, or remain open.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You agree and acknowledge that VOXTO shall not be liable for any losses suffered
                  resulting from or arising out of transaction delays.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>PLATFORM ACCURACY</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  While we will do our best to ensure all information on the Platform is accurate
                  and up-to-date, the Platform (and all content therein) may not always be entirely
                  accurate, complete or current and may also include technical inaccuracies or
                  typographical errors.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  In an effort to continue to provide you with the most complete and accurate
                  information, information may, to the extent permitted by Applicable Laws, be
                  changed or updated from time to time without notice. Accordingly, you should
                  verify all information before relying on it, and all decisions based on
                  information contained on the Platform are your sole responsibility and we shall
                  have no liability for such decisions.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  Links to third party materials (including without limitation any websites) may be
                  provided as a convenience but are not controlled by us. You acknowledge and agree
                  that we are not responsible for any aspect of the information, content, or
                  services contained in any such third party materials accessible or linked to from
                  the VOXTO Website.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>LIABILITY</li>
            </Heading>
            <ol>
              <li>
                <Text>Disclaimer of Warranties</Text>
                <Box p={1} />
                <Text>
                  Your Account, the Platform and the VOXTO Services and any related products or
                  services are offered on a strictly "as-is" and "where-available" basis and VOXTO
                  expressly disclaims, and you waive, all warranties of any kind, whether express or
                  implied. Without limiting the generality of the foregoing, your Account, the VOXTO
                  Website, the VOXTO Services and any related products or services are offered
                  without any warranty as to merchantability or fitness for any particular purpose.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>Limitation of Liability</Text>
                <Box p={1} />
              </li>
              <ol>
                <li>
                  <Text>
                    VOXTO shall be liable to you only for any loss or damage which you may suffer as
                    a result of being a VOXTO member or using the platform to the extent that such
                    loss or damage directly arises from our material breach of this Agreement or was
                    the direct result of willful default or fraud by us.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    Notwithstanding the foregoing, we shall not be liable to you for any loss or
                    damage in respect of any matter for which liability is expressly excluded under
                    this Agreement, or arising out of or in connection with any error or inaccuracy
                    in the data entered by you or any misrepresentation or willful misconduct or any
                    other act by VOXTO. We shall not be liable to you for any indirect,
                    consequential, special or punitive loss, damage, cost or expense, unforeseeable
                    losses or damages, loss of profit, loss of business, lost or wasted management
                    time or time of other employees, loss of reputation, depletion of goodwill or
                    loss, damage or corruption of data.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    We shall not be liable to you for investment losses after Investment. Our
                    liability to you for any loss or damage arising in connection with your
                    Investment in our business shall be limited to no more than the amount you
                    invested in such business through the Platform (without regard to any subsequent
                    appreciation in the value of the shares purchased with that Investment). You
                    agree that any legal action against us is permitted only on an individual basis,
                    and that you will not initiate or join any purported or actual class or
                    consolidated actions against us.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    Nothing in this Agreement shall limit our liability for personal injury or
                    death, fraud or any other liability the exclusion or limitation of which is not
                    permitted by Applicable Laws or regulation.
                  </Text>
                  <Box p={1} />
                </li>
              </ol>
              <li>
                <Text>Indemnity</Text>
                <Box p={1} />
                <Text>
                  To the maximum extent permitted by law, you agree to indemnify VOXTO, its
                  operating entities or any other affiliates and each of our, or their respective
                  directors, members, employees and / or agents) against any action, liability,
                  cost, claim, loss, damage, proceeding or expense (including legal fees and any
                  fines, fees or penalties imposed by any regulatory authority) suffered or incurred
                  directly or indirectly arising from your use of or conduct in relation to the
                  Platform, or from your violation of the terms of this Agreement (unless they arise
                  solely from our fraud, gross negligence or wilful default). This includes the
                  incorrect information provided by you to VOXTO; or the non-compliance with the
                  terms of this Agreement by you; or the use of the Platform or the Website for any
                  illegal activity by you, including but not limited to money laundering and the
                  financing of terrorism; or any unauthorized third-party activities, including
                  without limitation the use or introduction of computer viruses, or other malware,
                  hacking, phishing, spoofing or other means of attack against you.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>GOVERNING LAW AND DISPUTE RESOLUTION</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  This Agreement shall be governed by, and construed in accordance with, the laws of
                  Samoa.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>SUBMISSION OF FEEDBACK, QUERIES OR COMPLAINTS</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  If you wish to contact us with any feedback, questions, or complaints, please send
                  your enquiry to support@voxto.com.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  When you contact us, please provide us with your full name, email address, and any
                  other information we may need to identify you, your Account, and where applicable,
                  the relevant transaction in respect of which your feedback, question, or complaint
                  relates.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>PRIVACY</li>
            </Heading>
            <ol>
              <li>
                <Text>Your Privacy</Text>
                <Box p={1} />
                <Text>
                  In the course of our relationship with you and to provide you with the VOXTO
                  Services, we will collect and process your personal data in accordance with the
                  terms of our Privacy Policy. By entering into this Agreement, you here by
                  expressly agree to VOXTO’s processing of your personal data as described in our
                  Privacy Policy which can be found at support@voxto.com.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>Privacy of Other Users</Text>
                <Box p={1} />
              </li>
              <ol>
                <li>
                  <Text>
                    In the event you receive information about any other individual when using the
                    Platform or in the course of your access to and / or participation to and / or
                    engagement with the VOXTO Services, you are strictly required to keep such
                    information private and confidential.
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    Save where you have received the relevant individuals’ express consent, you are
                    strictly prohibited from: -
                  </Text>
                  <Box p={1} />
                </li>
                <ol>
                  <li>
                    <Text>
                      Using information relating to another individual except for such purposes and
                      in such manner reasonably necessary for you to carry out a transaction and
                      other functions reasonably incidental thereto; and
                    </Text>
                    <Box p={1} />
                  </li>
                  <li>
                    <Text>
                      Disclosing or distributing information relating to any other individual which
                      you have obtained from your use of the Platform or the VOXTO Services to any
                      third party.
                    </Text>
                    <Box p={1} />
                  </li>
                </ol>
                <li>
                  <Text>
                    You shall not send unsolicited communications to any other user of the Platform
                    or the VOXTO Services.
                  </Text>
                  <Box p={1} />
                </li>
              </ol>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>LICENSE LIMITATION</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  We grant you a limited, non-exclusive, non-transferable license, subject to the
                  terms of this Agreement, to access and use the Platform and related content,
                  materials, information (collectively, the "Content"). The license granted is
                  solely for your use of the Platform, VOXTO Website and VOXTO Services in
                  accordance with the terms of this Agreement, and for any other purposes as
                  permitted by us from time to time.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  Any other use of the Platform or Content is expressly prohibited and all other
                  right, title, and interest in the Platform or Content is exclusively the property
                  of VOXTO and its licensors. You agree not to copy, transmit, distribute, sell,
                  license, reverse engineer, modify, publish, or participate in the transfer or sale
                  of, create derivative works from, or in any other way exploit any of the Content,
                  in whole or in part.
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>TAXATION</li>
            </Heading>
            <Text>
              You are solely responsible for determining whether, and to what extent, any taxes
              apply to any transactions you conduct through the VOXTO Services, and to withhold,
              collect, report and remit the correct amount of tax to the appropriate tax
              authorities. Your transaction history is available through your Account.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>SANCTIONS & EXPORTS CONTROL</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  Your participation to and / or engagement with the VOXTO Services and the Platform
                  is subject to international export controls and economic sanctions requirements.
                  By undertaking any activity on the Platform or through your Account, you agree
                  that you will comply with those requirements.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  You are not permitted to acquire Digital Assets or participate to and / or engage
                  with any of the VOXTO Services through the Platform if: -
                </Text>
                <Box p={1} />
              </li>
              <ol>
                <li>
                  <Text>
                    You are in, under the control of, or a national or resident of any country
                    subject to United States embargo, United Nations sanctions or the UK’s HM
                    Treasury's financial sanctions regimes (each a "Sanctioned Country"), or if you
                    are a person on the U.S. Treasury Department's Specially Designated Nationals
                    List or the U.S. Commerce Department's Denied Persons List, Unverified List,
                    Entity List, or the UK’s HM Treasury's financial sanctions regime (a "Sanctioned
                    Person"); or
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    You intend to supply any acquired or stored Digital Assets or VOXTO Services to
                    a Sanctioned Country (or a national or resident of a Sanctioned Country) or
                    Sanctioned Person.
                  </Text>
                  <Box p={1} />
                </li>
              </ol>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>UNCLAIMED PROPERTY</li>
            </Heading>
            <ol>
              <li>
                <Text>
                  29.1.In the event that you have not accessed your Account through the Platform or
                  otherwise undertaken any activity in relation to your Account (such as transfer-in
                  of funds) for two (2) years, the Account will be deemed dormant and de-activated.
                  Re-activation is required for the Account to resume activity.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  If you wish to re-activate the Account, you agree to provide us with such
                  information as we may require to authenticate your identity. Otherwise, we may
                  terminate your Account in accordance with this Agreement.
                </Text>
                <Box p={1} />
              </li>
              <li>
                <Text>
                  If we determine in good faith that we are still unable to trace you in the two (2)
                  years following dormancy, the Account will be terminated and you agree that all
                  Assets then standing to the credit of any Account or otherwise held by us or our
                  affiliate (as the case may be) together with any property as may from time to time
                  continue to accrue to those monies and property (whether by way of dividends,
                  interest or otherwise) may forthwith be liquidated by us and the proceeds of the
                  liquidation (after setting off any applicable administrative costs) shall be held
                  by us for your benefit for a period of one (1) year. If any of the proceeds
                  payable to you remain unclaimed by you after a period of one (1) year from the
                  date on which it became payable, we shall surrender such unclaimed amount to the
                  Registrar of Samoa Public Finance Management Act 2001 (Part 10, Unclaimed Money).
                  You thereafter shall have no right whatsoever to claim such proceeds from us, you
                  deemed to have waived and abandoned all your rights to such proceeds (and any
                  other property as may accrue to it).
                </Text>
                <Box p={1} />
              </li>
            </ol>
            <Box p={3} />
            <Heading as="h3">
              <li>AMENDMENTS</li>
            </Heading>
            <Text>
              We may make all other amendments to the Agreement (including any other VOXTO Services
              and those relating to it) by posting the revised Agreement on the Platform or by
              emailing it to you, indicating when the revised Agreement becomes effective. Although
              we will endeavor to provide you with advance notice where possible, where lawful we
              may indicate that the revised Agreement shall be effective immediately and if you do
              not agree with any such modification, you should close your Account and cease using
              the VOXTO Services. Copies of the most up-to-date version of the Agreement will be
              made available in the Platform at all times and will be provided to you by email on
              your request.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>RELATIONSHIP OF PARTIES</li>
            </Heading>
            <Text>
              Nothing in this Agreement shall be deemed or is intended to be deemed, nor shall it
              cause, either you or VOXTO to be treated as partners or joint venture partners, or
              either you or VOXTO to be treated as the agent of the other.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>CONTACT INFORMATION</li>
            </Heading>
            <Text>
              You are responsible for keeping your email address and telephone number up to date in
              your Account file in order to receive any notices or alerts that we may send you.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>ENTIRE AGREEMENT</li>
            </Heading>
            <Text>
              This Agreement (including documents incorporated by reference herein) comprise the
              entire understanding and agreement between you and VOXTO as to the subject matter
              hereof, and it supersedes any and all prior discussions, agreements, and
              understandings of any kind (including without limitation any prior versions of this
              Agreement), and every nature between and among you and VOXTO.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>TRANSFER AND ASSIGNMENT</li>
            </Heading>
            <Text>
              You shall not have the right to assign any of such rights, undertakings, agreements,
              duties, liabilities and / or obligations hereunder, except with our written consent.
              We may assign or transfer any of our rights hereunder to any party without your
              consent, but subject to prior notification.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>CHANGE OF CONTROL</li>
            </Heading>
            <Text>
              In the event that VOXTO is acquired by or merged with a third party, we reserve the
              right to transfer or assign the information we have collected from you as part of such
              merger, acquisition, sale, or other change of control.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>FORCE MAJEURE</li>
            </Heading>
            <Text>
              VOXTO shall not be liable for any delays, failure in performance or interruption of
              service which result directly or indirectly from any cause or condition beyond our
              reasonable control, including but not limited to, any delay or failure due to any act
              of God, act of civil or military authorities, pandemics and epidemics, lockdowns, act
              of terrorists, civil disturbance, war, strike or other labour dispute, fire,
              interruption in telecommunications or Internet services or network provider services,
              failure of equipment and / or software, other catastrophe or any other occurrence
              which is beyond our reasonable control.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>SECURITY INTEREST</li>
            </Heading>
            <Text>
              You shall not create an Encumbrance over your Digital Asset except with a written
              consent from VOXTO.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>INVALIDITY</li>
            </Heading>
            <Text>
              If any provision of this Agreement or part thereof is rendered void, illegal or
              unenforceable by any legislation to which it is subject, it shall be rendered void,
              illegal or unenforceable to that extent and no further and, for the avoidance of
              doubt, the rest of this Agreement shall continue in full force and effect and the
              legality, validity and enforceability of the whole of this Agreement in any other
              jurisdiction shall not be affected.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>ENFORCEMENT OF VOXTO’S RIGHTS</li>
            </Heading>
            <Text>
              No failure or delay on our part in exercising any power of sale or any other rights or
              options hereunder and no notice or demand which may be given to or made upon you by us
              with respect to any power of sale or other right or option hereunder, shall constitute
              a waiver thereof, or limit or impair our right to take any action or to exercise any
              power of sale or any other rights or options hereunder without notice or demand, or
              prejudice our rights as against you in any respect or render us responsible for any
              loss arising there from. A single or partial exercise of a right, power or remedy does
              not prevent another or further exercise of that or another right, power or remedy. A
              waiver of a right, power or remedy must be in writing and signed by the party giving
              the waiver. We may grant time or other indulgence to you or any other person, without
              impairing or affecting in any way any of our rights as against you or any such other
              persons.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>USE OF LANGUAGE</li>
            </Heading>
            <Text>
              The language of this Agreement and any information or notifications are or will be
              provided to you in English. Any translation of this Agreement or other documents is
              provided for your convenience only and may not accurately represent the information in
              the original language version. In the event of any inconsistency, the English language
              version of this Agreement or other documents shall prevail.
            </Text>
            <Box p={3} />
            <Heading as="h3">
              <li>SURVIVAL</li>
            </Heading>
            <Text>
              All provisions of this Agreement which by their nature extend beyond the expiration or
              termination of this Agreement, including, without limitation, the sections relating to
              suspension or termination, Account cancellation, debts owed to VOXTO, general use of
              the VOXTO Website, disputes with VOXTO, and general provisions will continue to be
              binding and operate after the termination or expiration of this Agreement. All
              disclaimers, indemnities and exclusions in this Agreement shall survive the
              termination of this Agreement.
            </Text>
          </ol>
          <Divider />
          <Flex>
            <Label>
              <Checkbox defaultChecked={false} />
              <Box>
                <Text>
                  BY TICKING THIS BOX, all terms and conditions stated herein are fully understood
                  by you and you hereby agree that by signing up to our VOXTO Website and / or
                  Platform, you accept the above stated provisions within your own full capacity in
                  the acceptance of the entirety of this Agreement.
                </Text>
              </Box>
            </Label>
          </Flex>
        </Box>
      </Card>
    </Box>
  )
}

export default UserAgreementPage
