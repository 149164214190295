import client, { IDataResponse } from '../request'

export type Symbol = {
  id: string | number
  name: string
  code: string
  blocked: boolean
  exchange_rate?: number
  decimal?: number
  created_at: string
  updated_at: string
}

// useSWR, return strings
const getAll = '/symbols'

// useSWR, return strings
const get = (id: string | number) => {
  return `/symbols/${id}`
}

// axios
const create = (data: Symbol) => {
  return client.post('/symbols', data)
}

// axios
const update = (id: string | number, data: Symbol) => {
  return client.put(`/symbols/${id}`, data)
}

// processing
const toRow = (data: IDataResponse<Symbol>): Symbol[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<Symbol>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id: string | number) => {
  return client.delete(`/symbols/${id}`)
}

const SymbolService = {
  getAll,
  get,
  create,
  update,
  toPaginate,
  toRow,
  remove
}

export default SymbolService
