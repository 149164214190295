/** @jsxImportSource theme-ui */
import { Box, Button, Flex, Grid, Heading, Text } from 'theme-ui'
import { BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import _ from 'lodash'

import { MyNFTCard } from 'components/NFTs'
import MoreInfo from 'components/more-info'

import NFTService, { NFT } from 'network/services/nft'
import { IDataResponse, serialize } from 'network/request'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'

const MyNFtsPage = () => {
  const navigate = useNavigate()

  return (
    <Box>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Heading>NFT Assets</Heading>
        <Button
          variant="primaryFlexCenter"
          sx={{ justifyContent: 'space-between' }}
          onClick={() => navigate('withdraw')}
        >
          <Text>Withdraw</Text>
          <BsArrowRight />
        </Button>
      </Flex>

      <Box p={4} />

      <Box>
        <Flex>
          <MoreInfo title="Avatar">
            <Box variant="layout.borderedTextCenter" bg="dark" p={2} sx={{ width: 'max-content' }}>
              <Text variant="small" color="textMuted">
                Can be linked with any asset pool
              </Text>
            </Box>
          </MoreInfo>
        </Flex>

        <Box p={2} />

        <Avatars />
      </Box>

      <Box p={4} />

      <Box>
        <Flex>
          <MoreInfo title="Potion">
            <Box variant="layout.borderedTextCenter" bg="dark" p={2} sx={{ width: 'max-content' }}>
              <Text variant="small" color="textMuted">
                Can be linked with asset pool only
              </Text>
            </Box>
          </MoreInfo>
        </Flex>

        <Box p={2} />

        <Potions />
      </Box>
    </Box>
  )
}

const Avatars = () => {
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, {
      status: 'active'
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve avatars" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const myNFTs = NFTService.toRow(data)
  const avatars = myNFTs.filter((item) => item.type === 'avatar')

  if (_.isEmpty(avatars)) {
    return <Text>You do not own any avatars at the moment</Text>
  }

  return (
    <Grid columns={[1, 3, 4]} gap={8}>
      {avatars.map((item) => (
        <MyNFTCard key={item.id} nft={item} />
      ))}
    </Grid>
  )
}

const Potions = () => {
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, {
      status: 'active'
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve potions" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const myNFTs = NFTService.toRow(data)
  const potions = myNFTs.filter((item) => item.type === 'potion')

  if (_.isEmpty(potions)) {
    return <Text>You do not own any potions at the moment</Text>
  }

  return (
    <Grid columns={[1, 3, 4]} gap={8}>
      {potions.map((item) => (
        <MyNFTCard key={item.id} nft={item} />
      ))}
    </Grid>
  )
}

export default MyNFtsPage
