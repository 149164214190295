import { FC, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, Flex, Grid, Image, Text } from 'theme-ui'

import { useResolution } from 'hooks/useResolution'
import { Verification } from 'network/services/verification'

const IdentityVerification: FC<{
  initialData: Verification | undefined
  setPageSelected: React.Dispatch<React.SetStateAction<string>>
}> = ({ initialData = null, setPageSelected }) => {
  const navigate = useNavigate()
  const { isMobile } = useResolution()
  const [type, setType] = useState<string>(initialData?.id_type ?? 'ic')

  const hasExistingVerification = initialData?.completed ?? false

  if (!initialData) {
    return (
      <>
        <Box>
          <Text variant="extraLarge">
            Please submit your personal info before proceeding in this section.
          </Text>

          <Box p={2} />

          <Button onClick={() => setPageSelected('personal_info')}>Okay</Button>
        </Box>
      </>
    )
  }

  console.log(hasExistingVerification)

  return (
    <>
      <Box>
        <Text variant="extraLarge">
          To verify your identity, please select one of these document to proceed.
        </Text>
      </Box>

      <Box p={2} />

      <Box>
        <Grid columns={[1, 2]} sx={{ '>*': { height: [200, 'auto'] } }} gap={5}>
          <CardView
            onClick={() => {
              if (initialData?.completed) {
                return
              }

              setType('ic')
            }}
            isActive={initialData?.id_type === 'ic' ? true : type === 'ic'}
            disabled={hasExistingVerification}
          >
            <Image src="/assets/svg/identity-card.svg" />
            <Text pt={5} variant="large">
              Identity Card
            </Text>
            {initialData?.id_type === 'ic' && hasExistingVerification && (
              <Text pt={2} variant="medium">
                Your verification is {initialData?.status}
              </Text>
            )}
          </CardView>
          <CardView
            onClick={() => {
              if (initialData?.completed) {
                return
              }

              setType('passport')
            }}
            isActive={initialData?.id_type === 'passport' ? true : type === 'passport'}
            disabled={hasExistingVerification}
          >
            <Image src="/assets/svg/passport.svg" />
            <Text pt={5} variant="large">
              Passport
            </Text>
            {initialData?.id_type === 'passport' && hasExistingVerification && (
              <Text pt={2} variant="medium">
                Your verification is {initialData?.status}
              </Text>
            )}
          </CardView>
        </Grid>
      </Box>
      <Box p={2} />
      {!initialData?.completed && (
        <Flex variant={isMobile ? 'layout.flexCenterCenter' : 'layout.flexCenterEnd'}>
          <Button onClick={() => navigate(type)}>Next</Button>
        </Flex>
      )}
    </>
  )
}

const CardView: FC<{
  onClick: () => void
  isActive: boolean
  disabled: boolean
  children?: ReactNode
}> = ({ onClick, isActive, disabled, children }) => {
  return (
    <Card
      variant={isActive ? 'flexCenterSecondaryCardOutlined' : 'flexCenterSecondaryCard'}
      sx={{ flexDirection: 'column', p: '10%', opacity: disabled && !isActive ? '0.5' : '1' }}
      onClick={onClick}
    >
      <Flex variant="layout.flexCenterCenter" sx={{ flexDirection: 'column' }}>
        {children}
      </Flex>
    </Card>
  )
}

export default IdentityVerification
