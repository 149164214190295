import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Heading, Text, Flex, Image, Button } from 'theme-ui'
import { proxy, useSnapshot } from 'valtio'
import useSWR, { mutate } from 'swr'

import { ExampleModal, UploadCard } from 'components/IdentityVerification'
import MobileReturnHeader from 'components/MobileReturnHeader'
import StatusModal from 'components/status-modal'
import { useResolution } from 'hooks/useResolution'
import VerificationService, { Verification } from 'network/services/verification'
import ProofOfAddress from './proof-of-address'

const passportVerificationStore = proxy({
  step1Completed: false,
  showExample: false,
  modalMessage: {
    isOpen: false,
    success: true,
    message: ''
  }
})

const PassportVerification = () => {
  const { isMobile } = useResolution()
  const navigate = useNavigate()
  const { step1Completed, showExample, modalMessage } = useSnapshot(passportVerificationStore)

  return (
    <Box variant="layout.pageContainer">
      {isMobile && (
        <MobileReturnHeader title="Identity Verification" subtitle="Verified" delta={-1} />
      )}
      <Box>
        <Heading>Identity Verification</Heading>
      </Box>
      <Box p={1} />
      <Box>
        <Text>
          {step1Completed ? 'Step 2 of 2 - Proof of address' : 'Step 1 of 2 - Upload passport'}
        </Text>
      </Box>

      <Box p={4} />

      <FormView />

      <StatusModal
        isOpen={modalMessage.isOpen}
        onRequestClose={() => {
          passportVerificationStore.modalMessage.isOpen = false
          if (modalMessage.success) {
            navigate('/identity-verification')
          }
        }}
        success={modalMessage.success}
        children={modalMessage.message}
      />

      <ExampleModal
        isOpen={showExample}
        onRequestClose={() => (passportVerificationStore.showExample = false)}
      />
    </Box>
  )
}

const FormView = () => {
  const { data } = useSWR<{ data: Verification }>(VerificationService.getPending)
  const verificationData = data?.data

  if (!data) {
    return <></>
  }

  return <PassportVerificationForm initialData={verificationData} />
}

const PassportVerificationForm: FC<{ initialData?: Verification }> = ({ initialData }) => {
  const methods = useForm<Verification>({
    shouldUseNativeValidation: false,
    defaultValues: {
      ...initialData,
      id_type: 'passport'
    }
  })
  const navigate = useNavigate()
  const { isMobile } = useResolution()
  const { step1Completed } = useSnapshot(passportVerificationStore)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (initialData == null) return
      const { data: result } = await VerificationService.update(initialData.id, {
        ...data,
        completed: true
      })
      if (result.success) {
        passportVerificationStore.modalMessage = {
          isOpen: true,
          success: true,
          message: 'Your KYC is submmited and pending for approval'
        }
      }

      mutate(VerificationService.getPending)
    } catch (e: any) {
      passportVerificationStore.modalMessage = {
        isOpen: true,
        success: true,
        message: e.message ?? ''
      }
    }
  })

  return (
    <FormProvider {...methods}>
      <Box id="verification-form" as="form" onSubmit={onSubmit}>
        {/* use display for hide/show to prevent state loss */}
        <Box sx={{ display: step1Completed ? 'none' : 'block' }}>
          <Box>
            <Box
              variant="layout.coloredContainer"
              sx={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Text>Tips</Text>
            </Box>

            <Box p={2} />

            <Box>
              <Text>• Make sure all details are clear</Text>
            </Box>

            <Box p={2} />

            <Box>
              <Text>• Full name entered must match with Passport document</Text>
            </Box>

            <Box p={5} />

            <Box>
              <Text variant="large">Part 1: Upload your passport</Text>
            </Box>

            <Box p={2} />

            <UploadCard<Verification>
              image={<Image src="/assets/svg/Group 1946.svg" width={50} height={50} />}
              description=" Upload your passport in details"
              rules={{
                required: 'Please upload the front of your passport'
              }}
              setValue={setValue}
              register={register}
              name="id_front"
              errors={errors}
            />

            <Box p={4} />

            <Box variant={isMobile ? 'default' : 'layout.flexCenterStart'}>
              <Box sx={{ mr: 5 }}>
                <Text variant="large">Part 2: Upload your selfie with verification passport</Text>
              </Box>
              <Button
                type="button"
                onClick={() => {
                  passportVerificationStore.showExample = true
                }}
              >
                <Text>Example</Text>
              </Button>
            </Box>

            <Box p={4} />

            <UploadCard<Verification>
              image={<Image src="/assets/svg/selfies.svg" width={50} height={50} />}
              description="Selfie with verification identity card"
              rules={{
                required: 'Please upload your selfie'
              }}
              setValue={setValue}
              register={register}
              name="selfie"
              errors={errors}
            />

            <Box p={5} />

            <Flex variant={isMobile ? 'layout.flexCenterCenter' : 'layout.flexCenterEnd'}>
              <Button
                type="button"
                sx={{ mr: 5, width: ['100%', 'auto'] }}
                variant="secondary"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Button
                type="button"
                sx={{ width: ['100%', 'auto'] }}
                onClick={async () => {
                  const triggers = await Promise.all([
                    methods.trigger('id_front'),
                    methods.trigger('selfie')
                  ])

                  if (triggers.includes(false)) {
                    return
                  }

                  passportVerificationStore.step1Completed = true
                }}
              >
                Next
              </Button>
            </Flex>
          </Box>
        </Box>

        {/* use display for hide/show to prevent state loss */}
        <Box sx={{ display: !step1Completed ? 'none' : 'block' }}>
          <ProofOfAddress
            onBack={() => {
              passportVerificationStore.step1Completed = false
            }}
          />
        </Box>
      </Box>
    </FormProvider>
  )
}

export default PassportVerification
