import { Box, Card, Flex, Heading, Image, Text, Button, Grid } from 'theme-ui'
import useSWR from 'swr'
import { useMemo, useState } from 'react'

import { getProfile } from 'store/auth'
import { useResolution } from 'hooks/useResolution'
import RewardService, { Reward } from 'network/services/rewards'
import { IDataResponse, serialize } from 'network/request'
import AuthService, { User } from 'network/services/auth'
import Table from 'components/Table'
import FormInput from 'components/form-input'
import StatusModal from 'components/status-modal'
import { CardItems, OverviewCard } from 'components/GiftReward'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'

const GiftRewardsPage = () => {
  return (
    <Box variant="layout.pageContainer">
      <Grid gap={3}>
        <InviteFriend />
        <HowItWorks />
        <Overview />
        <MyRewards />
        <MyReferrals />
      </Grid>
    </Box>
  )
}

const InviteFriend = () => {
  const { isMobile } = useResolution()

  return (
    <Box>
      <Card
        sx={{
          px: [4, 10],
          py: 10,
          borderRadius: 20,
          display: ['block', 'flex'],
          width: '100%'
        }}
      >
        <Image
          src="/assets/images/invite@2x.png"
          sx={{
            objectFit: 'scale-down'
          }}
        />
        <Box p={[4, 0]} />
        <Box sx={{ display: 'flex', flexDirection: 'column', pl: [0, 8] }}>
          <Box sx={{ textAlign: ['center', 'left'] }}>
            <Heading variant={isMobile ? 'styles.h6' : 'styles.h2'}>
              Invite friends and earn VXT
            </Heading>
            <Box p={2} />
            <Box sx={{ lineHeight: ['10px', 'default'] }}>
              <Text variant="mediumSmall">
                For every friend who stake $10,000 or more, we'll reward both of you with $25 worth
                of VTX
              </Text>
            </Box>
          </Box>
          <Box p={2} />
          <InviteForm />
        </Box>
      </Card>
    </Box>
  )
}

const InviteForm = () => {
  const profile = getProfile()
  const referralURL = useMemo(
    () => `${window.location.origin}/sign-up?referral=${profile?.referral_code}`,
    [profile]
  )
  const [showCopyModal, setShowCopyModal] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(referralURL)
    setShowCopyModal(true)
  }

  return (
    <>
      <Flex variant="layout.flexCenterCenter" sx={{ flexDirection: 'column' }}>
        <FormInput
          name="email"
          inputSX={{ py: 0 }}
          containerSX={{ borderRadius: 30, pl: 6, pr: 0, width: '100%' }}
          placeholder={`${referralURL.slice(0, 35)}${referralURL.length > 35 ? '...' : ''}`}
          value={referralURL}
          readOnly={true}
          trailing={
            <Button
              sx={{ py: [4, 5], borderRadius: 30, minWidth: [100, null] }}
              onClick={handleCopy}
            >
              <Text variant="mediumSmall">Copy Link</Text>
            </Button>
          }
        />
      </Flex>

      <StatusModal
        isOpen={showCopyModal}
        onRequestClose={() => {
          setShowCopyModal(false)
        }}
        children="Referral link copied successfully"
        success={true}
      />
    </>
  )
}

const HowItWorks = () => {
  return (
    <Box>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
          p: 10,
          borderRadius: 20
        }}
      >
        <Heading>How it Works?</Heading>
        <Box p={4} />
        <Grid
          columns={[1, 3]}
          gap={10}
          sx={{ justifyContent: 'center', alignItems: 'center', '>*': { height: 200 } }}
        >
          <CardItems
            icon={'/assets/svg/people.svg'}
            heading="1. Share Link"
            content="Share your referral ID or link with friends"
          />
          <CardItems
            icon={'/assets/svg/wallet.svg'}
            heading={`2. Register & Stake`}
            content="Invite friends to register and stake $10,000 or more"
          />
          <CardItems
            icon={'/assets/svg/Group 1616.svg'}
            heading="3. Earn VXT"
            content="Receive $25 worth of VTX as reward "
          />
        </Grid>
      </Card>
    </Box>
  )
}

const Overview = () => {
  const { data, error, mutate } = useSWR<{
    total: number
    count: number
    success_count: number
  }>(RewardService.getRewardsStats)

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve overview data" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  return (
    <Box>
      <Heading>Overview</Heading>
      <Box p={3} />
      <Grid columns={[1, '1fr 2fr']}>
        <OverviewCard title="Total Referral Bonus (VXT)" value={data.total} />
        <Grid columns={2}>
          <OverviewCard title="Total Referral" value={data.count} />
          <OverviewCard title="Successful Referral" value={data.success_count} />
        </Grid>
      </Grid>
      <Box p={3} />
    </Box>
  )
}

const MyRewards = () => {
  const [page, setPage] = useState(1)
  const { data, error, mutate } = useSWR<IDataResponse<Reward>>(
    serialize(RewardService.getRewards, {
      page: page,
      limit: 10,
      sort: 'created_at:desc'
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve rewards data" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }
  const total = RewardService.toPaginate(data).total

  const rewardData = RewardService.toRow(data).map((item) => {
    return {
      id: item.id,
      distributed_at: item.created_at, // TODO
      reward: item.amount,
      status: 'Pending' // TODO
    }
  })

  return (
    <Box>
      <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: 'auto' }}>
        <Heading>My Rewards</Heading>
        {/* <Flex variant="layout.hStack" sx={{ background: 'dark', p: 5, borderRadius: 30 }}>
          <Box>
            <Text>2022-04-01</Text>
          </Box>
          <BsArrowRight />
          <Box>
            <Text>2022-04-01</Text>
          </Box>
          <Image src="/assets/svg/calendar.svg" />
        </Flex> */}
      </Flex>
      <Box p={3} />
      <Table
        headers={['ID', 'Distribution Date', 'Reward', 'Status']}
        data={rewardData}
        total={total}
        page={page}
        onPageChange={setPage}
      />
    </Box>
  )
}

const MyReferrals = () => {
  const filter = ['', 'completed', 'pending']
  const [index, setIndex] = useState<number>(0)
  const [page, setPage] = useState(1)
  const { data, error, mutate } = useSWR<IDataResponse<User>>(
    serialize(RewardService.getReferrals, {
      page: page,
      limit: 10,
      sort: 'created_at:desc',
      status: filter[index]
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve referrals data" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const total = RewardService.toPaginate(data).total

  const referralData = AuthService.toRow(data).map((item) => {
    return {
      id: item.id,
      registered: item.created_at,
      task_completed: 'No' // TODO: need a variable to determine invested 10,000
    }
  })

  return (
    <Box>
      <Heading>My Referrals</Heading>
      <Box p={1} />
      <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: 'auto' }}>
        <Flex variant="layout.hStack">
          <Button
            variant="secondary"
            onClick={() => setIndex(0)}
            sx={{
              width: [80, '100%'],
              minWidth: [50, 100],
              background: index === 0 && 'muted'
            }}
          >
            All
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIndex(1)}
            sx={{
              width: [80, '100%'],
              minWidth: [120, 150],
              background: index === 1 && 'muted',
              ml: [1, 2]
            }}
          >
            Successful
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIndex(2)}
            sx={{
              width: [80, '100%'],
              minWidth: [120, 150],
              background: index === 2 && 'muted',
              ml: [1, 2]
            }}
          >
            Pending
          </Button>
        </Flex>
        {/* <Flex variant="layout.hStack" sx={{ background: 'dark', p: 5, borderRadius: 30 }}>
          <Box>
            <Text>2022-04-01</Text>
          </Box>
          <BsArrowRight />
          <Box>
            <Text>2022-04-01</Text>
          </Box>
          <Image src="/assets/svg/calendar.svg" />
        </Flex> */}
      </Flex>
      <Box p={3} />
      <Table
        headers={['ID', 'Registered', 'Task Completed']}
        data={referralData}
        total={total}
        page={page}
        onPageChange={setPage}
      />
    </Box>
  )
}

export default GiftRewardsPage
