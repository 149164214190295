import { FC } from 'react'
import { Box, Heading, Text, Image, Flex } from 'theme-ui'

const CardItems: FC<{
  icon: string
  heading: string
  content: string
}> = ({ icon, heading, content }) => {
  return (
    <Box>
      <Box sx={{ height: '40%' }}>
        <Box variant="layout.cardIconContainer" sx={{ height: 60 }}>
          <Image src={icon} />
        </Box>
      </Box>
      <Flex variant="layout.flexCenterCenter" sx={{ height: '20%' }}>
        <Heading as="h3">{heading}</Heading>
      </Flex>
      <Box p={1} sx={{ height: '40%' }}>
        <Text>{content}</Text>
      </Box>
    </Box>
  )
}

export default CardItems
