import { FC } from 'react'
import { Box, Text, Heading, Flex } from 'theme-ui'

const DepositCard: FC<{
  num: string
  title: string
  content: string
}> = ({ num, title, content }) => {
  return (
    <Box>
      <Flex variant="layout.flexStartStart">
        <Box>
          <Box variant="layout.iconContainer">{num}</Box>
        </Box>
        <Box sx={{ ml: [2, 4] }}>
          <Heading as="h3">{title}</Heading>
          <Box>
            <Text>{content}</Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default DepositCard
