import { User } from 'network/services/auth'
import { proxy } from 'valtio'

interface LoginResponseState {
  token?: string
  profile?: User
}

// export interface UserProfile {
//   id: number | string
//   email: string
//   username: string
//   remember_me_token?: string
//   full_name: string
//   country_code?: string
//   referral_code?: string
//   phone?: string
//   provider?: string
//   confirmed: boolean
//   blocked: boolean
//   verified: boolean
//   phone_verified: boolean
//   last_login_at: string
//   created_at: string
//   updated_at: string
// }

export const authStore = proxy<LoginResponseState>({
  token: undefined,
  profile: undefined
})

export const checkAuthorization = async () => {
  const token = getToken()
  const profile = getProfile()

  if (token != null && profile != null) {
    // TODO: refresh token on entry
    // refresh myself
    // const me = await AuthService.findMyself()
    login({ token, profile })
    return true
  } else {
    console.log('not authenticated')
    return false
  }
}

export const login = ({ token, profile }: LoginResponseState) => {
  if (token) {
    authStore.token = token
    authStore.profile = profile

    localStorage.setItem('token', token)
    localStorage.setItem('profile', JSON.stringify(profile))
  } else {
    // login error
  }
}

export const logout = () => {
  clearToken()
  clearProfile()

  authStore.token = undefined
  authStore.profile = undefined
}

export function getToken(): string | null {
  try {
    const token = localStorage.getItem('token')
    return token
  } catch (err) {
    clearToken()
  }

  return null
}

export function getProfile(): User | null {
  try {
    const profile = localStorage.getItem('profile')
    if (profile) {
      return JSON.parse(profile)
    }
  } catch (err) {
    clearProfile()
  }

  return null
}

export function clearToken() {
  localStorage.removeItem('token')
}

export function clearProfile() {
  localStorage.removeItem('profile')
}
