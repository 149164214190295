/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import { Box, Grid, Heading, Text, Flex, Image, Button } from 'theme-ui'
import { proxy, useSnapshot } from 'valtio'

import { useResolution } from 'hooks/useResolution'
import VerificationService, { Verification } from 'network/services/verification'
import { ExampleModal, UploadCard } from 'components/IdentityVerification'
import MobileReturnHeader from 'components/MobileReturnHeader'
import StatusModal from 'components/status-modal'
import ProofOfAddress from './proof-of-address'

const idVerificationStore = proxy({
  step1Completed: false,
  showExample: false,
  modalMessage: {
    isOpen: false,
    success: true,
    message: ''
  }
})

const IDVerification = () => {
  const { isMobile } = useResolution()
  const navigate = useNavigate()
  const { step1Completed, showExample, modalMessage } = useSnapshot(idVerificationStore)

  return (
    <Box variant="layout.pageContainer">
      {isMobile && (
        <MobileReturnHeader title="Identity Verification" subtitle="Verified" delta={-1} />
      )}
      <Box>
        <Heading>Identity Verification</Heading>
      </Box>
      <Box p={1} />
      <Box>
        <Text>
          {step1Completed ? 'Step 2 of 2 - Proof of address' : 'Step 1 of 2 - Upload identity card'}{' '}
        </Text>
      </Box>

      <Box p={4} />

      <FormView />

      <StatusModal
        isOpen={modalMessage.isOpen}
        onRequestClose={() => {
          idVerificationStore.modalMessage.isOpen = false
          if (modalMessage.success) {
            navigate('/identity-verification')
          }
        }}
        success={modalMessage.success}
        children={modalMessage.message}
      />

      <ExampleModal
        isOpen={showExample}
        onRequestClose={() => (idVerificationStore.showExample = false)}
      />
    </Box>
  )
}

const FormView = () => {
  const { data } = useSWR<{ data: Verification }>(VerificationService.getPending)
  const verificationData = data?.data

  if (!data) {
    return <></>
  }

  return <IDVerificationForm initialData={verificationData} />
}

const IDVerificationForm: FC<{ initialData?: Verification }> = ({ initialData }) => {
  const methods = useForm<Verification>({
    shouldUseNativeValidation: false,
    defaultValues: {
      ...initialData,
      id_type: 'ic'
    }
  })
  const navigate = useNavigate()
  const { isMobile } = useResolution()
  const { step1Completed } = useSnapshot(idVerificationStore)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (initialData == null) return
      const { data: result } = await VerificationService.update(initialData.id, {
        ...data,
        completed: true
      })
      if (result.success) {
        idVerificationStore.modalMessage = {
          isOpen: true,
          success: true,
          message: 'Your KYC is submmited and pending for approval'
        }
      }

      mutate(VerificationService.getPending)
    } catch (e: any) {
      idVerificationStore.modalMessage = {
        isOpen: true,
        success: false,
        message: e.message ?? ''
      }
    }
  })

  return (
    <FormProvider {...methods}>
      <Box id="verification-form" as="form" onSubmit={onSubmit}>
        {/* use display for hide/show to prevent state loss */}
        <Box sx={{ display: step1Completed ? 'none' : 'block' }}>
          <Box
            variant="layout.coloredContainer"
            sx={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Text>Tips</Text>
          </Box>

          <Box p={2} />

          <Box>
            <Text>• Make sure all details are clear</Text>
          </Box>
          <Box p={2} />

          <Box>
            <Text>• Full name entered must match with identification document</Text>
          </Box>

          <Box p={2} />

          <Box>
            <Text>• Photo of the identity card must be visible and clear</Text>
          </Box>

          <Box p={4} />

          <Box>
            <Text variant="large">Part 1: Upload your identity card</Text>
          </Box>

          <Box p={2} />

          <Grid sx={{ '>*': { height: 350 } }} columns={[1, 2]} gap={5}>
            <UploadCard<Verification>
              image={<Image src="/assets/svg/name.svg" width={80} height={80} />}
              description="Upload front of identity card"
              rules={{
                required: 'Please upload the front of your identity card'
              }}
              setValue={setValue}
              register={register}
              name="id_front"
              errors={errors}
            />

            <UploadCard<Verification>
              image={<Image src="/assets/svg/name.svg" width={80} height={80} />}
              description="Upload back of identity card"
              rules={{
                required: 'Please upload the back of your identity card'
              }}
              setValue={setValue}
              register={register}
              name="id_back"
              errors={errors}
            />
          </Grid>

          <Box p={4} />

          <Box variant={isMobile ? 'default' : 'layout.flexCenterStart'}>
            <Box sx={{ mr: 5 }}>
              <Text variant="large">
                Part 2: Upload your selfie with verification identity card
              </Text>
            </Box>
            <Button
              type="button"
              onClick={() => {
                idVerificationStore.showExample = true
              }}
            >
              <Text>Example</Text>
            </Button>
          </Box>
          <Box p={4} />

          <UploadCard<Verification>
            image={<Image src="/assets/svg/selfies.svg" width={50} height={50} />}
            description="Selfie with verification identity card"
            rules={{
              required: 'Please upload your selfie'
            }}
            setValue={setValue}
            register={register}
            name="selfie"
            errors={errors}
          />

          <Box p={5} />

          <Flex variant={isMobile ? 'layout.flexCenterCenter' : 'layout.flexCenterEnd'}>
            <Button
              type="button"
              sx={{ mr: 5, width: ['100%', 'auto'] }}
              variant="secondary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              sx={{ width: ['100%', 'auto'] }}
              type="button"
              onClick={async () => {
                const triggers = await Promise.all([
                  methods.trigger('id_front'),
                  methods.trigger('id_back'),
                  methods.trigger('selfie')
                ])

                if (triggers.includes(false)) {
                  return
                }

                idVerificationStore.step1Completed = true
              }}
            >
              Next
            </Button>
          </Flex>
        </Box>

        {/* use display for hide/show to prevent state loss */}
        <Box sx={{ display: !step1Completed ? 'none' : 'block' }}>
          <ProofOfAddress
            onBack={() => {
              idVerificationStore.step1Completed = false
            }}
          />
        </Box>
      </Box>
    </FormProvider>
  )
}

export default IDVerification
