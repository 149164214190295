import { FC } from 'react'
import { Box, Image, Text, Flex, Button, AspectRatio } from 'theme-ui'

export interface TopVoxtoNFTCardProps {
  image: string
  name: string
  highestBid: number
  email: string
  index: number
}

const TopVoxtoNFTCard: FC<TopVoxtoNFTCardProps> = ({ image, name, highestBid, email, index }) => {
  return (
    <Flex variant="layout.flexCenterStart">
      <Box sx={{ mr: [3, 6] }}>
        <Text>{index}</Text>
      </Box>
      <Flex sx={{ height: [70, 80], width: '100%' }} variant="layout.flexCenterSpaceBetween">
        <Flex variant="layout.flexCenterStart">
          <Box sx={{ width: [70, 80] }}>
            <AspectRatio ratio={1 / 1}>
              <Image src={image} sx={{ objectFit: 'cover', borderRadius: 20 }} />
            </AspectRatio>
          </Box>

          <Box sx={{ ml: [2, 5], lineHeight: ['16px', 'default'] }}>
            <Box>
              <Text sx={{ fontWeight: 'bold' }} variant="mediumSmall">
                {name}
              </Text>
            </Box>
            <Box>
              <Text variant="small">Highest bid {highestBid} VXT</Text>
            </Box>
            <Box>
              <Text variant="small">
                <Text variant="small" color="textMuted">
                  By{' '}
                </Text>
                {email}
              </Text>
            </Box>
          </Box>
        </Flex>
        <Button variant="secondary" sx={{ minWidth: [0, 0], px: [0, 'auto'], py: 3, width: 90 }}>
          View
        </Button>
      </Flex>
    </Flex>
  )
}

export default TopVoxtoNFTCard
