import toUTCTime from 'modules/to-utc-time'
import client, { IDataResponse } from '../request'

export type RegisterState = {
  email: string
  username: string
  password: string
  password_confirmation: string
  auth_code: string
  referral_code?: string
  phone?: string
  full_name: string
  gender: string
  nationality: string
  birthdate: string
  has_agreed: boolean
  has_agreed_2: boolean
}

export type LoginState = {
  email: string
  password: string
  phone?: string
  location?: string
  ip_address?: string
}

export type ResetPassword = {
  password: string
  new_password: string
  new_password_confirmation: string
}

export type ForgotPassword = {
  email: string
  password: string
  password_confirmation: string
  auth_code: string
}

export type User = {
  id: number
  email: string
  username?: string
  fullName?: string
  address?: string
  city?: string
  zip?: string
  country?: string
  country_code?: string
  phone: string
  referral_code?: string
  verified: boolean //KYC verified
  phone_verified: boolean
  last_login_at: string
  created_at: string
}

const verifyUser = (data: Record<string, string>) => {
  return client.post('/users/available', data)
}

const register = (data: RegisterState) => {
  return client.post('/auth/register', data)
}

const login = (data: LoginState) => {
  return client.post('/auth/login', data)
}

const changePassword = (data: ResetPassword) => {
  return client.post('/auth/change-password', data)
}

const loginApple = (data: Record<string, string>) => {
  return client.post('/users/available', data)
}

const getAuth = (data: Record<string, string>) => {
  return client.post('/auth/code', data)
}

const verifyAuth = (data: Record<string, string>) => {
  return client.post('/auth/code/verify', data)
}

const logout = () => {
  return client.post('/auth/logout')
}

const forgotPassword = (data: ForgotPassword) => {
  return client.post('/auth/forgot-password', data)
}

const forgotPasswordGetAuth = (data: Record<string, string>) => {
  return client.post('/auth/forgot-password/code', data)
}

const findMyself = '/me'

const findMyselfAxios = () => {
  return client.get('/me')
}

const findBalance = '/me/summary'

// processing
const toRow = (data: IDataResponse<User>): User[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        createdAt: toUTCTime(element.created_at),
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<User>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const AuthService = {
  login,
  logout,
  register,
  verifyUser,
  getAuth,
  verifyAuth,
  forgotPassword,
  forgotPasswordGetAuth,
  loginApple,
  findMyself,
  findMyselfAxios,
  changePassword,
  findBalance,
  toRow,
  toPaginate
}

export default AuthService
