/** @jsxImportSource theme-ui */
import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Card, Button, Box, Heading, Text, IconButton } from 'theme-ui'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import { useInterval } from 'usehooks-ts'

import Link from 'components/link'
// import Tab from 'components/Tab'
import FormInput from 'components/form-input'
import FormCheckbox from 'components/form-checkbox'
import AuthService, { RegisterState } from 'network/services/auth'

import StatusModal from 'components/status-modal'
// import FormPhoneInput from 'components/form-phone-input'

const SignUpDetail = () => {
  const isEmail = useState<boolean>(true)
  const [showAuthField, setShowAuthField] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const [otpCooldown, setOtpCooldown] = useState<number>(30)
  const [otpSent, setOtpSent] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const params = searchParams.get('referral')
  const navigate = useNavigate()

  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })

  useInterval(
    () => {
      if (otpCooldown > 0) {
        setOtpCooldown(otpCooldown - 1)
        return
      }

      setOtpSent(false)
      setOtpCooldown(30)
    },
    otpSent ? 1000 : null
  )

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    clearErrors,
    setError,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm<RegisterState>({
    shouldUseNativeValidation: false
  })

  useEffect(() => {
    if (params !== null) {
      setValue('referral_code', params)
    }
  }, [params, setValue])

  const getAuthCode = async () => {
    const email = getValues('email')

    try {
      let result
      if (isEmail) {
        const { data } = await AuthService.getAuth({ email })
        result = data
      } else {
        // result = await AuthService.getAuth({ phone })
      }

      if (!result.success) {
        setMessage({
          isOpen: true,
          success: false,
          message: 'Unable to send OTP please try again later'
        })
        return
      }

      // ask for auth code
      setShowAuthField(true)
      setOtpSent(true)
    } catch (error: any) {
      console.error(error)
      setMessage({
        isOpen: true,
        success: false,
        message: error.message
      })
    }
  }

  const onSubmit = handleSubmit(async (data: RegisterState) => {
    try {
      const email = getValues('email')
      const code = getValues('auth_code')
      const { data: result } = await AuthService.verifyAuth({ email, code })
      if (result.success) {
        try {
          const { data: result } = await AuthService.register(data)
          if (result.success) {
            setMessage({
              isOpen: true,
              success: true,
              message: 'Account Register Succesfully'
            })
          }
        } catch (error: any) {
          setError('password_confirmation', {
            type: 'manual',
            message: ''
          })
          setMessage({
            isOpen: true,
            success: false,
            message: error.message
          })
        }
      }
    } catch (error: any) {
      setError('password_confirmation', {
        type: 'manual',
        message: ''
      })
      setMessage({
        isOpen: true,
        success: false,
        message: error.message
      })
    }
  })

  const closeRegister = () => {
    setMessage({ isOpen: false })
    message.success && navigate('/')
  }

  return (
    <Box
      variant="layout.flexCenterCenter"
      sx={{ minHeight: 'inherit', py: [5, 16], position: 'relative' }}
    >
      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        onRequestClose={() => closeRegister()}
        success={message.success}
      />

      <Card px={10} py={12}>
        <Box
          variant="layout.flexStartCenter"
          sx={{ minHeight: 'inherit', flexDirection: 'column', width: [270, null, 500] }}
        >
          <Heading as="h1">Register</Heading>
          <Box pt={4} />
          <Heading as="h3">Register with your email address</Heading>
          {/* <Heading as="h3">Register with your email address or phone number</Heading> */}

          <Box pt={10} />

          {/* <Box sx={{ width: '100%' }}>
            <Tab defaultTab={isEmail ? 0 : 1} tabs={['Email', 'Phone']} onTabChange={setIsEmail} />
          </Box>

          <Box pt={10} /> */}

          <Box as="form" sx={{ width: '100%' }} onSubmit={onSubmit}>
            {/* {isEmail ? (
              <FormInput<RegisterState>
                id="email"
                type="email"
                name="email"
                label="E-mail Address"
                register={register}
                rules={{
                  required: 'You must enter your email.',
                  pattern: {
                    value: new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$', 'ig'),
                    message: 'Enter a valid email address.'
                  }
                }}
                errors={errors}
                trailing={
                  <Button
                    type="button"
                    sx={{ p: 4, display: 'inline-block' }}
                    variant="text"
                    onClick={async (e) => {
                      e.preventDefault()

                      const validated = await trigger('email')
                      if (validated) {
                        await getAuthCode()
                      }
                    }}
                    disabled={!dirtyFields.email || otpSent}
                  >
                    {otpSent ? otpCooldown : 'Get OTP'}
                  </Button>
                }
              />
            ) : (
              <FormPhoneInput<RegisterState>
                id="phone"
                type="phone"
                name="phone"
                label="Phone Number"
                setValue={setValue}
                register={register}
                rules={{ required: 'You must enter your phone.' }}
                errors={errors}
              />
            )} */}

            <FormInput<RegisterState>
              id="email"
              type="email"
              name="email"
              label="E-mail Address"
              register={register}
              rules={{
                required: 'You must enter your email.',
                pattern: {
                  value: new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$', 'ig'),
                  message: 'Enter a valid email address.'
                }
              }}
              errors={errors}
              trailing={
                <Button
                  type="button"
                  sx={{ p: 4, display: 'inline-block' }}
                  variant="text"
                  onClick={async (e) => {
                    e.preventDefault()

                    const validated = await trigger('email')
                    if (validated) {
                      await getAuthCode()
                    }
                  }}
                  disabled={!dirtyFields.email || otpSent}
                >
                  {otpSent ? otpCooldown : 'Get OTP'}
                </Button>
              }
            />

            {showAuthField && (
              <>
                <Box pt={4} />
                <FormInput<RegisterState>
                  id="auth_code"
                  name="auth_code"
                  label="OTP"
                  register={register}
                  rules={{
                    required: 'You must enter the OTP.'
                  }}
                  errors={errors}
                />
              </>
            )}

            <Box pt={4} />

            <FormInput<RegisterState>
              id="password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password"
              register={register}
              rules={{
                required: 'You must enter your password.',
                pattern: {
                  value:
                    /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/,
                  message:
                    'The password must be at least 8 characters including at least a symbol, an uppercase letter and a number'
                }
              }}
              errors={errors}
              trailing={
                <IconButton px={4} type="button" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </IconButton>
              }
            />

            <Box pt={4} />

            <FormInput<RegisterState>
              id="password_confirmation"
              type={showConfirmPassword ? 'text' : 'password'}
              name="password_confirmation"
              label="Confirm Password"
              register={register}
              rules={{ required: 'You must enter your confirm password.' }}
              errors={errors}
              trailing={
                <IconButton
                  px={4}
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </IconButton>
              }
            />

            <Box pt={4} />

            <FormInput<RegisterState>
              id="referral_code"
              name="referral_code"
              label="Referral Code (Optional)"
              register={register}
              errors={errors}
            />

            <Box pt={10} />

            <FormCheckbox<RegisterState>
              id="has_agreed"
              name="has_agreed"
              register={register}
              rules={{ required: 'Please agree to the terms of condition & privacy policy' }}
              errors={errors}
            >
              <Text>
                I agree to all the
                <Text
                  sx={{ color: 'primary', cursor: 'pointer' }}
                  onClick={() => {
                    window.open('/terms-condition', '_blank')
                  }}
                >
                  {' '}
                  Terms of condition
                </Text>
                <Text> & </Text>
                <Text
                  sx={{ color: 'primary', cursor: 'pointer' }}
                  onClick={() => {
                    window.open('/privacy-policy', '_blank')
                  }}
                >
                  Privacy Policy
                </Text>
              </Text>
            </FormCheckbox>

            <Box p={1} />

            <FormCheckbox<RegisterState>
              id="has_agreed_2"
              name="has_agreed_2"
              register={register}
              rules={{ required: 'Please agree to the voxto user agreement' }}
              errors={errors}
            >
              <Text>
                I agree to the
                <Text
                  sx={{ color: 'primary', cursor: 'pointer' }}
                  onClick={() => {
                    window.open('/user-agreement', '_blank')
                  }}
                >
                  {' '}
                  Voxto User Agreement
                </Text>
              </Text>
            </FormCheckbox>

            <Box pt={10} />

            <Box
              variant="layout.flexCenterSpaceBetween"
              sx={{ width: '100%', flexDirection: ['column', 'row'] }}
            >
              <Button type="submit" onClick={() => clearErrors()}>
                Create Account
              </Button>
              <Text sx={{ pt: [3, 0] }}>
                Already have an account? <Link to="/sign-in">Log In</Link>
              </Text>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default SignUpDetail
