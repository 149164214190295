import { useNavigate } from 'react-router-dom'
import { Box, Card, Grid, Image, Text, Button, AspectRatio } from 'theme-ui'
import { FC } from 'react'
import { NFT } from 'network/services/nft'
import _ from 'lodash'

const ShopCard: FC<{ nft: NFT }> = ({ nft }) => {
  const navigate = useNavigate()
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const datetime = new Date(nft.expired_at)
  const diff = datetime.getTime() - Date.now()
  const mins = Math.ceil(diff / 1000 / 60)
  const hrs = Math.round(mins / 60)
  const day = Math.round(hrs / 24)
  const endDate = `${datetime.getDate()} ${months[datetime.getMonth()]} ${datetime.getFullYear()}`

  const timeLeft = `${day > 0 ? day + ' Days' : hrs > 0 ? hrs + ' Hours' : mins + 'Minutes'}`

  return (
    <Card sx={{ height: 'calc(100% - 50px)' }}>
      <Grid gap={4}>
        <Box>
          <Box sx={{ borderRadius: 10, overflow: 'hidden' }}>
            <AspectRatio ratio={1 / 1}>
              <Image
                src={nft.image_url}
                sx={{
                  width: '100%',
                  objectFit: 'cover'
                }}
              />
            </AspectRatio>
          </Box>
        </Box>
        <Box sx={{ background: 'black', borderRadius: 30, textAlign: 'center' }}>
          {nft.status !== 'active' ? (
            <Text color="buttonText">Ended on {endDate}</Text>
          ) : (
            <Text color="buttonText">End in {timeLeft}</Text>
          )}
        </Box>
        <Text>{nft.name}</Text>
        <Grid gap={1}>
          <Text>Type</Text>
          <Text variant="mediumSmall" sx={{ color: 'primary' }}>
            {_.startCase(nft.type)}
          </Text>
        </Grid>
        <Grid gap={1}>
          <Text>{nft.status !== 'active' ? 'Last Bid' : 'Current Bid'}</Text>
          <Text variant="mediumSmall" sx={{ color: 'primary' }}>
            {nft.current_bid} {nft.token_type}
          </Text>
        </Grid>
        {nft.status !== 'active' ? (
          <Button sx={{ background: 'input' }} onClick={() => navigate('shop/' + nft.id)}>
            Bid Ended
          </Button>
        ) : (
          <Button onClick={() => navigate('shop/' + nft.id)}>Place A Bid</Button>
        )}
      </Grid>
    </Card>
  )
}

export default ShopCard
