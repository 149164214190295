import { FC } from 'react'
import { Card, Flex, Text } from 'theme-ui'

const OverviewCard: FC<{
  title: string
  value: string | number
}> = ({ title, value }) => {
  return (
    <Card variant="muted">
      <Flex variant="layout.vStack">
        <Text color="textMuted">{title}</Text>
        <Text>{value}</Text>
      </Flex>
    </Card>
  )
}

export default OverviewCard
