import { Box, Container, IconButton, Image, Text } from 'theme-ui'
import { Link, Outlet } from 'react-router-dom'
import { useResolution } from 'hooks/useResolution'

const GeneralLayout = () => {
  return (
    <Container sx={{ minHeight: '100vh' }}>
      <PublicHeader />
      <Outlet />
      <PublicFooter />
    </Container>
  )
}

const PublicHeader = () => {
  const { isMobile } = useResolution()

  return (
    <Box>
      {isMobile ? (
        <Box sx={{ zIndex: 2, py: 3, pt: 15 }}>
          <Container variant="layout.flexCenterCenter">
            <Link to="/" style={{ display: 'flex' }}>
              <Image src="/voxto-white.svg" alt="voxto" />
            </Link>
          </Container>
        </Box>
      ) : (
        <Box>
          <Container sx={{ mt: 10 }}>
            <Box variant="layout.flexCenterCenter">
              <Link to="/">
                <Image src="/voxto-white.svg" alt="voxto" />
              </Link>
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  )
}

const PublicFooter = () => {
  const { isMobile } = useResolution()

  return (
    <Box>
      {isMobile ? (
        <Box sx={{ bottom: 7, left: 0, right: 0, py: 3 }}>
          <Container>
            <Box variant="layout.vStack" sx={{ alignItems: 'center' }}>
              <Box sx={{ pb: 14 }}>
                <ExternalPage />
              </Box>
              <Box>{/* <Button>English</Button> */}</Box>
              <Box>
                <Text>{`VOXTO © ${new Date().getFullYear()}. All Rights Reserved`}</Text>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box>
          <Container sx={{ mb: 10 }}>
            <Box variant="layout.flexCenterSpaceBetween">
              <Text>{`VOXTO © ${new Date().getFullYear()}. All Rights Reserved`}</Text>
              <ExternalPage />
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  )
}

const ExternalPage = () => {
  return (
    <Box>
      <a href="https://www.facebook.com/voxto.amplify" target="_blank" rel="noreferrer">
        <IconButton>
          <Image src="/assets/svg/facebook.svg" />
        </IconButton>
      </a>
      <a href="https://t.me/voxto" target="_blank" rel="noreferrer">
        <IconButton>
          <Image src="/assets/svg/telegram.svg" />
        </IconButton>
      </a>
      <a href="https://twitter.com/voxto_amplify" target="_blank" rel="noreferrer">
        <IconButton>
          <Image src="/assets/svg/twitter.svg" />
        </IconButton>
      </a>
      <a href="https://discord.com/invite/w7Sz8nZQJQ" target="_blank" rel="noreferrer">
        <IconButton>
          <Image src="/assets/svg/discord.svg" />
        </IconButton>
      </a>
    </Box>
  )
}

export default GeneralLayout
