/** @jsxImportSource theme-ui */
import { Box, Flex, Grid, Text } from 'theme-ui'
import TitleWithBack from 'components/TitleWithBack'
import MobileReturnHeader from 'components/MobileReturnHeader'
import { useResolution } from 'hooks/useResolution'
import { FC, useState } from 'react'
import useSWR from 'swr'
import NewsService, { News } from 'network/services/news'
import ErrorCard from 'components/error'
import { serialize } from 'network/request'
import LoadingCard from 'components/loading'
import { NewsCardSmall } from 'components/Home'
import PageControl from 'components/page-control'

const NewsPage = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      <Box>
        {isMobile && <MobileReturnHeader title="News" delta={-1} />}
        <TitleWithBack backRef="/home" title="News" />
      </Box>

      <NewsList />
    </Box>
  )
}

const NewsList: FC = () => {
  const [page, setPage] = useState(1)
  const limit = 10
  const { data, error, mutate } = useSWR<{ data: News[] }>(
    serialize(NewsService.getNewsList, {
      page: page,
      limit: limit,
      sort: 'created_at:desc'
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to load news" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const total = NewsService.toPaginate(data).total

  return (
    <Box>
      <Grid columns={[1, 1, 2]} gap={[2, 1]}>
        {data.data.length === 0 && <Text>There are no news at the moment</Text>}

        {data.data.map((news, index) => (
          <NewsCardSmall
            key={index}
            imageSrc={news.image_url}
            title={news.title}
            source="Voxto"
            // source={news.url ?? ''}
            date={news.published_at}
          />
        ))}
      </Grid>

      <Box p={4} />

      {data && total > 0 && (
        <PageControl page={page} total={total} limit={limit} setPage={setPage} />
      )}
    </Box>
  )
}

export default NewsPage
