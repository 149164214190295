import useSWR from 'swr'
import { useMemo } from 'react'
import NFTService, { NFT } from 'network/services/nft'

export const useNFTCheck = (id: number | string | undefined) => {
  const { data } = useSWR<{ data: NFT }>(id ? NFTService.getMyNFT(id) : null)
  console.log(data?.data)
  const checkState = useMemo(() => {
    if (
      data?.data?.pending_nft_withdraws_count === 0 &&
      data?.data?.pending_nft_transfers_count === 0
    ) {
      return true
    }
    return false
  }, [data])

  return { checkState }
}
