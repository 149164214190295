import { Box, Card, Heading, Text } from 'theme-ui'

const TermsConditionPage = () => {
  return (
    <Box
      variant="layout.newTabContainer"
      sx={{
        minHeight: ['calc(30vh - 51px - 144px)', 'inherit'],
        py: 5,
        position: 'relative'
      }}
    >
      <Card>
        <Box sx={{ textAlign: 'center' }}>
          <Heading sx={{ textDecoration: 'underline' }}>TERMS AND CONDITIONS OF USE </Heading>
        </Box>
        <Box p={[1, 10]}>
          <Text>Dear Users,</Text>
          <Box p={5} />
          <Text>
            KKindly read this Terms and Conditions of Use (“T&C”) before using our voxto.com (“VOXTO
            Website") operated by VOXFI Ltd ("us", "we", or "our"). This T&C constitute a legally
            binding agreement between you and VOXFI Ltd, which is a company incorporated under the
            Laws of Samoa.
          </Text>
          <Box p={2} />
          <Text>
            The following T&C and the Privacy Policy (collectively known as the “Terms”) apply to
            any person that registers for and / or opens an account through voxto.com .
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            ACCEPTANCE OF T&C
          </Heading>
          <Text>
            Your access and use of the VOXTO Website is conditional on your acceptance of and
            compliance with this T&C. This T&C sets out the terms and conditions of you, using,
            accessing, viewing or in any way interacting with VOXTO Website including but not
            limited to the access of services offered by us or the registration of an account with
            us, through the VOXTO Website. This T&C shall apply to all visitors, users and any other
            person who browse, access or use the VOXTO Website.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            RISKS
          </Heading>
          <Text>
            Before using the VOXTO Website, you should ensure that you understand the risks involved
            in partaking and / or investing in cryptocurrencies. Cryptocurrency markets can be
            volatile and its prices can fluctuate significantly, which could result in the sudden
            and significant increases or decreases in the value of your assets. This market
            volatility and other risks involved in partaking and / or investing in cryptocurrencies.
            There may be additional risks not identified in this T&C.
          </Text>
          <Box p={2} />
          <Text>
            You should carefully assess whether your financial situation and risk tolerance is
            suitable for partaking and / or investing in cryptocurrency. You accept and agree that
            you are solely responsible for any decision in partaking and / or investing or otherwise
            hold or deal with cryptocurrency.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            VOXTO ACCOUNTS
          </Heading>
          <Text>
            You may browse and view certain contents of the VOXTO Website without registering for an
            account with us (“Account”). However, in order to access all services offered by us and
            to partake and / or invest digital currencies on the VOXTO Website, you will need to
            register for an Account with us.
          </Text>
          <Box p={2} />
          <Text>
            Please note that where you register for and / or open an account through the VOXTO
            Website, you will be further required to enter into and agree to the terms of our User
            Agreement, which is a legally binding agreement setting out the relevant terms and
            conditions in respect of your VOXTO Account and your use of the VOXTO Services.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            ELIGIBILITY
          </Heading>
          <Text>By opening an Account with us, you expressly warrant and represent that :</Text>
          <ol type="a">
            <li>
              <Text>
                If you are an individual, you are at least 18 years of age. If you are a company /
                corporation, you must be registered with the relevant authority;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                You have full capacity to accept this T&C and enter any transaction available
                through the VOXTO Website;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                You will not have more than one (1) Account or use or access any account other than
                your own or assist any other person in obtaining unauthorized access to your Account
                or any other Account;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                All information and documents provided to us are true, correct and complete and not
                misleading in any material way and such information and documents will remain true
                and correct;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                All actions, conditions and things required to be taken, fulfilled and done in
                order: -
              </Text>
              <Box p={1} />
              <ol type="i">
                <li>
                  <Text>
                    To enable you to lawfully enter into, exercise your rights and perform, and
                    comply with, your obligations under this T&C, and
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    To ensure that those obligations are valid, legally binding and enforceable,
                    have been taken, fulfilled and done;
                  </Text>
                  <Box p={1} />
                </li>
              </ol>
            </li>
            <li>
              <Text>Your obligations under this T&C are valid, binding and enforceable;</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                You are solvent, able to pay your debts as they fall due and not an undischarged
                bankrupt and / or are a going concern;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Your entry into, exercise of your rights and / or performance of or compliance with
                your obligations under this T&C does not and will not: -
              </Text>
              <Box p={1} />
              <ol type="i">
                <li>
                  <Text>
                    Violate any terms of this T&C to which you or where applicable, any of your
                    affiliates, is a party or which is binding on any of you or your respective
                    assets, or
                  </Text>
                  <Box p={1} />
                </li>
                <li>
                  <Text>
                    Result in the existence of, or oblige any of you to create, any security over
                    those assets;
                  </Text>
                  <Box p={1} />
                </li>
              </ol>
            </li>
            <li>
              <Text>
                You have obtained all consents, licenses, approvals or authorizations of, exemptions
                by or registrations with or declarations by, any governmental or other authority
                that you require, and these are valid and subsisting and will not be contravened by
                the execution or performance of this T&C;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Your participation and / or engagement with the services offered by us complies with
                all Applicable Laws of Samoa (herein defined);
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                You will inform yourself and, if necessary, consult your own professional advisers
                as to the relevant legal, tax and exchange control regulations in force in the
                countries of your citizenship, residence or domicile; and
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                You will not use your Account for or in relation to any illegal activity, any
                prohibited activity(ies), in violation of any laws, statutes, ordinances or
                regulations, or in breach of export controls or sanctions requirements.
              </Text>
              <Box p={1} />
            </li>
          </ol>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            ORDER OF PRECEDENCE
          </Heading>
          <Text>
            To the extent the User Agreement applies to you, in the event of any conflict or
            inconsistency between the provisions of this T&C and the provisions of the User
            Agreement, the terms of the User Agreement shall take precedence.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            APPLICABLE LAW
          </Heading>
          <Text>
            The use of the VOXTO Website is subject to Applicable Law. (“Applicable Law”): refers to
            all applicable laws in Samoa of any governmental authority, including, without
            limitation, federal, state and foreign securities laws, tax laws, tariff and trade laws,
            ordinances, judgments, decrees, injunctions, writs and orders or like actions of any
            governmental authority and rules, regulations, orders, interpretations, licenses, and
            permits of any federal, regional, state, country, municipal or other governmental
            authority. By entering into this agreement to use and access the VOXTO Website, you
            agree to act in compliance with and be legally bound to any and all Applicable Law.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            CHANGES TO THESE TERMS
          </Heading>
          <Text>
            We reserve the right, at our sole discretion, to amend or replace this T&C at any time.
            Please visit the VOXTO Website regularly to keep yourself updated on the latest version
            of this T&C. We will provide notification of any amendments to this T&C via
            announcements on the VOXTO Website or any other appropriate means. If you do not agree
            to the T&C or any amendment thereto, please stop accessing or using the VOXTO Website.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            VOXTO WEBSITE AVAILABILITY
          </Heading>
          <Text>
            We will use reasonable endeavours to ensure continuous operation and accessibility of
            the VOXTO Website. However, our services are provided without warranty of any kind,
            either express or implied. We do not represent that the VOXTO Website will be available
            at all times free from any interruption whatsoever or that the VOXTO Website will not
            experience any delays, failures, omissions, loss of transmitted information or errors to
            meet your needs.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            CONTENT
          </Heading>
          <Text>
            To the extent you are requested or permitted to submit, post or upload content (in any
            form) for publication on the VOXTO Website, you agree and acknowledge that by submitting
            such content to us, you grant us a worldwide, royalty-free and transferable license to
            use, reproduce, distribute, prepare derivative works of, display, and otherwise publish
            such content in connection with the VOXTO Website , including but not limited to the
            promotion of the VOXTO Website through any media format.
          </Text>
          <Box p={2} />
          <Text>
            You undertake not to upload or submit any content to the VOXTO Website that is: -
          </Text>
          <ol type="i">
            <li>
              <Text>
                Obscene, indecent, false, defamatory, libellous, pornographic, harassing, offensive,
                menacing, with intent to annoy, abuse, threaten or harass another person, or
                encourage conduct that would violate any law, or is otherwise inappropriate;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Infringes any intellectual property right of any person or entity;</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Is in breach any of the terms of our Privacy Policy or violates the privacy of any
                person or entity;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Is illegal or unlawful;</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Is inaccurate or false or could be seen as misleading; or</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Contains software viruses or files designed or intended to disrupt, disable, damage,
                limit or interfere with the proper function of any part of the VOXTO Website or any
                third-party software, applications or features.
              </Text>
              <Box p={1} />
            </li>
          </ol>
          <Box p={2} />
          <Text>
            We reserve the right to determine whether any content you upload complies with this T&C.
            We may remove such content or take any other appropriate measure (including terminating
            your account or access to the VOXTO Website) if we discover that you have breached any
            of the terms of the T&C regarding content that may be uploaded or submitted through the
            VOXTO Website.
          </Text>
          <Box p={2} />
          <Text>
            If requested or if necessary, we will, at any time, cooperate fully with any regulatory
            authorities or court order requesting or directing us to disclose the identity of anyone
            posting, publishing or otherwise making available any content that violates this T&C.
          </Text>
          <Box p={2} />
          <Text>
            You shall be solely liable for any content posted or submitted through the VOXTO
            Website. All content published or accessed by you through the VOXTO Website is done at
            your own risk and you will be solely responsible and liable for any damage or loss to
            you or any other party resulting therefrom.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            VOXTO WEBSITE AND CONTENT ACCURACY
          </Heading>
          <Text>
            While we try our best to provide accurate and timely information on the VOXTO Website,
            the VOXTO Website (and all content therein) may not always be entirely accurate,
            complete or current and may also include technical inaccuracies or typographical errors.
          </Text>
          <Box p={2} />
          <Text>
            In an effort to continue to provide you with complete and accurate information,
            information may, to the extent permitted by Applicable Law, be changed or updated from
            time to time without notice. Accordingly, you should verify all information before
            relying on it, and all decisions based on information contained on the VOXTO Website are
            your sole responsibility and we shall have no liability for such decisions.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            LINK(S) TO OTHER WEBSITES
          </Heading>
          <Text>
            The VOXTO Website may contain links to third-party website, services or materials that
            are not owned or controlled by us. We have no control over, and assumes no
            responsibility for, the content, privacy policies, or practices of any third-party
            websites or services. You hereby further acknowledge and agree that we shall not be
            responsible or liable, directly or indirectly, for any damage or loss caused or alleged
            to be caused by or in connection with use of or reliance on any such content, goods or
            services available on or through any such websites or services. We strongly advise you
            to read all the terms and conditions and privacy policies of any third-party websites or
            services that you visit.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            PROHIBITIONS
          </Heading>
          <Text>
            You are prohibited from performing any of the following acts (whether performed by
            yourself or through any other means or person) where using or accessing the VOXTO
            Website: -
          </Text>
          <ol type="a">
            <li>
              <Text>
                Reproduce, duplicate, copy, distribute, sell, resell or otherwise exploit the VOXTO
                Website and any content and information made available therein for any purpose;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Modify, adapt, translate, reverse-engineer, decompile, disassemble or create
                derivative works of any portion of the VOXTO Website;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Use the VOXTO Website in any unlawful manner, for any unlawful purpose or in any
                manner inconsistent with these Terms;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Publish any content on the VOXTO Website which is indecent, obscene, false,
                menacing, or offensive in character with intent to annoy, abuse, threaten or harass
                any person or otherwise to violate the rights of any other user or any third party;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Remove any copyright, trade-mark or other proprietary rights notice from the VOXTO
                Website or materials originating from the VOXTO Website;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Interfere with or disrupt the operation of the VOXTO Website or the servers or
                networks used to make the VOXTO Website available; or violate any requirements,
                procedures, policies or regulations of such servers or networks;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Gather or collect personal information through any means (including using any robot,
                spider or other device or process to retrieve, data-mine or replicate or copy any
                information from the VOXTO Website;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Upload or transmit or otherwise make available any software or tool that contain any
                software viruses, worms, Trojan Horse, time bombs, web bugs, spyware, or any other
                computer code or program that may or is intended to damage, disrupt, disable, hijack
                the operation of, limit or interfere with the proper function of any hardware,
                software or device pertaining to the VOXTO Website; use any manual or automatic
                device in any way to gather VOXTO Website content or reproduce or circumvent the
                navigational structure or presentation of the VOXTO Website ;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Frame or mirror any part of the VOXTO Website; or</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Breach any terms of this T&C.</Text>
              <Box p={1} />
            </li>
          </ol>
          <Box p={2} />
          <Text>
            Where you have registered for an Account and when using the services provided by us, you
            are further prohibited from performing any of the prohibited acts or engaging in any of
            the prohibited businesses identified in the User Agreement.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            TERMINATION
          </Heading>
          <Text>
            If we consider that you have breached any of the Terms or if we otherwise consider it
            appropriate to do so, we may suspend and terminate your access to the VOXTO Website
            immediately, without giving notice to you.
          </Text>
          <Box p={2} />
          <Text>
            Where you have registered for an account with us, we reserve the right to suspend or
            terminate your Account for breach of this T&C and / or the User Agreement. We will not
            be liable to compensate you for any loss or damage that you may suffer from your
            inability to access your Account due to the suspension or termination.
          </Text>
          <Box p={2} />
          <Text>
            Our right to suspend or terminate your Account in the event of your breach any Terms of
            this T&C is in addition to our right to seek all remedies available to us under this
            T&C, at law and in equity.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            LIMITATION OF LIABILITY
          </Heading>
          <Text>
            This VOXTO Website and all material and information made available herein are provided
            on an "as is" and "as available" basis.
          </Text>
          <Box p={2} />
          <Text>
            To the maximum extent permitted by law, we expressly disclaim all liabilities, and you
            expressly waive all warranties of any kind, whether express or implied, in respect of
            your access or use of the VOXTO Website. In no event shall we, nor our directors,
            employees, agents, or representatives be liable under contract, tort, strict liability,
            negligence or any other legal or equitable theory, for: -
          </Text>
          <ol type="i">
            <li>
              <Text>Indirect or consequential losses or damages; </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Loss of actual or anticipated profits;</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Loss of revenue or goodwill or anticipated savings; or</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>Loss of data arising from your use and access of the VOXTO Website.</Text>
              <Box p={1} />
            </li>
          </ol>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            GOVERNING LAW
          </Heading>
          <Text>
            This T&C shall be governed and construed in accordance with the laws of Samoa. Our
            failure to enforce any right or provision of this T&C will not be considered a waiver of
            those rights. If any provision of this T&C is held to be invalid or unenforceable by a
            court, the remaining provisions of this T&C will remain in effect.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            FINANCIAL ADVICE
          </Heading>
          <Text>
            Under no circumstances does any information contained in the VOXTO Website, or provided
            to you through your account or by any employee, agent or affiliate of ours’s, constitute
            financial, investment or other professional advice.
          </Text>
          <Box p={2} />
          <Text>
            You are solely responsible for any decision made in partaking and / or investing
            cryptocurrency, and such decision should take into account your risk tolerance and
            financial circumstances. You should consult your legal or tax professional in relation
            to your specific situation.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            TAXES
          </Heading>
          <Text>
            You are solely responsible for determining whether, and to what extent, any taxes apply
            to any transactions you carry out through your account, and for withholding, collecting,
            reporting and remitting the correct amounts of tax to the appropriate tax authorities.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            ENTIRE AGREEMENT
          </Heading>
          <Text>
            This T&C constitute the entire agreement between us regarding your use and access of our
            VOXTO Website, save where you have registered for an Account and use the services
            offered by us, in which case the terms of the User Agreement shall further govern your
            relationship with us.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            CONTACTING US
          </Heading>
          <Text>
            If you have any questions about this T&C, the practices and accessibility of this VOXTO
            Website, or your dealings with this VOXTO Website, please contact us at:
            support@voxto.com
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            CUSTOMER FEEDBACK, QUERIES, AND COMPLAINTS
          </Heading>
          <Text>
            If you have any feedback, questions or complaints, please contact us via email at
            support@voxto.com. Whilst we strive to respond to you as soon as possible, for more
            complicated issues, it may take us up to 28 days to resolve and get back to you. You
            accept and agree that we shall not be responsible for any loss and damage incurred
            during such period.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            DISCLAIMER
          </Heading>
          <Text>
            The information and data supplied in response to requests will be collected and provided
            to the fullest and most accurate possible extent. However, nothing in such information
            and data can be ensured to contain no errors, mistakes, misrepresentations or failures
            etc. Such errors, mistakes, misrepresentations or failures etc. can be the result of
            human agency, program or process imperfections. Therefore, neither us nor any of our
            respective directors, officers, employees, agents or outsourced advisors makes any
            representation, warranty or guarantee whatsoever with respect to the information and
            data and, in particular, as to the accuracy or completeness of the information and data.
            All data processed by us herein will be in accordance in to our Privacy Policy.
          </Text>
          <Box p={2} />
          <Text>
            Nothing in this section shall be deemed or construed as non-willingness to provide the
            requested information and data.
          </Text>
        </Box>
      </Card>
    </Box>
  )
}

export default TermsConditionPage
