/** @jsxImportSource theme-ui */
import { Box, Button, Card, Flex, Grid, Heading, Select, Text } from 'theme-ui'
import { useNavigate } from 'react-router-dom'
import { TiArrowUnsorted } from 'react-icons/ti'
import useSWR from 'swr'

import { IDataResponse, serialize } from 'network/request'
import StakeService, { Stake } from 'network/services/stake'
import AuthService from 'network/services/auth'
import { Wallet } from 'network/services/wallet'
import HorizontalScroller from 'layout/horizontal-scroller'
import { StakeCard } from 'components/Stake'
import LoadingCard from 'components/loading'
import { currencyStore, findUsd } from 'store/currency'
import { useSnapshot } from 'valtio'
import ErrorCard from 'components/error'
import PageControl from 'components/page-control'
import { ChangeEvent, FC, useState } from 'react'
import commaNumber from 'modules/comma-number'

interface FilterQuery {
  status: string
  token_type: string
}

const StakePage = () => {
  const [query, setQuery] = useState<FilterQuery>({ status: '', token_type: '' })

  return (
    <Box variant="layout.pageContainer">
      <Heading>Stake</Heading>
      <Box pt={8} />
      <Overview />
      <Box pt={8} />
      <Filters query={query} setQuery={setQuery} />
      <ActivePools query={query} />
    </Box>
  )
}

const Overview = () => {
  const navigate = useNavigate()
  const currency = useSnapshot(currencyStore)
  const { data, error, mutate } = useSWR<{
    portfolio_balance: {
      token_type: string
      balance: number
    }[]
    wallets: Wallet[]
    interest_balance: {
      token_type: string
      balance: number
    }[]
    linked_nft: string
    owned_nft: string
  }>(serialize(AuthService.findBalance))

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve portfolio summary" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const portfolioUsd = data.portfolio_balance.reduce((prev, next) => {
    return prev + findUsd(next.balance, next.token_type)
  }, 0)
  const portfolioBtc = portfolioUsd / parseFloat(currency.btc?.c ?? '0')
  const interestUsd = data.interest_balance.reduce((prev, next) => {
    return prev + findUsd(next.balance, next.token_type)
  }, 0)
  const interestBtc = interestUsd / parseFloat(currency.btc?.c ?? '0')

  return (
    <Card variant="secondary" sx={{ p: 6 }}>
      <Flex variant="layout.flexCenterSpaceBetween" sx={{ flexDirection: ['column', 'row'] }}>
        <Box>
          <Text>Total Portfolio Value</Text>
          <Box p={2} />
          <Flex sx={{ flexDirection: ['column', 'row'] }}>
            <Text variant="extraLarge" sx={{ pr: 2 }} color="secondaryText">
              BTC {portfolioBtc}
            </Text>
            <Text variant="extraLarge" color="textMuted">
              ≈ ${commaNumber(portfolioUsd.toFixed(2))}
            </Text>
          </Flex>
          <Box p={2} />
          <Text>Total Earning (All Time)</Text>
          <Box p={2} />
          <Flex sx={{ flexDirection: ['column', 'row'] }}>
            <Text variant="extraLarge" sx={{ pr: 2 }} color="secondaryText">
              BTC {interestBtc}
            </Text>
            <Text variant="extraLarge" color="textMuted">
              ≈ ${commaNumber(interestUsd.toFixed(2))}
            </Text>
          </Flex>
        </Box>
        <Box sx={{ pt: [5, 0] }}>
          <Button onClick={() => navigate('pools')}>Stake My Crypto</Button>
        </Box>
      </Flex>
    </Card>
  )
}

const Filters: FC<{ query: FilterQuery; setQuery: any }> = ({ query, setQuery }) => {
  const queryContract = (e: ChangeEvent<HTMLSelectElement>) => {
    setQuery({ ...query, status: e.currentTarget.value })
  }

  const queryToken = (e: ChangeEvent<HTMLSelectElement>) => {
    setQuery({ ...query, token_type: e.currentTarget.value })
  }

  return (
    <>
      <Flex sx={{ justifyContent: ['space-between', 'flex-start'] }}>
        <Select
          variant="forms.secondarySelect"
          sx={{ background: 'background', color: 'text', p: 1 }}
          onChange={queryContract}
          arrow={
            <Flex
              variant="layout.flexCenterCenter"
              sx={{ width: 'auto', height: '100%', mr: [0, 6] }}
            >
              <TiArrowUnsorted />
            </Flex>
          }
          defaultValue={query.status}
        >
          <option value="">
            <Text as="p">All Contract</Text>
          </option>
          <option value="active">
            <Text as="p">My Active Contract</Text>
          </option>
          <option value="inactive">
            <Text as="p">My Inactive Contract</Text>
          </option>
        </Select>

        <Select
          variant="forms.secondarySelect"
          sx={{ background: 'background', color: 'text', p: 1 }}
          onChange={queryToken}
          arrow={
            <Flex variant="layout.flexCenterCenter" sx={{ width: 'auto', height: '100%' }}>
              <TiArrowUnsorted />
            </Flex>
          }
        >
          <option value="">
            <Text as="p">All Token</Text>
          </option>
          <option value="BTC">
            <Text as="p">BTC</Text>
          </option>
          <option value="USDT">
            <Text as="p">USDT</Text>
          </option>
          <option value="ETH">
            <Text as="p">ETH</Text>
          </option>
          <option value="VXT">
            <Text as="p">VXT</Text>
          </option>
        </Select>
      </Flex>

      <Box pt={8} />
    </>
  )
}

const ActivePools: FC<{ query: FilterQuery }> = ({ query }) => {
  const [page, setPage] = useState(1)

  const limit = 5
  const { data, error, mutate } = useSWR<IDataResponse<Stake>>(
    serialize(StakeService.getAll, {
      page: page,
      limit: limit,
      sort: 'created_at:desc',
      ...query
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve active contracts" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const stakes = StakeService.toRow(data)
  const total = StakeService.toPaginate(data).total

  if (stakes.length === 0) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <Text>You do not have any {query.status} contracts at the moment</Text>
      </Flex>
    )
  }

  return (
    <>
      <Box>
        <HorizontalScroller>
          <Grid gap={6} width={[800, '100%']}>
            {stakes.map((stake) => {
              return <StakeCard key={stake.id} stake={stake} />
            })}
          </Grid>
        </HorizontalScroller>
        <Box p={2} />
        {data && total > 0 && (
          <PageControl page={page} total={total} limit={limit} setPage={setPage} />
        )}
      </Box>
    </>
  )
}

export default StakePage
