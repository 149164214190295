/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Grid,
  Text,
  Flex,
  Divider,
  Card,
  Switch,
  Checkbox,
  Label,
  Paragraph
} from 'theme-ui'
import { useNavigate, useParams } from 'react-router-dom'
import { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { TiArrowUnsorted } from 'react-icons/ti'
import { BsArrowRight } from 'react-icons/bs'
import { ErrorMessage } from '@hookform/error-message'
import _ from 'lodash'
import useSWR, { mutate } from 'swr'

import { IDataResponse, serialize } from 'network/request'
import PoolService, { Pool } from 'network/services/pool'
import WalletService, { Wallet } from 'network/services/wallet'
import NFTService, { NFT } from 'network/services/nft'
import StakeService, { CreateEstimate, CreateStake, Estimation } from 'network/services/stake'
import { useResolution } from 'hooks/useResolution'
import { NFTSelectionCard } from 'components/Stake'
import TitleWithBack from 'components/TitleWithBack'
import MobileReturnHeader from 'components/MobileReturnHeader'
import CustomModal from 'components/modal'
import MoreInfo from 'components/more-info'
import FormInput from 'components/form-input'
import Link from 'components/link'
import toUTCTime from 'modules/to-utc-time'
import LoadingCard from 'components/loading'
import ErrorCard from 'components/error'
import StatusModal from 'components/status-modal'
import { motion, AnimatePresence } from 'framer-motion'
import { fadeAnim } from 'lib/animation'
import { useResponsiveValue } from '@theme-ui/match-media'

let AnimatedBox = motion(Box)

const StakeSubmissionPage = () => {
  return <StakeSubmissionView />
}

const StakeSubmissionView = () => {
  const { id } = useParams()
  const { data: response, error } = useSWR<{ data: Pool }>(id != null ? PoolService.get(id) : null)
  const navigate = useNavigate()

  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })

  const closeModal = useCallback(() => {
    if (!message.success) {
      setMessage({ isOpen: false })
    }
    setMessage({ isOpen: false })
    navigate('/stake')
  }, [message, navigate])

  const methods = useForm<CreateStake>({
    shouldUseNativeValidation: false,
    defaultValues: {
      pool_id: id,
      tenure: 12,
      link_nft: false,
      earn_vxt: false,
      agreed: false,
      nft_id: null
    }
  })

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve stake details" />
      </Flex>
    )
  }

  if (!response) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const pool = response.data

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      const { data: response } = await StakeService.create(values)
      if (response.success) {
        setMessage({
          isOpen: true,
          success: true,
          message: 'Stake created successfully'
        })
        methods.reset()
        mutate(WalletService.getByToken(pool.token_type))
      }
    } catch (error: any) {
      setMessage({
        isOpen: true,
        success: false,
        message: error.message
      })
    }
  })

  return (
    <Box>
      <FormProvider {...methods}>
        <Box variant="layout.pageContainer">
          <Header pool={pool} />
          <Box p={2} />
          <Box as="form" onSubmit={onSubmit}>
            <Terms pool={pool} />
            <Box p={2} />
            <Amount pool={pool} />
            <Divider sx={{ mx: 0 }} />
            <Box p={2} />
            <LinkNFT />
            <Box p={3} />
            <EarnVXT tokenType={pool.token_type} />
            <Box p={3} />
            <Summary />
            <Box p={3} />
            <Confirmation />
          </Box>
        </Box>
      </FormProvider>
      <StatusModal
        isOpen={message.isOpen}
        onRequestClose={closeModal}
        success={message.success}
        children={message.message}
      />
    </Box>
  )
}

const Header: FC<{ pool: Pool }> = ({ pool }) => {
  const { isMobile } = useResolution()

  return (
    <>
      {isMobile && (
        <MobileReturnHeader
          title={`Stake ${pool.token_type} - ${_.startCase(pool.type)} Pool`}
          delta={-1}
        />
      )}
      <TitleWithBack
        backRef="/stake/pools"
        title={`Stake ${pool.token_type} - ${_.startCase(pool.type)} Pool`}
      />
    </>
  )
}

const Terms: FC<{ pool: Pool }> = ({ pool }) => {
  const { setValue, watch } = useFormContext<CreateStake>()
  const term = watch('tenure')

  return (
    <Box>
      <Text>Terms</Text>
      <Box p={1} />
      <Grid columns={2}>
        {pool.has_tenure_12 && (
          <Button
            type="button"
            sx={{ borderRadius: 10 }}
            variant={term === 12 ? 'primary' : 'secondary'}
            onClick={() => setValue('tenure', 12)}
          >
            12 Months
          </Button>
        )}
        {pool.has_tenure_24 && (
          <Button
            type="button"
            sx={{ borderRadius: 10 }}
            variant={term === 24 ? 'primary' : 'secondary'}
            onClick={() => setValue('tenure', 24)}
          >
            24 Months
          </Button>
        )}
      </Grid>
    </Box>
  )
}

const Amount: FC<{ pool: Pool }> = ({ pool }) => {
  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext<CreateStake>()

  const { data: response, error } = useSWR<{ data: Wallet }>(
    pool.token_type ? WalletService.getByToken(pool.token_type) : ''
  )

  const walletBalance = response?.data?.balance

  useEffect(() => {
    if (errors != null) {
      if (errors.principal_amount?.type === 'min') {
        setError('principal_amount', {
          type: 'custom',
          message: `The amount must not be less than ${pool.min_amount} ${pool.token_type}`
        })
      }
      if (errors.principal_amount?.type === 'max') {
        setError('principal_amount', {
          type: 'custom',
          message: `The amount must not exceeds ${walletBalance} ${pool.token_type}`
        })
      }
    }
  }, [errors, pool, setError, walletBalance])

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message={`Unable to retrieve wallet information of ${pool.token_type}`} />
      </Flex>
    )
  }

  if (!response) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const handleMax = () => {
    walletBalance && setValue('principal_amount', walletBalance)
  }

  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  return (
    <Box>
      <FormInput
        label="Amount"
        name="principal_amount"
        register={register}
        errors={errors}
        placeholder={`Minimum ${pool.min_amount}`}
        containerSX={{ background: 'primaryCardBG' }}
        type="number"
        step=".00001"
        min={0}
        onKeyPress={preventMinus}
        rules={{
          required: 'Please enter the amount to stake',
          min: pool.min_amount,
          max: walletBalance
        }}
        trailing={
          <Flex
            variant="layout.hStack"
            sx={{
              width: 'auto',
              mr: 2
            }}
          >
            <Box>
              <Text>{pool.token_type ?? ''}</Text>
            </Box>
            <Box>
              <Text color="primary">|</Text>
            </Box>
            <Box>
              <Button variant="text" color="primary" type="button" onClick={handleMax}>
                Max
              </Button>
            </Box>
          </Flex>
        }
      />

      <Box p={1} />

      <Text sx={{ color: 'textMuted' }} variant="small">
        {`Available : ${walletBalance ?? ''} ${walletBalance ? pool.token_type : ''}`}
      </Text>

      <Box p={2} />

      {pool.type === 'launch' && pool.token_type.toLowerCase() !== 'vxt' && (
        <>
          <Text>Available Quota</Text>
          <Box p={1} />
          <Text sx={{ color: 'textMuted' }}>
            {`${pool.quota_limit ?? ''} ${pool.token_type ?? ''}`}
          </Text>
        </>
      )}
    </Box>
  )
}

const LinkNFT: FC = () => {
  const { setValue, watch } = useFormContext<CreateStake>()
  const [linkOpen, setLinkOpen] = useState<boolean>(false)
  const nftSelected = watch('nft_id')
  const nftLinked = watch('link_nft')

  const unlinkNFT = () => {
    setValue('link_nft', false)
    setValue('nft_id', null)
  }

  const linkNFT = () => {
    setValue('link_nft', true)
  }

  const openLink = () => {
    if (nftSelected != null) {
      setLinkOpen(true)
    }
  }

  const closeLink = () => {
    setLinkOpen(false)
  }

  const infoDirection = useResponsiveValue(['top', 'right'])

  return (
    <Box>
      <Flex sx={{ flexDirection: ['column', 'row'] }}>
        <Grid sx={{ flex: 2 }}>
          <Flex>
            <Text>Link a Voxto NFT</Text>
            <MoreInfo direction={infoDirection}>
              <Box
                variant="layout.borderedTextCenter"
                bg="dark"
                p={2}
                sx={{ width: 'max-content' }}
              >
                <Text variant="small" color="textMuted">
                  Link a Voxto NFT before the month ends for <br />
                  additional rewards
                </Text>
              </Box>
            </MoreInfo>
          </Flex>
          <NFTTotal />
        </Grid>

        <Flex variant="layout.hStack" sx={{ pt: [3, 0], transition: 'all' }}>
          <AnimatePresence exitBeforeEnter>
            {nftSelected && (
              <AnimatedBox
                key="cancel_modal"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <Button type="button" variant="secondary" onClick={unlinkNFT}>
                  Cancel
                </Button>
              </AnimatedBox>
            )}
            {nftSelected && nftLinked === false && (
              <AnimatedBox
                key="open_modal"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <Button variant="primaryFlexCenter" type="button" onClick={openLink}>
                  <Box sx={{ flex: 1 }} />
                  <Text>Confirm</Text>
                  <BsArrowRight sx={{ flex: 1, ml: 2 }} />
                </Button>
              </AnimatedBox>
            )}
          </AnimatePresence>
        </Flex>
      </Flex>

      <Box p={3} />

      <Avatars nftLinked={nftLinked} />

      <Box p={3} />

      <Potions nftLinked={nftLinked} />

      <CustomModal
        isOpen={linkOpen}
        onRequestClose={closeLink}
        contentStyle={{
          height: '60%'
        }}
      >
        <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <Card sx={{ p: [null, 10] }}>
            <Box>
              <Text>Link NFT</Text>
            </Box>
            <Box p={3} />
            <Paragraph>
              Linking an NFT to a pool allows you to earn additional profit share in VXT. You can
              unlink an NFT at any time, however it must be linked to the pool before the end of
              each month to be eligible for upcoming NFT holder profit distribution.
            </Paragraph>
            <Box p={3} />
            <Grid columns={2}>
              <Button
                type="button"
                onClick={() => {
                  unlinkNFT()
                  closeLink()
                }}
                sx={{ background: 'input' }}
              >
                Cancel
              </Button>
              <Button
                variant="primaryFlexCenter"
                type="button"
                onClick={() => {
                  linkNFT()
                  closeLink()
                }}
              >
                <Box sx={{ flex: 1 }} />
                <Text>Confirm</Text>
                <BsArrowRight sx={{ flex: 1, ml: 2 }} />
              </Button>
            </Grid>
          </Card>
        </Box>
      </CustomModal>
    </Box>
  )
}

const NFTTotal = () => {
  const { data, error } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, { linked: false })
  )

  if (error) {
    return (
      <Text sx={{ color: 'textMuted' }} variant="small">
        Error while retrieving NFT information
      </Text>
    )
  }

  if (!data) {
    return (
      <Text sx={{ color: 'textMuted' }} variant="small">
        Loading...
      </Text>
    )
  }

  const nfts = NFTService.toPaginate(data)

  return (
    <Text sx={{ color: 'textMuted' }} variant="small">
      You have {nfts.total} NFTs suitable for this pool
    </Text>
  )
}

const Avatars: FC<{ nftLinked: boolean }> = ({ nftLinked }) => {
  const { watch, setValue } = useFormContext<CreateStake>()
  const nftSelected = watch('nft_id')

  const { data, error } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, { linked: false })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve avatars" />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const nfts = NFTService.toRow(data)
  const avatars = nfts.filter((nft) => nft.type === 'avatar')

  return (
    <Box sx={{ display: avatars.length > 0 ? 'block' : 'none' }}>
      <Text variant="extraLarge">Avatar</Text>
      <Box p={1} />
      <Grid columns={[1, 3, 4]}>
        {avatars.map((nft) => (
          <NFTSelectionCard
            key={nft.id}
            NFT={nft}
            handleSelect={(id) => {
              if (!nftLinked) {
                setValue('nft_id', id)
              }
            }}
            isSelected={nftSelected === nft.id}
          />
        ))}
      </Grid>
    </Box>
  )
}

const Potions: FC<{ nftLinked: boolean }> = ({ nftLinked }) => {
  const { watch, setValue } = useFormContext<CreateStake>()
  const nftSelected = watch('nft_id')
  const { data, error } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, { linked: false })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve potions" />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }
  const nfts = NFTService.toRow(data)
  const potions = nfts.filter((nft) => nft.type === 'potion')

  return (
    <Box sx={{ display: potions.length > 0 ? 'block' : 'none' }}>
      <Text variant="extraLarge">Potion</Text>
      <Box p={1} />
      <Grid columns={[1, 3, 4]}>
        {potions.map((nft) => (
          <NFTSelectionCard
            key={nft.id}
            NFT={nft}
            handleSelect={(id) => {
              if (!nftLinked) {
                setValue('nft_id', id)
              }
            }}
            isSelected={nftSelected === nft.id}
          />
        ))}
      </Grid>
    </Box>
  )
}

const EarnVXT: FC<{ tokenType: string }> = ({ tokenType }) => {
  const [earnOpen, setEarnOpen] = useState<boolean>(false)
  const { watch, setValue } = useFormContext<CreateStake>()
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })

  useEffect(() => {
    if (tokenType === 'VXT') {
      setValue('earn_vxt', true)
    }
  }, [tokenType, setValue])

  const earnInVoxto = watch('earn_vxt')

  const checkTokenType = () => {
    console.log('check token type', tokenType)
    if (tokenType === 'VXT') {
      setMessage({
        isOpen: true,
        success: false,
        message: 'Cannot be disabled in VXT Pool'
      })
      setValue('earn_vxt', true)
    } else {
      if (!earnInVoxto) {
        setEarnOpen(true)
      } else {
        setValue('earn_vxt', false)
      }
    }
  }

  const infoDirection = useResponsiveValue(['top', 'right'])

  return (
    <Box>
      <Card>
        <Flex variant="layout.flexCenterSpaceBetween">
          <Flex variant="layout.flexCenterStart" sx={{ width: 'auto' }}>
            <Text sx={{ flex: 2 }}>Earn in Voxto token (VXT)</Text>
            <MoreInfo direction={infoDirection}>
              <Box
                variant="layout.borderedTextCenter"
                bg="dark"
                p={2}
                sx={{ width: 'max-content' }}
              >
                <Text variant="small" color="textMuted">
                  Receive additional profit share by choosing to <br />
                  get paid in VXT tokens
                </Text>
              </Box>
            </MoreInfo>
          </Flex>
          <Box>
            <Switch
              checked={tokenType === 'VXT' ? true : earnInVoxto}
              readOnly={true}
              onClick={() => checkTokenType()}
              sx={{
                background: tokenType === 'VXT' ? (earnInVoxto ? 'default' : 'input') : 'default'
              }}
            />
          </Box>
        </Flex>
      </Card>

      <CustomModal
        isOpen={earnOpen}
        contentStyle={{
          height: '50%'
        }}
      >
        <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <Card sx={{ p: [null, 10] }}>
            <Box>
              <Text>Earn in VXT</Text>
            </Box>
            <Box p={3} />
            <Paragraph>
              Receive additional profit share by choosing to get paid in VXT tokens.
            </Paragraph>
            <Box p={3} />
            <Grid columns={2}>
              <Button
                type="button"
                onClick={() => {
                  setEarnOpen(false)
                  setValue('earn_vxt', false)
                }}
                sx={{ background: 'sidebarButtonBG' }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setEarnOpen(false)
                  setValue('earn_vxt', true)
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Card>
        </Box>
      </CustomModal>
      <StatusModal
        isOpen={message.isOpen}
        onRequestClose={() => setMessage({ isOpen: false })}
        success={message.success}
        children={message.message}
      />
    </Box>
  )
}

const Summary = () => {
  const { id } = useParams()
  const { watch } = useFormContext<CreateStake>()
  const [estimation, setEstimation] = useState<Estimation>()
  const [showLess, setShowLess] = useState<boolean>(true)

  const getEstimate = async (data: CreateEstimate) => {
    try {
      const { data: result } = await StakeService.estimate({
        tenure: data.tenure,
        principal_amount: data.principal_amount,
        earn_vxt: data.earn_vxt,
        link_nft: data.link_nft,
        pool_id: data.pool_id,
        nft_id: data.nft_id
      })

      if (result.success) {
        setEstimation(result.data)
      }
    } catch (e) {
      //console.log(e)
    }
  }

  useEffect(() => {
    if (id != null) {
      // called once
      getEstimate({
        tenure: 12,
        principal_amount: 0,
        earn_vxt: false,
        link_nft: false,
        pool_id: id,
        nft_id: null
      })
    }

    const subscription = watch((data) => {
      if (
        data.tenure !== undefined &&
        data.principal_amount !== undefined &&
        data.earn_vxt !== undefined &&
        data.link_nft !== undefined &&
        data.pool_id !== undefined &&
        data.nft_id !== undefined
      ) {
        getEstimate({
          tenure: data.tenure,
          principal_amount: data.principal_amount,
          earn_vxt: data.earn_vxt,
          link_nft: data.link_nft,
          pool_id: data.pool_id,
          nft_id: data.nft_id
        })
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, id])

  return (
    <Box>
      <Flex variant="layout.flexCenterSpaceBetween">
        <Box>
          <Text variant="extraLarge">Summary</Text>
        </Box>
        {/* ToDo : Change to sorting button */}
        <Button
          type="button"
          variant="text"
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => setShowLess(!showLess)}
        >
          {showLess ? <Text>Show Less</Text> : <Text>Show More</Text>}
          <TiArrowUnsorted size="0.7em" />
        </Button>
      </Flex>

      <Box p={2} />

      {/* TODO: the fields are to be confirmed */}
      <Card sx={{ p: 0, borderRadius: 20 }} variant="mixedColor">
        <Card sx={{ p: 0, borderRadius: 20 }}>
          <AnimatePresence>
            {showLess && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Grid sx={{ py: 5, px: 6 }} columns={[1, 2]} gap={[2, 3]}>
                  <Text variant="mediumSmall" color="textMuted">
                    Staked Date
                  </Text>
                  <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
                    {estimation?.deposited_at ? toUTCTime(estimation.deposited_at) : '-'}
                  </Text>
                  <Text variant="mediumSmall" color="textMuted">
                    Value Date
                  </Text>
                  <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
                    {estimation?.started_at ? toUTCTime(estimation.started_at) : '-'}
                  </Text>
                  <Text variant="mediumSmall" color="textMuted">
                    Redemption Date
                  </Text>
                  <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
                    {estimation?.matured_at ? toUTCTime(estimation.matured_at) : '-'}
                  </Text>
                  {/* TODO: Not sure this comes with the estimation */}
                  {/* <Text variant="mediumSmall" color="textMuted">
              Profit Share Schedule
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              10th of Every Month
            </Text> */}
                  <Text variant="mediumSmall" color="textMuted">
                    First Payout Date
                  </Text>
                  <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
                    {estimation?.first_payout_at ? toUTCTime(estimation.first_payout_at) : '-'}
                  </Text>
                  <Text variant="mediumSmall" color="textMuted">
                    Last Payout Date
                  </Text>
                  <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
                    {estimation?.last_payout_at ? toUTCTime(estimation.last_payout_at) : '-'}
                  </Text>
                </Grid>

                <Divider />
              </motion.div>
            )}
          </AnimatePresence>

          <Grid sx={{ pb: 5, pt: 3, px: 6 }} columns={[1, 2]} gap={[2, 3]}>
            <Grid gap={1}>
              <Text sx={{ color: 'textMuted' }}>Minimum Profit Share</Text>
              <Text variant="mediumSmall" color="textMuted">
                of my staked profit
              </Text>
            </Grid>
            <Text sx={{ textAlign: [null, 'right'] }}>
              {estimation?.stake_rate ? `${estimation.stake_rate}%` : '-'}
              {estimation?.incentive_rate && estimation.earn_vxt
                ? ` + ${estimation.incentive_rate}%`
                : ''}
              {estimation?.nft_rate && estimation.link_nft ? ` + ${estimation.nft_rate}%` : ''}
            </Text>
          </Grid>
        </Card>

        <Link to={`/stake/pools/${id}/how`}>
          <Button type="button" variant="text" sx={{ width: '100%', py: 6, color: 'buttonText' }}>
            How Profit Distribution Works?
          </Button>
        </Link>
      </Card>
    </Box>
  )
}

const Confirmation = () => {
  const {
    register,
    clearErrors,
    formState: { errors }
  } = useFormContext<CreateStake>()

  return (
    <Box>
      <Flex sx={{ justifyContent: 'space-between', flexDirection: ['column', 'row'] }}>
        <Box>
          <Flex p={4}>
            <Label>
              <Checkbox
                {...register('agreed', {
                  required: 'Please agree to the terms of condition & privacy policy'
                })}
              />
              <Box sx={{ position: 'relative' }}>
                <Box>
                  <Text>I agree to all the </Text>
                  <Text
                    sx={{ color: 'primary', cursor: 'pointer' }}
                    onClick={() => {
                      window.open('/terms-condition', '_blank')
                    }}
                  >
                    Terms of condition
                  </Text>
                  <Text> & </Text>
                  <Text
                    sx={{ color: 'primary', cursor: 'pointer' }}
                    onClick={() => {
                      window.open('/privacy-policy', '_blank')
                    }}
                  >
                    Privacy Policy
                  </Text>
                </Box>
                {errors != null && (
                  <ErrorMessage
                    errors={errors}
                    name="agreed"
                    render={({ message }) => (
                      <Text
                        as="p"
                        color="red"
                        sx={{ position: 'absolute', top: '100%', width: '150%' }}
                      >
                        {message}
                      </Text>
                    )}
                  />
                )}
              </Box>
            </Label>
          </Flex>
        </Box>
        <Button onClick={() => clearErrors()} type="submit">
          Confirm
        </Button>
      </Flex>
    </Box>
  )
}

export default StakeSubmissionPage
