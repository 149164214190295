import React from 'react'
import { Button, Card, Container, IconButton, Input, Select, Text, useColorMode } from 'theme-ui'
import { MdNotifications } from 'react-icons/md'

const ThemeReference = () => {
  const [colorMode, setColorMode] = useColorMode()
  //setColorMode('default')
  return (
    <div>
      <Card variant="secondary">
        defualt card
        <Container>
          <IconButton>
            <MdNotifications size={200} />
          </IconButton>
          <Button>Primary</Button>
          <br />
          <Button variant="secondary">Secondary</Button>
          <br />
          <Input />
          <br />
          default select
          <Select>
            <option value="1">test 1</option>
            <option value="2">test 2</option>
            <option value="3">test 3</option>
            <option value="4">test 4</option>
          </Select>
          <br />
          secondary select
          <Select variant="secondarySelect">
            <option value="1">test 1</option>
            <option value="2">test 2</option>
            <option value="3">test 3</option>
            <option value="4">test 4</option>
          </Select>
          <br />
          <Text>default text</Text>
          <br />
          <Text color="textMuted">muted text</Text>
          <br />
          <Text color="danger">danger text</Text>
          <br />
          <Text color="success">success text</Text>
        </Container>
      </Card>
    </div>
  )
}

export default ThemeReference
