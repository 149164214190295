import { Box, Text, Flex } from 'theme-ui'
import { MdOutlineCopyright } from 'react-icons/md'
import SideBarIcon from './sidebar-icon'

const Footer = () => {
  // const [colorMode, setColorMode] = useColorMode()

  // const switchColorMode = () => {
  //   colorMode === 'default' ? setColorMode('light') : setColorMode('default')
  // }

  return (
    <Flex
      variant="layout.flexCenterEnd"
      sx={{
        flex: 1,
        flexDirection: 'column',
        // alignItems: 'center',
        marginTop: 50
      }}
    >
      {/* <Button sx={{ width: 300 }} onClick={switchColorMode}>
        {colorMode === 'default' ? 'Switch to light mode' : 'Switch to dark mode'}
      </Button> */}
      <Box pt={4} />
      <Box>
        <a href="https://www.facebook.com/voxto.amplify" target="_blank" rel="noreferrer">
          <SideBarIcon icon={'/assets/svg/facebook.svg'} />
        </a>
        <a href="https://t.me/voxto" target="_blank" rel="noreferrer">
          <SideBarIcon icon={'/assets/svg/telegram.svg'} />
        </a>
        <a href="https://twitter.com/voxto_amplify" target="_blank" rel="noreferrer">
          <SideBarIcon icon={'/assets/svg/twitter.svg'} />
        </a>
        <a href="https://discord.com/invite/w7Sz8nZQJQ" target="_blank" rel="noreferrer">
          <SideBarIcon icon={'/assets/svg/discord.svg'} />
        </a>
      </Box>
      <Box pt={4} />
      <Box>
        <Text>
          VOXTO <MdOutlineCopyright /> {new Date().getFullYear()}. All Rights Reserved
        </Text>
      </Box>
    </Flex>
  )
}

export default Footer
